import { Link } from 'react-router-dom'
import Profile from './profile'
import Navigation from './navigation'

export default function Index() {
  return (
    <div data-aos='fade-down' className='flex gap-2 relative p-3'>
      <Profile />
      <Navigation />
    </div>
  )
}

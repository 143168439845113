import { useState, useEffect } from 'react'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { PencilIcon } from '@heroicons/react/24/solid'

import EditModal from './edit-modal'
import AddModal from './add-modal'

export default function Index() {
  const functions = getFunctions()

  const [locations, setLocations] = useState(false)
  const [currentLocation, setCurrentLocation] = useState(false)

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    async function getLocations() {
      console.log('get locations')
      const sendMessage = httpsCallable(functions, 'mongodbGetCollection')
      await sendMessage({ text: JSON.stringify({
          collection: 'locations',
        })
      })
      .then(result => {
        const data = result.data
        console.log(data)
        if(data.data && data.data.length > 0) {
          setLocations(data.data)
        } else {
          setLocations(null)
        }
      })
      .catch((e) => {
        console.log(e)
      })
    }
    if(!locations) getLocations()
  }, [])

  return (
    <div className='fade-in'>
      {locations === false &&
        <div className='fade-in relative h-screen flex items-center justify-center'>
          Loading
        </div>
      }
      {locations === null &&
        <div className='fade-in relative h-screen flex items-center justify-center'>
          No data available.
        </div>
      }
      {locations &&
        <div className='fade-in pb-20vh'>
          <div className='px-3 flex justify-between items-end h-32 sticky top-0 bg-black-900 bg-opacity-40 backdrop-filter backdrop-blur-md pb-3 mb-6'>
            <p>
              <input
                type='text'
                className='placeholder-black-550 bg-black-850 px-3 py-2 rounded-lg text-sm'
                placeholder='Search locations' />
            </p>
          </div>
          <ul className='px-3 fade-in divide-y space-y-3.5'>
            {locations.map(l =>
              <li
                key={'locations-' + l.name}
                className='text-sm pt-3 flex items-center gap-3'>
                <button
                  onClick={() => {
                    setCurrentLocation(l)
                    setShowModal('edit')
                  }}
                  className='group transition duration-200 h-8 w-8 rounded-full bg-black-825 hover:bg-black-800 flex text-primary'>
                  <PencilIcon className='transition duration-200 group-hover:scale-110 h-4 w-4 m-auto' />
                </button>
                <span className='w-1/3 truncate'>{l.name}</span>
                <span className='flex-1 truncate text-black-550'>{l.state}</span>
                <span className='flex-1 truncate text-black-550'>{l.serviceArea}</span>
                <span className='flex-1 truncate text-black-550'>{l.phone}</span>
                <span className='flex-1 truncate text-black-550'>{l.address}</span>
              </li>
            )}
          </ul>
        </div>
      }
      <EditModal location={currentLocation} setLocation={setCurrentLocation} showModal={showModal} setShowModal={setShowModal} />
      <AddModal location={currentLocation} setLocation={setCurrentLocation} showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

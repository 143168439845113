const Reducer = (state, action) => {
  switch (action.type) {
    case 'toast-set':
      return {
        ...state,
        toast: action.payload
      }
    case 'user-set':
      return {
        ...state,
        user: action.payload
      }
    case 'settings-set':
      return {
        ...state,
        settings: action.payload
      }
    case 'profile-set':
      return {
        ...state,
        profile: action.payload
      }
    case 'main-user-set':
      return {
        ...state,
        mainUser: action.payload
      }
    case 'main-settings-set':
      return {
        ...state,
        mainSettings: action.payload
      }
    case 'main-profile-set':
      return {
        ...state,
        mainProfile: action.payload
      }
    case 'spoof-settings-set':
      return {
        ...state,
        spoofSettings: action.payload
      }
    case 'spoof-profile-set':
      return {
        ...state,
        spoofProfile: action.payload
      }
    case 'tools-set-show':
      return {
        ...state,
        tools: {
          ...state.tools,
          show: action.payload
        }
      }
    case 'tools-set-conversation-filter':
      return {
        ...state,
        tools: {
          ...state.tools,
          conversationFilter: action.payload
        }
      }
    case 'tools-set-conversation':
      return {
        ...state,
        tools: {
          ...state.tools,
          conversation: action.payload
        }
      }
    case 'contact-properties-set':
      return {
        ...state,
        contactProperties: action.payload
      }
    case 'contact-owners-set':
      return {
        ...state,
        contactOwners: action.payload
      }
    case 'twilio-set-device':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          device: action.payload
        }
      }
    case 'twilio-set-call':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          call: action.payload
        }
      }
    case 'twilio-set-contact':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          contact: action.payload
        }
      }
    case 'twilio-set-call-start':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          callStart: action.payload
        }
      }
    case 'twilio-set-call-duration':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          callDuration: action.payload
        }
      }
    case 'twilio-set-wrapup-start':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          wrapUpStart: action.payload
        }
      }
    case 'twilio-set-wrapup-pause':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          wrapUpPause: action.payload
        }
      }
    case 'twilio-set-wrapup-duration':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          wrapUpDuration: action.payload
        }
      }
    case 'twilio-set-number':
      return {
        ...state,
        twilio: {
          ...state.twilio,
          number: action.payload
        }
      }
    case 'dialer-set-continue-queue':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          continueQueue: action.payload
        }
      }
    case 'dialer-set-size':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          size: action.payload
        }
      }
    case 'dialer-set-snooze-leads':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          snoozeLeads: action.payload
        }
      }
    case 'dialer-set-status':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          status: action.payload
        }
      }
    case 'dialer-set-filter':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          filter: action.payload
        }
      }
    case 'dialer-set-update-queue':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          updateQueue: action.payload
        }
      }
    case 'dialer-set-next-up':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          nextUp: action.payload
        }
      }
    case 'dialer-set-update-current-contact':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          updateCurrentContact: action.payload
        }
      }
    case 'dialer-set-current-contact':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          currentContact: action.payload
        }
      }
    case 'dialer-set-cut-in-line':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          cutInLine: action.payload
        }
      }
    case 'dialer-set-contacts':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          contacts: action.payload
        }
      }
    case 'dialer-set-queue':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          queue: action.payload
        }
      }
    case 'dialer-set-total-time':
      return {
        ...state,
        dialer: {
          ...state.dialer,
          totalTime: action.payload
        }
      }
    default:
      return state
  }
}

export default Reducer

import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, deleteDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import slugify from 'slugify'

import { Context } from 'store/index'
import { MapPinIcon, ArrowsUpDownIcon, XMarkIcon, PencilIcon, PlusIcon, ArrowPathIcon } from '@heroicons/react/24/solid'
import ReactTooltip from 'react-tooltip'

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import Select from 'react-select'

export default function Index({ career, setCurrentCareer, showModal, setShowModal }) {
  const [state] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)
  const [original, setOriginal] = useState(false)

  function compareObjs(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setCurrentCareer(false)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  useEffect(() => {
    if(career) {
      setData(career)
      if(!original) {
        setOriginal(career)
      }
    } else {
      setData(false)
      setOriginal(false)
    }
  }, [career])

  return display ? createPortal(
    <DndProvider backend={HTML5Backend}>
    <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-5xl transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='p-3'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 overflow-hidden flex pr-3'>
          <div className='flex-1 max-w-sm h-full overflow-hidden overflow-scroll'>
            {data &&
              <div className='space-y-12'>
                <div className='p-3 pr-6'>
                  <h2 className='text-xl'>{data.position} &mdash; {data.service_area.label}</h2>
                  <p className='text-black-400 mb-6'>/careers/{data.slug}</p>
                  <div className='flex items-center gap-3'>
                    <label htmlFor={data.id + '-published-edit'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                      <input
                        type='checkbox'
                        checked={data.isPublished ? true : false}
                        id={data.id + '-published-edit'}
                        onChange={(e) => {
                          const isPublished = data.isPublished ? false : true
                          setData(prev => ({
                            ...prev,
                            isPublished: isPublished
                          }))
                          // setDoc(doc(db, 'careers', data.id), {
                          //   isPublished: isPublished,
                          //   updatedAt: new Date()
                          // }, { merge: true })
                        }}
                        className='cursor-pointer h-full w-full sr-only peer' />
                      <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (data.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                    </label>
                    <span className={'text-xs ' + (data.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                  </div>
                </div>
                <div className='space-y-2 p-3 pr-6'>
                  <div className='relative'>
                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        slug: e.target.value
                      }))
                    }} data={data} property='slug' label='Slug' />
                    <button
                      onClick={() => {
                        setData(prev => ({
                          ...prev,
                          slug: slugify(data.service_area.label + '-' + data.position, {remove: /[#$%^&=*+~.,/;?—()''!@]/g}).toLowerCase()
                        }))
                      }}
                      className='text-black-200 hover:text-white group h-6 w-6 bg-black-700 rounded-full flex absolute right-2 top-1/2 transform -translate-y-1/2 z-10'>
                      <ArrowPathIcon className='transition duration-300 group-hover:rotate-180 h-3.5 w-3.5 m-auto' />
                    </button>
                  </div>
                  {/*<TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      title: e.target.value
                    }))
                  }} data={data} property='title' label='Title' />*/}
                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      position: e.target.value
                    }))
                  }} data={data} property='position' label='Position' />
                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      type: e.target.value
                    }))
                  }} data={data} property='type' label='Type' />
                  <div className='relative group'>
                    <label htmlFor='salesReps' className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>Service Area</label>
                    <Select
                      menuPlacement='top'
                      className='react-select-multi-select relative z-10'
                      classNamePrefix='react-select'
                      value={data && data.service_area ? data.service_area : ''}
                      onChange={(e) => {
                        setData(prev => ({
                          ...prev,
                          service_area: e
                        }))
                      }}
                      options={state.contactProperties.service_area}
                    />
                    <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
                  </div>
                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      pay: e.target.value
                    }))
                  }} data={data} property='pay' label='Pay' />
                </div>

                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    responsibilities: e
                  }))
                }} data={data} property='responsibilities' label='Main Responsibilities' placeholder='New responsibility' />
                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    qualifications: e
                  }))
                }} data={data} property='qualifications' label='Qualifications' placeholder='New qualification' />
                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    attributes: e
                  }))
                }} data={data} property='attributes' label='Ideal Attributes' placeholder='New attribute' />
                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    bonuses: e
                  }))
                }} data={data} property='bonuses' label='Bonuses' placeholder='New bonus' />
                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    benefits: e
                  }))
                }} data={data} property='benefits' label='Benefits' placeholder='New benefit' />
                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    schedule: e
                  }))
                }} data={data} property='schedule' label='Schedule' placeholder='New schedule' />
                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    supplemental_pay: e
                  }))
                }} data={data} property='supplemental_pay' label='Supplemental Pay' placeholder='New pay' />
                <MultiInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    experience: e
                  }))
                }} data={data} property='experience' label='Experience' placeholder='New experience' />

                <div className='p-3 pb-12'>
                  <button
                    onClick={async () => {
                      if(window.confirm('Are you sure you want to remove this?')) {
                        await deleteDoc(doc(db, 'careers', data.id))
                        setShowModal(false)
                      }
                    }}
                    className='font-bold px-6 py-1.5 text-sm rounded-lg text-red-500 bg-black-775'>
                    Delete
                  </button>
                </div>
              </div>
            }
            <div className='border-t border-black-750 text-sm sticky bottom-0 bg-black-850 p-3 flex z-50 justify-between'>
              <button
                onClick={() => {
                  setData(original)
                }}
                disabled={compareObjs(data, original) ? true : false}
                className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
                Discard
              </button>
              <div className='flex gap-1'>
                <button
                  onClick={async () => {
                    const update = {}
                    Object.entries(data).forEach(([k, v]) => {
                      update[k] = v
                    })
                    delete update.id
                    delete update.createdAt
                    delete update.updatedAt
                    await setDoc(doc(db, 'careers', data.id), {
                      ...update,
                      updatedAt: new Date()
                    }, { merge: true })
                    setOriginal(data)
                  }}
                  disabled={compareObjs(data, original) ? true : false}
                  className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
                  Save
                </button>
                <button
                  onClick={async () => {
                    const update = {}
                    Object.entries(data).forEach(([k, v]) => {
                      update[k] = v
                    })
                    delete update.id
                    delete update.createdAt
                    delete update.updatedAt
                    await setDoc(doc(db, 'careers', data.id), {
                      ...update,
                      updatedAt: new Date()
                    }, { merge: true })
                    setShowModal(false)
                  }}
                  disabled={compareObjs(data, original) ? true : false}
                  className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-black bg-primary hover:bg-purple-600')}>
                  Save & Close
                </button>
              </div>
            </div>
          </div>
          <div className='flex-1 h-full overflow-scroll rounded-t-2xl bg-black-875'>
            <div className='max-w-md m-auto py-8 px-3'>
              <h1 className='font-bold text-3xl pb-6'>{data.position} &mdash; {data.service_area.label}</h1>
              <ul className='flex gap-4 flex-wrap pb-6'>
                <li className='text-sm whitespace-nowrap flex items-center gap-2'>
                  <span className='h-3 w-3 bg-primary rounded-full' />Location &mdash; {data.service_area.label}
                </li>
                <li className='text-sm whitespace-nowrap flex items-center gap-2'>
                  <span className='h-3 w-3 bg-primary rounded-full' />Type &mdash; {data.type}
                </li>
              </ul>
              {data.benefits &&
                <ul className='flex gap-1 flex-wrap mb-6'>
                  {data.benefits.map(benefit =>
                    <li key={benefit} className='bg-black-800 text-black-400 text-xs leading-none px-4 py-1 rounded-full whitespace-nowrap'>{benefit}</li>
                  )}
                </ul>
              }
              <button className='text-black bg-primary px-8 py-1.5 font-bold rounded-full mb-8'>
                Apply Now
              </button>
              <p className='text-sm mb-8'>Astrawatt Solar is an equal opportunity employer.</p>
              <div className='border border-black-750 rounded-2xl p-3 space-y-4 text-sm'>
                <h2 className='font-bold text-lg'>About Astrawatt Employment</h2>
                <p>Astrawatt Solar is a passionate team of solar professionals working to lead change and progress toward sustaining a healthy planet. We were recently ranked on the Inc. 500 list for the second straight year! We are vertically integrated meaning we do solar sales, project management, installation, and service in-house.</p>
                <p>Ideal potential employees are passionate about clean energy, have a strong worth ethic, keen attention to detail, high quality standards, and are self motivated.</p>
                <p>We offer competitive pay, benefits, and a company vehicle for install crew leads.</p>
                <p>Astrawatt Solar pays for 50% of your selected health insurance offering! We also offer optional vision and dental insurance as well as 100% company paid short term, long term disability, and life insurance.</p>
                <p>If you've worked in solar, believe you are a cut above the rest, and you're looking to find a long-term home with a good company, doing good work, working with good people, running a business the right way - We invite you to apply and send your resume to us today!</p>

                <h2 className='font-bold text-lg'>Main Responsibilities</h2>
                {data.responsibilities && data.responsibilities.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.responsibilities.map(d =>
                      <div key={d + '-responsibilities'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                <h2 className='font-bold text-lg'>Qualifications</h2>
                {data.qualifications && data.qualifications.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.qualifications.map(d =>
                      <div key={d + '-qualifications'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                {data.attributes && data.attributes.length > 0 &&
                  <>
                    <h2 className='font-bold text-lg'>Ideal Attributes</h2>
                    <div className='space-y-1.5'>
                      {data.attributes.map(d =>
                        <div key={d + '-attributes'} className='flex items-center gap-3'>
                         <span className='h-2 w-2 rounded-full bg-black-650' />
                         <span className='flex-1'>{d}</span>
                       </div>
                      )}
                    </div>
                  </>
                }

                <h2 className='font-bold text-lg'>Bonuses</h2>
                {data.bonuses && data.bonuses.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.bonuses.map(d =>
                      <div key={d + '-bonuses'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                <h2 className='font-bold text-lg'>Job Type</h2>
                <p>{data.type}</p>

                <h2 className='font-bold text-lg'>Pay</h2>
                <p>{data.pay}</p>

                <h2 className='font-bold text-lg'>Benefits</h2>
                {data.benefits && data.benefits.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.benefits.map(d =>
                      <div key={d + '-benefits'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                <h2 className='font-bold text-lg'>Schedule</h2>
                {data.schedule && data.schedule.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.schedule.map(d =>
                      <div key={d + '-schedule'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                <h2 className='font-bold text-lg'>Supplemental Pay Types</h2>
                {data.supplemental_pay && data.supplemental_pay.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.supplemental_pay.map(d =>
                      <div key={d + '-supplemental_pay'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                <h2 className='font-bold text-lg'>Experience</h2>
                {data.experience && data.experience.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.experience.map(d =>
                      <div key={d + '-experience'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                <h2 className='font-bold text-lg'>License / Certification</h2>
                {data.license_certification && data.license_certification.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.license_certification.map(d =>
                      <div key={d + '-license_certification'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }

                <h2 className='font-bold text-lg'>Work Location</h2>
                {data.work_location && data.work_location.length > 0 &&
                  <div className='space-y-1.5'>
                    {data.work_location.map(d =>
                      <div key={d + '-work_location'} className='flex items-center gap-3'>
                       <span className='h-2 w-2 rounded-full bg-black-650' />
                       <span className='flex-1'>{d}</span>
                     </div>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </DndProvider>
  , document.body) : null
}

function TextInput({ data, property, label, onChange}) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <input
        id={property}
        className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
        type='text'
        onChange={onChange}
        value={data[property] ? data[property] : ''} />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}

function MultiInput({ data, property, label, onChange, placeholder}) {
  const ItemTypes = {
    CARD: 'card',
  }

  const [value, setValue] = useState('')
  const [showAdd, setShowAdd] = useState(false)

  const [cards, setCards] = useState(false)

  useEffect(() => {
    function updateCards() {
      setCards(data[property].map((o, index) => ({
        position: index,
        text: o
      })))
    }

    if(data && data[property]) {
      if(cards) {
        if(JSON.stringify(data[property]) !== JSON.stringify(cards.map(o => o.text))) {
          updateCards()
        }
      } else {
        updateCards()
      }
    }
  }, [data])

  // useEffect(() => {
  //   if(cards) {
  //     onChange(cards.map(o => o.text))
  //   }
  // }, [cards])

  const Card = ({ id, text, index, moveCard, cards }) => {
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex, cards)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
      <li ref={ref} style={{ opacity }} data-handler-id={handlerId} className='text-sm flex gap-3 px-2 py-4 items-center'>
        <div className='text-black-400 hover:text-white cursor-ns-resize'>
          <ArrowsUpDownIcon className='h-4 w-4 m-auto' />
        </div>
        <span className='text-black-200 flex-1 block'>
          {text}
        </span>
        <button onClick={() => {
            onChange(data[property].filter(a => a !== text))
          }} className='group h-6 w-6 rounded-full bg-black-750 hover:bg-black-725 text-black-200 hover:text-white flex'>
          <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-4 w-4 m-auto' />
        </button>
      </li>
    )
  }

  const moveCard = useCallback((dragIndex, hoverIndex, cards) => {
    console.log(cards)
    const newUpdate = update(cards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, cards[dragIndex]],
      ],
    })
    console.log(update)
    setCards(newUpdate)
    onChange(newUpdate.sort((a,b) => b.postion - a.postion).map(o => o.text))
  }, [])

  const renderCard = useCallback((card, index, cards) => {
    return (
      <Card
        cards={cards}
        key={card.position}
        index={index}
        id={card.position}
        text={card.text}
        moveCard={moveCard}
      />
    )
  }, [])

  return (
    <div>
      <h2 className='text-lg border-b border-black-750 sticky -top-px bg-black-850 p-3 z-50 flex justify-between'>
        <span>{label}</span>
        <span className={'transition duration-200 text-black text-sm h-6 w-6 rounded-full flex items-center justify-center ' + (data[property] && data[property].length > 0 ? 'bg-primary' : 'bg-yellow-500')}>{data[property] ? data[property].length : 0}</span>
      </h2>
      <div className='space-y-4 p-3 pr-6'>
        {cards && cards.length > 0 &&
          <ul className='border border-black-750 divide-y divide-black-700 rounded-xl'>
            {cards.map((card, i) => renderCard(card, i, cards))}
          </ul>
        }
        {!showAdd &&
          <button
            onClick={() => {
              setShowAdd(true)
            }}
            className='fade-in group transition duration-200 hover:bg-black-775 gap-2 rounded-full p-1 flex items-center'>
            <div className='group flex rounded-full h-7 w-7 bg-primary text-black'>
              <PlusIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </div>
            <span className='text-sm text-black-400 transition duration-200 group-hover:text-white pr-3'>{placeholder}</span>
          </button>
        }
        {showAdd &&
          <div className='fade-in gap-3 items-center border border-primary rounded-lg overflow-hidden'>
            <input
              id={property}
              className='bg-black-825 border-b border-black-750 placeholder-black-400 relative z-10 px-3 py-4 w-full'
              type='text'
              onChange={(e) => {
                setValue(e.target.value)
              }}
              value={value}
              placeholder={placeholder} />
            <div className='p-2 flex gap-2 justify-end'>
              <button
                onClick={() => {
                  if(value && value !== '') onChange([...data[property], value])
                  setValue('')
                  setShowAdd(false)
                }}
                className={'text-xs transition duration-200 rounded-lg px-6 py-1.5 ' + (value && value !== '' ? 'bg-primary text-black' : 'bg-black-800')}>
                Add
              </button>
              <button
                onClick={() => {
                  setShowAdd(false)
                }}
                className='text-xs transition duration-200 rounded-lg px-6 py-1.5 bg-black-800'>
                Cancel
              </button>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

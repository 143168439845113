import { useRef, useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { XMarkIcon } from '@heroicons/react/24/solid'

export default function Index({ showSlider, setShowSlider, children }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)

  useEffect(() => {
    if(showSlider) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showSlider])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
        setData(false)
      }, 500)
    }
  }, [animate])

  return display ?
    <div className='absolute z-40 bottom-0 left-0 h-full w-full pt-12'>
      <div
        onClick={() => {
          setShowSlider(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black-825 bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-2xl overflow-hidden flex flex-col absolute right-0 bottom-0 h-5/6 bg-black-875 w-full max-w-5xl transition duration-500 transform ' + (animate ? 'translate-y-0' : 'translate-y-full')}>
        <div className='p-2'>
          <button
            onClick={() => {
              setShowSlider(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 overflow-hidden p-2 pt-4 pt-0'>
          {children}
        </div>
      </div>
    </div>
    : null
}

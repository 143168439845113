import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, orderBy, setDoc, doc } from 'firebase/firestore'

import { MapPinIcon, PencilIcon } from '@heroicons/react/24/solid'
import { CheckIcon } from '@heroicons/react/24/outline'
import ReactTooltip from 'react-tooltip'

import EditModal from './edit-modal'
import AddModal from './add-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const brandAssetsUnsub = useRef(null)
  const [assets, setAssets] = useState(false)
  const [currentAsset, setCurrentAsset] = useState(false)

  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(brandAssetsUnsub.current) brandAssetsUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getAssets() {
      const q = query(collection(db, 'brand-assets'), orderBy('title', 'asc'))
      brandAssetsUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            const result = querySnapshot.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data()
              }
            })
            setAssets(result)
          } else setAssets(null)
        }
      })
    }
    getAssets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-aos='fade-in' className='relative h-full'>
      <div className='p-3 fixed top-0 right-0 '>
        <button
          className='bg-black-800 hover:bg-black-750 rounded-full px-4 text-primary py-1 text-sm font-bold'
          onClick={() => {
            setShowAddModal(true)
          }}>
          Add Asset
        </button>
      </div>
      <div className='h-full overflow-scroll py-16 space-y-8'>
        <ul>
          {assets && assets.map(asset =>
            <li key={asset.id} className='text-sm px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
              <button
                data-tip='Edit asset'
                data-for={asset.id + '-edit'}
                data-delay-show={1000}
                onClick={() => {
                  setShowEditModal(true)
                  setCurrentAsset({
                    id: asset.id,
                    ...asset
                  })
                }}
                className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                <PencilIcon className='h-4 w-4 m-auto' />
                <ReactTooltip
                  className='text-sm'
                  id={asset.id + '-edit'}
                  place='right'
                  type='dark'
                  effect='solid'
                  multiline={true}
                />
              </button>
              <span>
                <div className='flex items-center gap-3 pr-6'>
                  <label htmlFor={asset.id + '-published'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={asset.isPublished ? false : true}
                      id={asset.id + '-published'}
                      onChange={(e) => {
                        const isPublished = asset.isPublished ? false : true
                        setDoc(doc(db, 'brand-assets', asset.id), {
                          isPublished: isPublished,
                          updatedAt: new Date()
                        }, { merge: true })
                      }}
                      className='cursor-pointer h-full w-full sr-only peer' />
                    <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (asset.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                  </label>
                  <span className={'text-xs ' + (asset.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                </div>
              </span>
              <span>
                <span className='h-10 w-10 bg-black-775 rounded-full flex'>
                   {asset.image ?
                     <img className='h-full w-full rounded-full overflow-hidden object-cover' src={asset.image.url} alt='Asset thumbnail.' /> :
                     <svg xmlns='http://www.w3.org/2000/svg' className='m-auto text-primary h-4 w-4' viewBox='0 0 20 20' fill='currentColor'>
                       <path fillRule='evenodd' d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z' clipRule='evenodd' />
                    </svg>
                   }
                 </span>
              </span>
              <span className='flex-1 truncate'>{asset.title}</span>
              <span className='flex-1 truncate'>{asset.packageUrl && <span className='block h-8 w-8 rounded-full flex items-center justify-center text-primary border border-primary'><CheckIcon strokeWidth={2} className='h-4 w-4' /></span>}</span>
              <span className='flex-1 truncate flex gap-1'>
                {asset.categories && asset.categories.map(c =>
                  <span key={c.value} className='px-3 leading-loose rounded-full text-black-300 text-xxs bg-black-800'>{c.label}</span>
                )}
              </span>
            </li>
          )}
        </ul>
      </div>
      <EditModal asset={currentAsset} setCurrentAsset={setCurrentAsset} showModal={showEditModal} setShowModal={setShowEditModal} />
      <AddModal asset={currentAsset} setCurrentAsset={setCurrentAsset} showModal={showAddModal} setShowModal={setShowAddModal} setShowEditModal={setShowEditModal} />
    </div>
  )
}

import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, setDoc, onSnapshot, orderBy, doc } from 'firebase/firestore'

import { MapPinIcon, PencilIcon } from '@heroicons/react/24/solid'
import ReactTooltip from 'react-tooltip'

import EditModal from './edit-modal'
import AddModal from './add-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const careersUnsub = useRef(null)

  const [careers, setCareers] = useState(false)
  const [currentCareer, setCurrentCareer] = useState(false)

  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(careersUnsub.current) careersUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getCareers() {
      const q = query(collection(db, 'careers'), orderBy('createdAt', 'asc'))
      careersUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            const result = querySnapshot.docs.reduce(function (r, a) {
              r[a.data().service_area.label] = r[a.data().service_area.label] || []
              r[a.data().service_area.label].push(a)
              return r
            }, Object.create(null))
            const sorted = Object.keys(result).sort().reduce(
              (obj, key) => {
                obj[key] = result[key]
                return obj
              },
              {}
            )
            setCareers(sorted)
          } else setCareers(null)
        }
      })
    }
    getCareers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-aos='fade-in' className='relative h-full'>
      <div className='p-3 fixed top-0 right-0 '>
        <button
          className='bg-black-800 hover:bg-black-750 rounded-full px-4 text-primary py-1 text-sm font-bold'
          onClick={() => {
            setShowAddModal(true)
          }}>
          Add Career
        </button>
      </div>
      <div className='h-full overflow-scroll py-16 space-y-8'>
        {careers && Object.entries(careers).map(([key, entries]) =>
          <div key={key} className='fade-in'>
            <h2 className='p-3 flex items-center gap-3 text-sm'>
              <MapPinIcon className='-mb-px text-primary h-5 w-5' />
              {key}
            </h2>
            <ul className='divide-y'>
              {entries.sort((a,b) => a.data().position.localeCompare(b.data().position)).map(career =>
                <li key={career.id} className='text-sm px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
                  <button
                    data-tip='Edit career'
                    data-for={career.id + '-edit'}
                    data-delay-show={1000}
                    onClick={() => {
                      setCurrentCareer({
                        id: career.id,
                        ...career.data()
                      })
                      setShowEditModal(true)
                    }}
                    className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                    <PencilIcon className='h-4 w-4 m-auto' />
                    <ReactTooltip
                      className='text-sm'
                      id={career.id + '-edit'}
                      place='right'
                      type='dark'
                      effect='solid'
                      multiline={true}
                    />
                  </button>
                  <span>
                    <div className='flex items-center gap-3 pr-6'>
                      <label htmlFor={career.id + '-published'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                        <input
                          type='checkbox'
                          checked={career.data().isPublished ? false : true}
                          id={career.id + '-published'}
                          onChange={(e) => {
                            const isPublished = career.data().isPublished ? false : true
                            setDoc(doc(db, 'careers', career.id), {
                              isPublished: isPublished,
                              updatedAt: new Date()
                            }, { merge: true })
                          }}
                          className='cursor-pointer h-full w-full sr-only peer' />
                        <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (career.data().isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                      </label>
                      <span className={'text-xs ' + (career.data().isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                    </div>
                  </span>
                  <span className='flex-1 truncate'>{career.data().position}</span>
                  <span className='flex-1 truncate text-black-400'>{career.data().service_area.label}</span>
                  <span className='truncate text-black-400'>{career.data().type}</span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <EditModal career={currentCareer} setCurrentCareer={setCurrentCareer} showModal={showEditModal} setShowModal={setShowEditModal} />
      <AddModal career={currentCareer} setCurrentCareer={setCurrentCareer} showModal={showAddModal} setShowModal={setShowAddModal} setShowEditModal={setShowEditModal} />
    </div>
  )
}

import { useState, useRef, useEffect, useContext } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { Context } from 'store/index'

export default function Index({ map, type, markers, setType, location, setLocation }) {
  const [state] = useContext(Context)

  const [show, setShow] = useState(false)
  const [locations, setLocations] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) setShow(false)
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ref])

  useEffect(() => {
    if(state.contactProperties.service_area) setLocations(state.contactProperties.service_area)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.contactProperties.service_area])

  return (
    <div className='flex flex-col justify-between p-2 h-full w-full'>
      <div>
        <p className='mb-12 font-bold text-primary'>Customers</p>
        <div ref={ref} className='flex justify-between items-center gap-2 relative z-10'>
          <div className='relative flex-1 flex justify-between'>
            <button
              onClick={() => setShow(!show)}
              className={'text-sm rounded-full pl-4 pr-3 py-1 flex gap-4 items-center ' + (location && location.value !== false ? 'bg-primary text-black': 'bg-black-725')}>
              <span className='text-sm'>{location.label}</span>
              <ChevronDownIcon className='h-4.5 w-4.5 -mb-1' />
            </button>
            {show &&
              <div className='filter drop-shadow-lg absolute w-56 h-40vh overflow-scroll top-full left-0 mt-2 bg-black-825 rounded-xl py-6'>
                <ul>
                  <li>
                    <button
                      onClick={() => {
                        if('all' !== location.value) setLocation({
                          label: 'All Areas',
                          value: 'all'
                        })
                        setShow(false)
                      }}
                      className='flex gap-3 items-center text-sm w-full px-2 py-3 text-left hover:bg-black-800'>
                      <span className={'h-2 w-2 rounded-full ' + ('all' === location.value ? 'bg-primary' : 'bg-black-725') } />
                      All Areas
                    </button>
                  </li>
                  {locations && locations.map(loc =>
                    <li key={loc.label}>
                      <button
                        onClick={() => {
                          if(loc.label !== location.label) setLocation(loc)
                          setShow(false)
                        }}
                        className='flex gap-3 items-center text-sm w-full px-2 py-3 text-left hover:bg-black-800'>
                        <span className={'h-2 w-2 rounded-full ' + (loc.label === location.label ? 'bg-primary' : 'bg-black-725') } />
                        {loc.label}
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            }
          </div>
        </div>
        {markers &&
          <p className='mt-4 text-sm text-black-400'>{markers.length.toLocaleString()} customers in {location.label}</p>
        }
      </div>

      <div className='flex flex-col gap-2 items-start'>
        <p className='text-sm text-black-400'>Roadmaps</p>
        <ul className='grid grid-cols-2 w-full gap-1 pb-2'>
          <li>
            <button
              className={'w-full text-sm rounded-lg px-3 py-2 flex gap-3 items-center ' + (type === 'roadmap-google' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-700 active:bg-black-675')}
              onClick={() => setType('roadmap-google')}>
              Google
            </button>
          </li>
          <li>
            <button
              className={'w-full text-sm rounded-lg px-3 py-2 flex gap-3 items-center ' + (type === 'roadmap-mapbox' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-700 active:bg-black-675')}
              onClick={() => setType('roadmap-mapbox')}>
              Mapbox
            </button>
          </li>
        </ul>
        <p className='text-sm text-black-400'>Satellite</p>
        <ul className='w-full grid grid-cols-2 gap-1'>
          <li>
            <button
              className={'w-full text-sm rounded-lg px-3 py-2 flex gap-3 items-center ' + (type === 'satellite-mapbox' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-700 active:bg-black-675')}
              onClick={() => setType('satellite-mapbox')}>
              Mapbox
            </button>
          </li>
          <li>
            <button
              className={'w-full text-sm rounded-lg px-3 py-2 flex gap-3 items-center ' + (type === 'satellite-bing' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-700 active:bg-black-675')}
              onClick={() => setType('satellite-bing')}>
              Bing
            </button>
          </li>
          <li>
            <button
              className={'w-full text-sm rounded-lg px-3 py-2 flex gap-3 items-center ' + (type === 'satellite-google' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-700 active:bg-black-675')}
              onClick={() => setType('satellite-google')}>
              Google
            </button>
          </li>
          <li>
            <button
              className={'w-full text-sm rounded-lg px-3 py-2 flex gap-3 items-center ' + (type === 'satellite-usgs' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-700 active:bg-black-675')}
              onClick={() => setType('satellite-usgs')}>
              USGS
            </button>
          </li>
          <li>
            <button
              className={'w-full text-sm rounded-lg px-4 py-2 flex gap-3 items-center ' + (type === 'satellite-arcgis' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-700 active:bg-black-675')}
              onClick={() => setType('satellite-arcgis')}>
              ArcGIS
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

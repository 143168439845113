import { useState, useEffect, useRef } from 'react'

import { LightBulbIcon } from '@heroicons/react/24/solid'
import Help from './help'

export default function Index() {
  const [show, setShow] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if(show && ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div className='p-3'>
      <div ref={ref} className='relative'>
        <button
          onClick={() => setShow(!show)}
          className='bg-black-825 text-black-100 hover:text-primary flex h-10 w-10 rounded-full hover:bg-black-800'>
          <LightBulbIcon className='h-4 w-4 m-auto' />
        </button>

        {show &&
          <div className='space-y-16 absolute z-50 bg-black-800 filter drop-shadow-lg w-72 right-0 mb-2 bottom-full rounded-2xl'>
            <Help setShowMenu={setShow}/>
          </div>
        }
      </div>
    </div>
  )
}

import { useEffect, useState, useRef } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, orderBy, limit } from 'firebase/firestore'

import { MapIcon } from '@heroicons/react/24/solid'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const ref = useRef(false)

  const [show, setShow] = useState('Projects')
  const [enable, setEnable] = useState(false)
  const [showIframe, setShowIframe] = useState(false)

  const [data, setData] = useState(false)
  const [proposalId, setProposalId] = useState(false)
  const [leadId, setLeadId] = useState(false)

  const [coordinates, setCoordinates] = useState('29.70252203097935, -95.41783058531671')
  const [usage, setUsage] = useState(12000)

  const mounted = useRef(false)
  const projectsUnsub = useRef(null)
  const [projects, setProjects] = useState(false)

  const [loaded, setLoaded] = useState(false)
  const loadedRef = useRef(loaded)
  loadedRef.current = loaded

  const [attempts, setAttempts] = useState(0)
  const attemptsRef = useRef(attempts)
  attemptsRef.current = attempts

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getProjects() {
      const q = query(collection(db, 'proposals'), orderBy('createdAt', 'desc'), limit(25))
      projectsUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) setProjects(querySnapshot.docs)
          else setProjects(null)
        }
      })
    }
    getProjects()
    return () => {
      if(projectsUnsub.current) projectsUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log(projects)

  function handleLoaded() {
    console.log('handle load')
    if(!loadedRef.current) {
      console.log('ping attempt ' + attemptsRef.current)
      sendMessage({
        cmd: 'Ping'
      })
      attemptsRef.current = attemptsRef.current + 1
      setAttempts(attemptsRef.current)
      setTimeout(() => {
        handleLoaded()
      }, 1000)
    }
  }

  useEffect(() => {
    if(loaded) {
      console.log('loaded')
      console.log('loading project')
      console.log(leadId, proposalId)
      loadProject(leadId, proposalId)
    }
  }, [loaded])

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleMessage(event) {
    if(event.data.callbackType === 'Production') {
      setData(prev => ({ ...prev, production: event.data.data }))
    }

    if(event.data.callbackType === 'NewLead') {
      setData(prev => ({ ...prev, leadId: event.data.data }))
    }

    if(event.data.callbackType === 'Loaded') {
      setData(prev => ({ ...prev, loaded: event.data.data }))
    }

    if(event.data.callbackType === 'Shading') {
      setData(prev => ({ ...prev, shading: event.data.data }))
    }

    if(event.data.callbackType === 'PanelPlacement') {
      setData(prev => ({ ...prev, panelPlacement: event.data.data }))
    }

    if(event.data.callbackType === 'NewProposal') {
      setData(prev => ({ ...prev, proposalId: event.data.data }))
    }

    if(event.data.callbackType === 'Error') {
      setData(prev => ({ ...prev, error: event.data.data }))
    }

    if(event.data.callbackType === 'Ping') {
      loadedRef.current = true
      setLoaded(true)
    }
  }

  function getIframeWindow(iframeEl) {
    let doc
    if(iframeEl.contentWindow) {
      return iframeEl.contentWindow
    }
    if(iframeEl.window) {
      return iframeEl.window
    }
    if(!doc && iframeEl.contentDocument) {
      doc = iframeEl.contentDocument
    }
    if(!doc && iframeEl.document) {
      doc = iframeEl.document
    }
    if(doc && doc.defaultView) {
      return doc.defaultView
    }
    if(doc && doc.parentWindow) {
      return doc.parentWindow
    }
    return undefined
  }

  function sendMessage(msg) {
    const iframeWindow = getIframeWindow(ref.current)
    if(iframeWindow) iframeWindow.postMessage(msg, '*')
  }

  function generate() {
    console.log('generate')
    try {
      sendMessage({
        cmd: 'generate',
        params: {
          coordinates,
          electricity_usage: usage,
        },
        // optional, if not provided the default values set in the software will be used
        config: {
          setback: 18, // inch
          buffer: 0.5, // inch
          panel: {
            manufacturer: 'REC',
            dimensions: { length: 1821, width: 890 },
            degradation: 0.26, // %
            efficiency: 21.6, // %
            has_micro: false,
            model: 'REC400AA',
            power: 400,
          },
          inverter: {
            capacity: 10000,
            efficiency: 96.0, // %
            model: 'Enphase',
            type: 'Central', // 'Micro' | 'Central'
          },
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  function loadProposal() {
    try {
      sendMessage({
        cmd: 'proposal.LoadProposal',
        params: { uid: leadId, pid: proposalId }
      })
    } catch (e) {
      console.log(e)
    }
  }

  function loadProject(leadId, proposalId) {
    try {
      sendMessage({
        cmd: 'proposal.LoadProposal',
        params: { uid: leadId, pid: proposalId }
      })
    } catch (e) {
      console.log(e)
    }
  }

  function startLoading(lid, pid) {
    setEnable(true)

    loadedRef.current = false
    setLoaded(false)

    ref.current = false
    setShowIframe(false)

    attemptsRef.current = 0
    setAttempts(0)

    setLeadId(lid)
    setProposalId(pid)

    setTimeout(() => {
      setShowIframe(true)

      setTimeout(() => {
        handleLoaded()
      }, 100)
    }, 100)
  }

  return (
    <div data-aos='fade-in' className='px-3 py-16 w-full h-screen'>
      <div className='bg-black-875 rounded-2xl w-full h-full flex'>
        <div className='w-1/4 h-full overflow-scroll'>
          <p className='mb-12 font-bold text-primary pt-3 px-3'>AI Proposals</p>
          {/*<ul className='text-xs flex gap-1 pb-3'>
            <li><button className={'px-4 py-1 rounded-full ' + (show === 'Projects' ? 'bg-primary text-black': 'bg-black-800 hover:bg-black-775')} onClick={() => setShow('Projects')}>Projects</button></li>
            <li><button className={'px-4 py-1 rounded-full ' + (show === 'Create' ? 'bg-primary text-black': 'bg-black-800 hover:bg-black-775')} onClick={() => setShow('Create')}>Create</button></li>
            <li><button className={'px-4 py-1 rounded-full ' + (show === 'Retreive' ? 'bg-primary text-black': 'bg-black-800 hover:bg-black-775')} onClick={() => setShow('Retreive')}>Retreive</button></li>
          </ul>*/}
          {show === 'Projects' &&
            <div data-aos='fade-in'>
              {projects === false &&
                <p>Loading projects...</p>
              }
              {projects === null &&
                <p>No projects.</p>
              }
              {projects &&
                <ul className='divide-y'>
                  {projects.map(project =>
                    <li key={project.id}>
                      <button
                        disabled={project.data().error ? true : false}
                        className='px-3 hover:bg-black-825 whitespace-nowrap truncate overflow-hidden w-full text-left py-2 text-xs' onClick={() => {
                        startLoading(project.data().leadId, project.data().proposalId)
                      }}>
                        <span className='block'>{project.data().email}</span>
                        <span className='block text-black-300'>{project.data().address}</span>
                        <span className='block text-black-300'>{project.data().createdAt.toDate().toDateString()}</span>
                        {project.data().error && <span className='mt-2 text-primary block'>Error: {project.data().error.message}</span>}
                      </button>
                    </li>
                  )}
                </ul>
              }
            </div>
          }
          {show === 'Create' &&
            <ul data-aos='fade-in' className='text-sm space-y-2 mb-6'>
              <li>
                <label className='block text-black-300 mb-1'>Coordinates</label>
                <input
                  type='text'
                  value={coordinates ? coordinates : ''}
                  onChange={(e) => setCoordinates(e.target.value)}
                  className='w-full rounded bg-black-800 px-3 py-2'
                />
              </li>
              <li>
                <label className='block text-black-300 mb-1'>Usage</label>
                <input
                  type='number'
                  value={usage ? usage : ''}
                  onChange={(e) => setUsage(e.target.value)}
                  className='w-full rounded bg-black-800 px-3 py-2'
                />
              </li>
              <li><button className='bg-black-800 mt-4 px-6 py-1 rounded-full' onClick={() => {
                generate()
                setEnable(true)
              }}>Generate</button></li>
            </ul>
          }
          {show === 'Retreive' &&
            <div data-aos='fade-in'>
              <ul className='text-sm space-y-2 mb-6'>
                <li>
                  <label className='block text-black-600'>Proposal ID</label>
                  <input
                    type='text'
                    value={proposalId ? proposalId : ''}
                    onChange={(e) => setProposalId(e.target.value)}
                    className='w-full rounded bg-black-800 px-3 py-2'
                  />
                </li>
                <li>
                  <label className='block text-black-600'>Lead ID</label>
                  <input
                    type='text'
                    value={leadId ? leadId : ''}
                    onChange={(e) => setLeadId(e.target.value)}
                    className='w-full rounded bg-black-800 px-3 py-2'
                  />
                </li>
                <li><button className='bg-black-800 mt-4 px-6 py-1 rounded-full' onClick={() => {
                  loadProposal()
                  setEnable(true)
                }}>Load</button></li>
              </ul>
            </div>
          }
          {/*
          <ul className='divide-y text-sm space-y-2 mb-6'>
            <li>
              <label className='block text-black-600'>Production</label>
              {production ? production : 'No production'}
            </li>
            <li>
              <label className='block text-black-600'>Shading</label>
              {shading ? shading : 'No shading'}
            </li>
            <li>
              <label className='block text-black-600'>Panel Placement</label>
              {panelPlacement ? panelPlacement : 'No placement'}
            </li>
          </ul>
          */}
          {/*<ul className='text-sm space-y-2'>
            <li>Loaded: {loaded && loaded}</li>
            <li>Error: {error && error.data.message}</li>
          </ul>*/}
        </div>
        <div className='h-full flex-1 p-3 pl-0'>
          <div className='rounded-2xl overflow-hidden bg-black-825 h-full w-full'>
            {enable === false &&
              <div className='w-full h-full flex'>
                <div className='flex h-44 w-44 relative m-auto'>
                  <span className='z-0 bg-black-750 h-full w-full absolute top-0 left-0 rounded-full m-auto flex' />
                  <MapIcon className='relative z-10 text-primary h-6 w-6 m-auto' />
                </div>
              </div>
            }
            {showIframe === true &&
              <iframe
                ref={ref}
                title='proposal'
                onLoad={() => {
                  console.log('on load')
                  // handleLoaded()
                }}
                className={'fade-in h-full w-full transition duration-200'}
                src={'https://solar.aerialytic.ai/iframe?access-key=' + process.env.REACT_APP_AERIALYTIC_API} />
              }
          </div>
        </div>
      </div>
    </div>
  )
}

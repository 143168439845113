import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, orderBy, setDoc, doc } from 'firebase/firestore'

import { StarIcon, EyeIcon } from '@heroicons/react/24/solid'

import ReactTooltip from 'react-tooltip'

import ViewModal from './view-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const reviewsUnsub = useRef(null)
  const [reviews, setReviews] = useState(false)
  const [currentReview, setCurrentReview] = useState(false)

  const [showViewModal, setShowViewModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(reviewsUnsub.current) reviewsUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getReviews() {
      const q = query(collection(db, 'reviews'), orderBy('createdAt', 'desc'))
      reviewsUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setReviews(querySnapshot.docs)
          } else setReviews(null)
        }
      })
    }
    getReviews()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-aos='fade-in' className='relative h-full'>
      <div className='h-full overflow-scroll py-16 space-y-8'>
        {reviews &&
          <ul className='divide-y'>
            {reviews.map(review =>
              <li key={review.id} className='text-sm px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
                <button
                  data-tip='View review'
                  data-for={review.id + '-edit'}
                  data-delay-show={1000}
                  onClick={() => {
                    setShowViewModal(true)
                    setCurrentReview({
                      id: review.id,
                      ...review.data()
                    })
                  }}
                  className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                  <EyeIcon className='h-4 w-4 m-auto' />
                  <ReactTooltip
                    className='text-sm'
                    id={review.id + '-edit'}
                    place='right'
                    type='dark'
                    effect='solid'
                    multiline={true}
                  />
                </button>
                <span>
                  <div className='flex items-center gap-3 pr-6'>
                    <label htmlFor={review.id + '-published'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                      <input
                        type='checkbox'
                        checked={review.data().isPublished ? false : true}
                        id={review.id + '-published'}
                        onChange={(e) => {
                          const isPublished = review.data().isPublished ? false : true
                          setDoc(doc(db, 'reviews', review.id), {
                            isPublished: isPublished,
                            updatedAt: new Date()
                          }, { merge: true })
                        }}
                        className='cursor-pointer h-full w-full sr-only peer' />
                      <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (review.data().isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                    </label>
                    <span className={'text-xs ' + (review.data().isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                  </div>
                </span>
                <span className='flex-1 truncate'>{review.data().author_title}</span>
                <span className='flex gap-1'>
                  {[...Array(parseInt(review.data().review_rating))].map(i =>
                    <StarIcon key={'yellow-' + i} className={'h-2.5 w-2.5 m-auto ' + (parseInt(review.data().review_rating) !== 5 ? 'text-primary' : 'text-primary')} />
                  )}
                  {[...Array(5 - parseInt(review.data().review_rating))].map(i =>
                    <StarIcon key={'gray-' + i} className='text-black-600 h-2.5 w-2.5 m-auto' />
                  )}
                </span>
                <span className='flex-1 truncate text-black-400'>{review.data().review_text}</span>
                <span className='flex-1 text-black-400 truncate text-right'>{review.data().createdAt.toDate().toLocaleDateString('en-us', { weekday:'short', year:'numeric', month:'short', day:'numeric'})}</span>
              </li>
            )}
          </ul>
        }
      </div>
      <ViewModal review={currentReview} setCurrentReview={setCurrentReview} showModal={showViewModal} setShowModal={setShowViewModal} />
    </div>
  )
}

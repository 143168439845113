import { useRef, useState, useCallback, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, updateDoc, doc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import Resizer from "react-image-file-resizer"

import { useDropzone } from 'react-dropzone'

export default function Index({ data, collection, folder, onChange }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp, 'gs://risingsun-solar-public')

  const pixelRatio = 4

  const imgModal = useRef(null)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)

  const [upImg, setUpImg] = useState(false)

  const onSelectFile = async files => {
    if(files && files.length > 0) {
      Resizer.imageFileResizer(
        files[0],
        1200,
        10000,
        'JPEG',
        1200,
        0,
        (blob) => {
          setUpImg({
            url: URL.createObjectURL(blob),
            file: blob
          })
        },
        'blob'
      )
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  console.log(upImg)

  function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement('canvas')
    tmpCanvas.width = newWidth
    tmpCanvas.height = newHeight
    const ctx = tmpCanvas.getContext('2d')
    ctx.drawImage(canvas,0, 0, canvas.width, canvas.height,0, 0, newWidth, newHeight)
    return tmpCanvas
  }

  const uploadImage = async (file) => {
    console.log(data)
    if(file) {
      const storageRef = ref(storage, folder + '/' + data.id + '.jpg')
      await uploadBytes(storageRef, file.file).then(async image => {
        console.log(image)
        const update = {
          image: {
            path: image.metadata.fullPath,
            bucket: image.metadata.bucket
          }
        }
        await getDownloadURL(storageRef).then((url) => {
          update.image.url = url
        })
        .catch(e => {
          console.log(e)
        })
        console.log(update)
        const docRef = doc(db, collection, data.id)
        updateDoc(docRef, update)
        onChange(update.image)
      })
    }
  }

  return (
    <div content='true' className='w-full max-w-lg'>
      {!upImg && <MyDropzone data={data} onSelectFile={onSelectFile} />}
      {upImg &&
        <div className='px-3'>
          <img src={upImg.url} className='max-w-xs' />
          <button
            className='mt-3 bg-primary text-black text-sm font-bold rounded-full px-4 py-1'
            type='button'
            onClick={() => {
              uploadImage(upImg)
              setUpImg(false)
            }}>
            Set Image
          </button>
        </div>
      }
    </div>
  )
}

function MyDropzone({ onSelectFile, data }) {
  const onDrop = useCallback(acceptedFiles => {
    onSelectFile(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className='p-3 pr-6'>
      <div className='flex justify-center px-3 py-8 max-w-full w-full cursor-pointer bg-black-800 rounded-lg hover:bg-black-775' {...getRootProps()}>
       <input {...getInputProps()} />
       <div className='flex items-center gap-3'>
         {data.image ?
          <div className='w-24 bg-black-750 rounded-lg flex'>
             <img className='h-full rounded-lg overflow-hidden' src={data.image.url} alt='Asset thumbnail.' />
           </div> :
           <div className='h-20 w-20 bg-black-750 rounded-full flex'>
             <svg xmlns='http://www.w3.org/2000/svg' className='m-auto text-primary h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
               <path fillRule='evenodd' d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z' clipRule='evenodd' />
            </svg>
          </div>
         }
         <p className='flex-1 text-sm text-black-400 leading-tight'>
           {isDragActive ?
             'Drop the file here.' :
             'Click or drop a file here to update asset thumbnail photo.'
           }
         </p>
       </div>
     </div>
   </div>
  )
}

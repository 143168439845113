import { useRef, useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, deleteDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import slugify from 'slugify'

import { MapPinIcon, ArrowsUpDownIcon, XMarkIcon, PencilIcon, PlusIcon, ArrowPathIcon } from '@heroicons/react/24/solid'
import ReactTooltip from 'react-tooltip'

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

export default function Index({ location, setLocation, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)
  const [original, setOriginal] = useState(false)

  function compareObjs(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  useEffect(() => {
    if(showModal === 'edit') {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setLocation(false)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  useEffect(() => {
    if(location) {
      setData(location)
      if(!original) {
        setOriginal(location)
      }
    } else {
      setData(false)
      setOriginal(false)
    }
  }, [location])

  return display ? createPortal(
    <DndProvider backend={HTML5Backend}>
      <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
        <div
          onClick={() => {
            setShowModal(false)
          }}
          className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
        <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-4xl transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
          <div className='flex-1 overflow-hidden flex'>
            <div className='flex-1 max-w-sm h-full overflow-hidden overflow-scroll'>
              <div className='p-3'>
                <button
                  onClick={() => {
                    setShowModal(false)
                  }}
                  className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
                  <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
                </button>
              </div>
              {data &&
                <div className='space-y-12'>
                  <div className='p-3 pr-6'>
                    <h2 className='text-xl'>{data.name}</h2>
                    <p className='text-black-400 mb-6'>/locations/{data.state.toLowerCase()}/solar-panel-installers/{data.slug}</p>
                    <div className='flex items-center gap-3'>
                      <label htmlFor={data.id + '-published-edit'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                        <input
                          type='checkbox'
                          checked={data.isPublished ? true : false}
                          id={data.id + '-published-edit'}
                          onChange={(e) => {
                            const isPublished = data.isPublished ? false : true
                            setData(prev => ({
                              ...prev,
                              isPublished: isPublished
                            }))
                            // setDoc(doc(db, 'locations', data.id), {
                            //   isPublished: isPublished,
                            //   updatedAt: new Date()
                            // }, { merge: true })
                          }}
                          className='cursor-pointer h-full w-full sr-only peer' />
                        <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (data.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                      </label>
                      <span className={'text-xs ' + (data.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                    </div>
                  </div>
                  <div className='space-y-2 p-3'>
                    <div className='relative'>
                      <TextInput onChange={(e) => {
                        setData(prev => ({
                          ...prev,
                          slug: e.target.value
                        }))
                      }} data={data} property='slug' label='Slug' />
                      <button
                        onClick={() => {
                          setData(prev => ({
                            ...prev,
                            slug: slugify(data.serviceArea, {remove: /[#$%^&=*+~.,/;?—()''!@]/g}).toLowerCase()
                          }))
                        }}
                        className='text-black-200 hover:text-white group h-6 w-6 bg-black-700 rounded-full flex absolute right-2 top-1/2 transform -translate-y-1/2 z-10'>
                        <ArrowPathIcon className='transition duration-300 group-hover:rotate-180 h-3.5 w-3.5 m-auto' />
                      </button>
                    </div>
                    {/*<TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        title: e.target.value
                      }))
                    }} data={data} property='title' label='Title' />*/}
                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        state: e.target.value
                      }))
                    }} data={data} property='state' label='State' />
                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        serviceArea: e.target.value
                      }))
                    }} data={data} property='serviceArea' label='Service Area' />
                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        hubSpotSchedulerEmbedUrl: e.target.value
                      }))
                    }} data={data} property='hubSpotSchedulerEmbedUrl' label='Scheduler URL' />
                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        googleBizEmbedUrl: e.target.value
                      }))
                    }} data={data} property='googleBizEmbedUrl' label='Google Business URL' />
                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        phone: e.target.value
                      }))
                    }} data={data} property='phone' label='Phone' />
                  </div>

                  <div className='p-3 pb-12'>
                    <button
                      onClick={async () => {
                        await deleteDoc(doc(db, 'locations', data.id))
                        setShowModal(false)
                      }}
                      className='font-bold px-6 py-1.5 text-sm rounded-lg text-red-500 bg-black-775'>
                      Delete
                    </button>
                  </div>
                </div>
              }
              <div className='border-t border-black-750 text-sm sticky bottom-0 bg-black-850 p-3 flex z-50 justify-between'>
                <button
                  onClick={() => {
                    setData(original)
                  }}
                  disabled={compareObjs(data, original) ? true : false}
                  className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
                  Discard
                </button>
                <div className='flex gap-1'>
                  <button
                    onClick={async () => {
                      const update = {}
                      Object.entries(data).forEach(([k, v]) => {
                        update[k] = v
                      })
                      delete update.id
                      delete update.createdAt
                      delete update.updatedAt
                      await setDoc(doc(db, 'locations', data.id), {
                        ...update,
                        updatedAt: new Date()
                      }, { merge: true })
                      setOriginal(data)
                    }}
                    disabled={compareObjs(data, original) ? true : false}
                    className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
                    Save
                  </button>
                  <button
                    onClick={async () => {
                      const update = {}
                      Object.entries(data).forEach(([k, v]) => {
                        update[k] = v
                      })
                      delete update.id
                      delete update.createdAt
                      delete update.updatedAt
                      await setDoc(doc(db, 'locations', data.id), {
                        ...update,
                        updatedAt: new Date()
                      }, { merge: true })
                      setShowModal(false)
                    }}
                    disabled={compareObjs(data, original) ? true : false}
                    className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-black bg-primary hover:bg-purple-600')}>
                    Save & Close
                  </button>
                </div>
              </div>
            </div>
            <div className='flex-1 h-full'>
              <div className='h-full overflow-scroll rounded-2xl bg-black-875'>
                <div className='w-full'>
                  <MultiInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      zips: e
                    }))
                  }} data={data} property='zips' label='Zip Codes' placeholder='Add new territory' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  , document.body) : null
}

function TextInput({ data, property, label, onChange}) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <input
        id={property}
        className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
        type='text'
        onChange={onChange}
        value={data[property] ? data[property] : ''} />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}

function MultiInput({ data, property, label, onChange, placeholder}) {
  const ItemTypes = {
    CARD: 'card',
  }

  const [value, setValue] = useState('')
  const [showAdd, setShowAdd] = useState(false)

  const [cards, setCards] = useState(false)

  console.log(cards)

  useEffect(() => {
    function updateCards() {
      setCards(data[property].map((o, index) => ({
        position: index,
        text: o
      })))
    }

    if(data && data[property]) {
      if(cards) {
        if(JSON.stringify(data[property]) !== JSON.stringify(cards.map(o => o.text))) {
          updateCards()
        }
      } else {
        updateCards()
      }
    }
  }, [data])

  // useEffect(() => {
  //   if(cards) {
  //     onChange(cards.map(o => o.text))
  //   }
  // }, [cards])

  const Card = ({ id, text, index, moveCard, cards }) => {
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
      accept: ItemTypes.CARD,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex, cards)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    return (
      <li ref={ref} style={{ opacity }} data-handler-id={handlerId} className='text-sm flex gap-3 px-2 py-2 items-center'>
        <div className='hidden text-black-400 hover:text-white cursor-ns-resize'>
          <ArrowsUpDownIcon className='h-4 w-4 m-auto' />
        </div>
        <span className='text-black-200 w-1/4 block'>
          {text}
        </span>
        <span className='text-black-200 flex-1 block'>
          Add county
        </span>
        <button onClick={() => {
            onChange(data[property].filter(a => a !== text))
          }} className='group h-6 w-6 rounded-full bg-black-750 hover:bg-black-725 text-black-200 hover:text-white flex'>
          <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-4 w-4 m-auto' />
        </button>
      </li>
    )
  }

  const moveCard = useCallback((dragIndex, hoverIndex, cards) => {
    console.log(cards)
    const newUpdate = update(cards, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, cards[dragIndex]],
      ],
    })
    console.log(update)
    setCards(newUpdate)
    onChange(newUpdate.sort((a,b) => b.postion - a.postion).map(o => o.text))
  }, [])

  const renderCard = useCallback((card, index, cards) => {
    return (
      <Card
        cards={cards}
        key={card.position}
        index={index}
        id={card.position}
        text={card.text}
        moveCard={moveCard}
      />
    )
  }, [])

  return (
    <div>
      <div className='space-y-4 p-3 pb-12'>
        {!showAdd &&
          <button
            onClick={() => {
              setShowAdd(true)
            }}
            className='fade-in group transition duration-200 hover:bg-black-775 gap-2 rounded-full flex items-center mb-12'>
            <div className='group flex rounded-full h-7 w-7 bg-black-775'>
              <PlusIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </div>
            <span className='text-sm text-black-400 transition duration-200 group-hover:text-white pr-3'>{placeholder}</span>
          </button>
        }
        {showAdd &&
          <div className='fade-in gap-3 items-center border border-primary rounded-lg overflow-hidden'>
            <input
              id={property}
              className='bg-black-825 border-b border-black-750 placeholder-black-400 relative z-10 px-3 py-4 w-full'
              type='text'
              onChange={(e) => {
                setValue(e.target.value)
              }}
              value={value}
              placeholder={placeholder} />
            <div className='p-2 flex gap-2 justify-end'>
              <button
                onClick={() => {
                  if(value && value !== '') onChange([...data[property], value])
                  setValue('')
                  setShowAdd(false)
                }}
                className={'text-xs transition duration-200 rounded-lg px-6 py-1.5 ' + (value && value !== '' ? 'bg-primary text-black' : 'bg-black-800')}>
                Add
              </button>
              <button
                onClick={() => {
                  setShowAdd(false)
                }}
                className='text-xs transition duration-200 rounded-lg px-6 py-1.5 bg-black-800'>
                Cancel
              </button>
            </div>
          </div>
        }
        {cards && cards.length > 0 &&
          <ul className='border rounded-2xl divide-y'>
            {cards.map((card, i) => renderCard(card, i, cards))}
          </ul>
        }
      </div>
    </div>
  )
}

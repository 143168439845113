import { useEffect, useState, useRef, useContext } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, addDoc, query, where, deleteField, onSnapshot, limit, orderBy } from 'firebase/firestore'

import { PlusIcon } from '@heroicons/react/24/solid'

import { Context } from 'store/index'

import Modal from 'components/modal'
import moment from 'moment'

export default function Index({ contact }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [state, dispatch] = useContext(Context)

  const mounted = useRef(false)
  const activityUnsub = useRef(null)
  const noteModal = useRef(null)
  const callModal = useRef(null)

  const [activity, setActivity] = useState(false)

  const [noteBody, setNoteBody] = useState('')
  const [callBody, setCallBody] = useState('')
  const [callDisposition, setCallDisposition] = useState(false)


  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getActivity() {
      const q = query(collection(db, 'sundial-activity'), where('uid', '==', state.user.uid), where('cid', '==', contact.vid.toString()), orderBy('createdAt', 'desc'), limit(20))
      activityUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) setActivity(querySnapshot.docs)
          else setActivity(null)
        }
      })
    }
    getActivity()
    return () => {
      if(activityUnsub.current) activityUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function createNote() {
    noteModal.current.close()
    dispatch({ type: 'toast-set', payload: 'Adding note to Hubspot.' })
    await addDoc(collection(db, 'sundial-activity'), {
      createdAt: new Date(),
      updatedAt: new Date(),
      body: noteBody,
      uid: state.user.uid.toString(),
      cid: contact.vid.toString(),
      notCounted: true,
      service_area: state.dialer.currentContact.service_area ? state.dialer.currentContact.service_area : 'undefined',
      lead_source: state.dialer.currentContact.lead_source ? state.dialer.currentContact.lead_source : 'undefined',
      type: {
        label: 'Note',
        value: 'note'
      }
    })
    await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/hubspotCreateNote', {
      method: 'POST',
      body: JSON.stringify({
        contactId: contact.vid.toString(),
        body: noteBody
      })
    })
    dispatch({ type: 'toast-set', payload: false })
    setTimeout(() => {
      dispatch({ type: 'toast-set', payload: 'Note added to Hubspot!' })
    }, 400)
    setNoteBody('')
  }

  async function createCall() {
    callModal.current.close()
    dispatch({ type: 'toast-set', payload: 'Adding call to Hubspot.' })
    await addDoc(collection(db, 'sundial-activity'), {
      createdAt: new Date(),
      updatedAt: new Date(),
      body: callBody,
      disposition: JSON.parse(callDisposition),
      to: contact.phone,
      from: state.settings.twilioPhone,
      uid: state.user.uid.toString(),
      cid: contact.vid.toString(),
      notCounted: true,
      service_area: state.dialer.currentContact.service_area ? state.dialer.currentContact.service_area : 'undefined',
      lead_source: state.dialer.currentContact.lead_source ? state.dialer.currentContact.lead_source : 'undefined',
      type: {
        label: 'Call',
        value: 'call'
      }
    })
    await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/hubspotCreateCall', {
      method: 'POST',
      body: JSON.stringify({
        contactId: contact.vid.toString(),
        body: callBody,
        toNumber: contact.phone,
        fromNumber: state.settings.twilioPhone,
        disposition: JSON.parse(callDisposition).value
      })
    })
    dispatch({ type: 'toast-set', payload: false })
    setTimeout(() => {
      dispatch({ type: 'toast-set', payload: 'Call added to Hubspot!' })
    }, 400)
    setCallBody('')
  }

  return (
    <div data-aos='fade-in' className='relative h-full'>
      {!activity && activity !== null &&
        <div className='w-full h-full flex'>
          <div className='m-auto'>
            <div className='dot-pulse' />
          </div>
        </div>
      }
      {activity == null &&
        <div className='w-full h-full flex'>
          <div className='m-auto bg-black-825 rounded-full p-4 px-12'>
            No activity.
          </div>
        </div>
      }
      {activity && activity.length > 0 &&
        <ul data-aos='fade-in' className='min-h-full divide-y z-0 py-3'>
          {activity.map(item => {
            const id = item.id
            const data = item.data()
            return (
               <li key={id} className='flex items-center px-3 py-1.5 text-sm'>
                <div className='pr-6'>
                  {data.type.value === 'note' &&
                    <svg xmlns='http://www.w3.org/2000/svg' className='text-black-600 h-4.5 w-4.5' viewBox='0 0 20 20' fill='currentColor'>
                      <path d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z' />
                      <path fillRule='evenodd' d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z' clipRule='evenodd' />
                    </svg>
                  }
                  {data.type.value === 'call' &&
                    <svg xmlns='http://www.w3.org/2000/svg' className='text-black-600 h-4.5 w-4.5' viewBox='0 0 20 20' fill='currentColor'>
                      <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z' />
                      <path d='M16.707 3.293a1 1 0 010 1.414L15.414 6l1.293 1.293a1 1 0 01-1.414 1.414L14 7.414l-1.293 1.293a1 1 0 11-1.414-1.414L12.586 6l-1.293-1.293a1 1 0 011.414-1.414L14 4.586l1.293-1.293a1 1 0 011.414 0z' />
                    </svg>
                  }
                </div>
                <div className='flex-1 truncate'>
                  <p className='text-black-500'>{data.type.value === 'call' && data.disposition.label}{data.type.value === 'call' && <span> &mdash; </span>}{moment(data.createdAt.toDate()).fromNow()}</p>
                  <p className='truncate'>{data.body}</p>
                </div>
               </li>
             )}
           )}
         </ul>
       }
       <div className='p-2 sticky bottom-0 z-10 flex gap-1'>
         <Modal
           ref={noteModal}
           center={true}
           onClose={() => setNoteBody('')}>
           <button
             trigger='true'
             className='text-black-200 bg-black-750 hover:bg-black-725 rounded-full text-xs px-3 h-5 hover:text-white flex space-x-2 items-center'
             onClick={() => noteModal.current.open()}>
             <PlusIcon className='h-3.5 w-3.5 m-auto' />
             <span>Add note</span>
           </button>
           <div content='true' className='max-w-full w-96 space-y-6 p-3'>
             <p className='text-3xl text-center'>Add Note</p>
             <textarea
               value={noteBody}
               className='placeholder-black-400 border focus:ring-2 focus:ring-primary transition duration-200 block h-44 rounded-lg resize-none bg-black-850 w-full focus:outline-none p-4'
               onChange={(e) => setNoteBody(e.target.value)}
               placeholder='Write your wonderful notes here!'
             />
             <button
               disabled={noteBody ? false : true}
               className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' +(noteBody ? 'text-black bg-primary hover:bg-primary-700' : 'bg-black-850 text-black-300')}
               onClick={createNote}>
               Create Note
             </button>
           </div>
         </Modal>
         <Modal
           ref={callModal}
           center={true}
           onClose={() => {
             setCallDisposition(false)
             setCallBody('')
           }}>
           <button
             trigger='true'
             className='text-black-200 bg-black-750 hover:bg-black-725 rounded-full text-xs px-3 h-5 hover:text-white flex space-x-2 items-center'
             onClick={() => callModal.current.open()}>
             <PlusIcon className='h-3.5 w-3.5 m-auto' />
             <span>Add call</span>
           </button>
           <div content='true' className='max-w-full w-96 space-y-6 p-3'>
             <p className='text-center text-3xl'>Add Call Engagement</p>
             <div className='p-4 bg-black-900 rounded-xl border'>
               {state.contactProperties.call_disposistions &&
                 <select
                   onChange={(e) => setCallDisposition(e.target.value)}
                   value={callDisposition ? callDisposition : ''}
                   className={'focus:ring-2 focus:ring-primary transition duration-200 p-4 mb-2 bg-black-850 hover:bg-black-800 appearance-none rounded-lg focus:outline-none w-full cursor-pointer ' + (callDisposition ? '' : 'text-black-400')}>
                   <option disabled value=''>Select call status</option>
                   {state.contactProperties.call_disposistions.map((option) =>
                     <option key={option.value} value={JSON.stringify(option)}>{option.label}</option>
                   )}
                 </select>
               }
               <textarea
                 value={callBody}
                 className='placeholder-black-400 focus:ring-2 focus:ring-primary transition duration-200 rounded-lg block h-44 resize-none bg-black-850 w-full focus:outline-none p-4'
                 onChange={(e) => setCallBody(e.target.value)}
                 placeholder='Add notes to this call activity.'
               />
             </div>
             <button
               disabled={callDisposition ? false : true}
               className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' + (callDisposition ? 'bg-primary hover:bg-primary-700 text-black' : 'bg-black-850 text-black-300')}
               onClick={createCall}>
               Create Call
             </button>
           </div>
         </Modal>
       </div>
     </div>
  )
}

import { useEffect, useState, useRef, createRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { getFirestore, collection, query, startAfter, onSnapshot, where, limit, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'

import { Context } from 'store/index'

import ReactTooltip from 'react-tooltip'
import { XMarkIcon, PencilIcon, EnvelopeIcon, UserIcon, PlusIcon } from '@heroicons/react/24/solid'

import Modal from 'components/modal'

import AddUser from './components/user-add'
import EditUser from './components/user-edit'

import EditModal from './components/edit-modal'
import AddModal from './components/add-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const auth = getAuth()

  const navigate = useNavigate()

  const [state, dispatch] = useContext(Context)

  const mounted = useRef(false)
  const usersUnsub = useRef(null)
  const accessUnsub = useRef(null)
  const addModal = useRef(null)
  const [editUserModalRef, setEditUserModalRef] = useState([])

  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [users, setUsers] = useState(false)
  const [access, setAccess] = useState(false)

  const [page, setPage] = useState(false)
  const [isPaging, setIsPaging] = useState(false)

  const [search, setSearch] = useState('')

  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [currentUser, setCurrentUser] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(usersUnsub.current) usersUnsub.current()
      if(accessUnsub.current) accessUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(search) debouncedGetUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const debouncedGetUsers = useDebouncedCallback(() => {
    getUsers()
  }, 1000)

  useEffect(() => {
    setUsers(false)
    setPage(false)
    debouncedGetUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    async function getAccess() {
      const q = query(collection(db, 'users-access'), orderBy('name', 'asc'))
      accessUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setAccess(querySnapshot.docs.map(d => ({ id: d.id, ...d.data()}) ))
          } else {
            setAccess(null)
          }
        }
      })
    }
    getUsers()
    getAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(users) {
      setEditUserModalRef(refs => (
        Array(users.length).fill().map((_, i) => refs[i] || createRef())
      ))
    }
  }, [users])

  async function getUsers() {
    if(usersUnsub.current) usersUnsub.current()

    let q = null
    if(search && search !== '') {
      q = query(collection(db, 'users'), orderBy('createdAt', 'desc'), where('keyWords', 'array-contains', search.toLowerCase()), limit(25))
    } else {
      q = query(collection(db, 'users'), orderBy('createdAt', 'desc'), limit(25))
      if(page) {
        q = query(q, startAfter(page.data().createdAt))
      }
    }

    usersUnsub.current = onSnapshot(q, (querySnapshot) => {
      if(mounted.current) {
        if(!querySnapshot.empty) {
          if(users) {
            setUsers(prev => {
              if(prev) {
                return ([
                ...prev,
                ...querySnapshot.docs.map(d => ({ id: d.id, ...d.data()}))
                ])
              } else {
                return querySnapshot.docs.map(d => ({ id: d.id, ...d.data()}))
              }
            })
          } else {
            setUsers(querySnapshot.docs.map(d => ({ id: d.id, ...d.data()})))
          }

          const last = querySnapshot.docs[querySnapshot.docs.length - 1]
          setPage(last)
        } else {
          setPage(false)
          if(!users) setUsers(null)
        }
      }
      setIsPaging(false)
    })
  }

  async function loadMore() {
    setIsPaging(true)
    getUsers()
  }

  return (
    <div data-aos='fade-in' className='px-3 py-16 w-full h-screen'>
      <div className='bg-black-875 relative rounded-2xl overflow-hidden flex h-full'>
        <div className='w-1/4 h-full bg-black-825 rounded-2xl'>
          <div className='p-3 space-y-3 h-full flex flex-col justify-between items-start'>
            <div className='w-full'>
              <p className='mb-12 font-bold text-primary'>Users</p>
              <div className='relative'>
                <input
                  autoComplete='off'
                  id='search'
                  className='focus:ring-2 focus:ring-primary transition duration-200 bg-black-775 appearance-none rounded-lg w-full py-2.5 px-3 text-sm placeholder-black-300 focus:outline-none'
                  type='text'
                  placeholder='Search'
                  value={search}
                  onChange={(e) => {
                    setUsers(false)
                    setSearch(e.target.value)
                  }}
                />
                {search !== '' &&
                  <button onClick={() => setSearch('')} className='absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 rounded-full flex items-center justify-center bg-primary text-black'>
                    <XMarkIcon className='h-3.5 w-3.5' />
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='flex-1 overflow-scroll'>
          <div data-aos='fade-in' className='relative h-full'>
            <div className='p-3 fixed top-0 right-0 z-10'>
              <button
                className='bg-black-800 hover:bg-black-750 rounded-full px-4 text-primary py-1 text-sm font-bold'
                onClick={() => {
                  setShowAddModal(true)
                }}>
                Add User
              </button>
            </div>
            <div className='h-full overflow-scroll py-16 space-y-8'>
              {!users || !access ?
                <div>
                  <div className='h-4 w-4 animate-ping bg-green-500 rounded-full m-auto' />
                </div>
                :
                <ul data-aos='fade-in' className='text-sm divide-y'>
                  {users.map((user, index) => {
                    return (
                      <li key={user.id} className='px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
                        <button
                          data-tip='Edit user'
                          data-for={user.id + '-edit'}
                          data-delay-show={1000}
                          onClick={() => {
                            setCurrentUser(user)
                            setShowEditModal(true)
                          }}
                          className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                          <PencilIcon className='h-4 w-4 m-auto' />
                          <ReactTooltip
                            className='text-sm'
                            id={user.id + '-edit'}
                            place='right'
                            type='dark'
                            effect='solid'
                            multiline={true}
                          />
                        </button>
                        <span className='flex items-center'>
                          {user.photo &&
                            <span className='h-9 w-9 rounded-full overflow-hidden'>
                              <img alt='Profile Avatar' className='fade-in h-10 w-10' src={user.photo.url} />
                            </span>
                          }
                          {!user.photo &&
                            <span className='text-black-400 h-9 w-9 rounded-full bg-black-800 flex items-center justify-center'>
                              <UserIcon className='h-3.5 w-3.5 m-auto' />
                            </span>
                          }
                        </span>
                        <span className='flex-1 truncate'>
                          <span>{user.firstName} {user.lastName}</span>
                        </span>
                        <span className='flex-1 truncate text-black-500'>
                          {user.email}
                        </span>
                        {state.profile.access && state.profile.access.global && state.profile.access.global.some(item => 'Super Admin' === item) &&
                          <span className='flex space-x-2'>
                            <button
                              data-tip='Send password reset email'
                              data-for={user.id + '-email'}
                              data-delay-show={1000}
                              onClick={() => {
                                dispatch({ type: 'toast-set', payload: 'Sending email.' })
                                sendPasswordResetEmail(auth, user.email)
                                .then(() => {
                                  dispatch({ type: 'toast-set', payload: false })
                                  setTimeout(() => {
                                    dispatch({ type: 'toast-set', payload: 'Email sent!' })
                                  }, 400)
                                })
                                .catch((error) => {
                                  console.log(error.message)
                                  dispatch({ type: 'toast-set', payload: error.message })
                                })
                              }}
                              trigger='true'
                              className='h-9 w-9 text-black-300 rounded-full bg-black-800 hover:bg-black-775 active:bg-black-750 hover:text-white flex'>
                              <EnvelopeIcon className='h-3.5 w-3.5 m-auto' />
                            </button>

                            <ReactTooltip
                              className='text-sm'
                              id={user.id + '-email'}
                              place='bottom'
                              type='dark'
                              effect='solid'
                              multiline={true}
                            />
                          </span>
                        }
                      </li>
                    )
                  })}
                </ul>
              }
              <div className='py-6'>
                {search === '' && page &&
                  <div className='fade-in'>
                    {!isPaging &&
                      <button
                        onClick={() => loadMore()}
                        className='block m-auto bg-black-825 px-6 py-1.5 text-sm rounded-full text-black-200 hover:bg-black-800 hover:text-white active:bg-black-775'>
                        Load More
                      </button>
                    }
                    {isPaging &&
                      <span className='py-2 text-sm block text-center text-black-300'>
                        Loading more users...
                      </span>
                    }
                  </div>
                }
                {users && !page &&
                  <span className='fade-in block px-3 py-16 text-black-400 text-xs'>
                    There are no more users in this list.
                  </span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditModal access={access} user={currentUser} setCurrentUser={setCurrentUser} showModal={showEditModal} setShowModal={setShowEditModal} />
      <AddModal access={access} setCurrentUser={setCurrentUser} showModal={showAddModal} setShowModal={setShowAddModal} setShowEditModal={setShowEditModal} />
    </div>
  )
}

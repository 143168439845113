import { useRef, useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, deleteDoc, setDoc, doc, arrayRemove } from 'firebase/firestore'

import { ArrowsUpDownIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'

import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'

import { Editor } from '@tinymce/tinymce-react'

import UploadImage from './upload-image'

export default function Index({ location, setCurrentLocation, currentPage, setCurrentPage, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)
  const [original, setOriginal] = useState(false)

  function compareObjs(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setCurrentLocation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  useEffect(() => {
    if(currentPage) {
      setData(currentPage)
      if(!currentPage) setOriginal(currentPage)
    } else {
      setData(false)
      setOriginal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return display ? createPortal(
    <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex absolute right-0 top-0 h-full bg-black-850 max-w-5xl transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='max-w-sm flex flex-col'>
          <div className='p-3'>
            <button
              onClick={() => {
                setShowModal(false)
              }}
              className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
              <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
            </button>
          </div>
          <div className='flex-1 h-full overflow-scroll'>
            {data &&
              <div className='space-y-12 pt-6 pb-12'>
                <div className='px-3'>
                  <h2 className='text-xl mb-1'>{location.name} &mdash; {data.title}</h2>
                  <p className='text-black-400 mb-4'>/{location.states[0].value}/solar-panel-installers/{location.slug}{data.slug === 'landing' ? '' : '/' + data.slug}</p>
                  <div className='flex gap-6'>
                    <div className='flex items-center gap-3'>
                      <label htmlFor={data.id + '-published-edit'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                        <input
                          type='checkbox'
                          checked={data.isPublished ? true : false}
                          id={data.id + '-published-edit'}
                          onChange={(e) => {
                            const isPublished = data.isPublished ? false : true
                            setData(prev => ({
                              ...prev,
                              isPublished: isPublished
                            }))
                            // setDoc(doc(db, 'careers', data.id), {
                            //   isPublished: isPublished,
                            //   updatedAt: new Date()
                            // }, { merge: true })
                          }}
                          className='cursor-pointer h-full w-full sr-only peer' />
                        <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (data.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                      </label>
                      <span className={'text-xs ' + (data.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                    </div>
                  </div>
                </div>

                <div className='px-3 space-y-8'>
                  <div className='space-y-2'>
                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        content: {
                          ...prev.content,
                          headerIntro: e.target.value
                        }
                      }))
                    }} data={data.content ? data.content : {}} property='headerIntro' label='Introduction Header' />

                    <TextInput onChange={(e) => {
                      setData(prev => ({
                        ...prev,
                        content: {
                          ...prev.content,
                          headerMain: e.target.value
                        }
                      }))
                    }} data={data.content ? data.content : {}} property='headerMain' label='Main Header' />
                  </div>

                  <UploadImage
                    data={data}
                    collection='locations'
                    folder='locations'
                    onChange={e => {
                      setData(prev => ({
                        ...prev,
                        image: e
                      }))
                    }}
                  />

                  <TextInput isTextArea={true} onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      content: {
                        ...prev.content,
                        copyIntro: e.target.value
                      }
                    }))
                  }} data={data.content ? data.content : {}} property='copyIntro' label='Introduction Copy' />

                  <TinyMCE />

                  <div className='space-y-2'>
                    <MetaTags data={data} setData={setData} location={location} />
                  </div>
                </div>
              </div>
            }
            <div className='border-t border-black-750 text-sm sticky bottom-0 bg-black-850 p-3 flex z-50 justify-between'>
              <button
                onClick={() => {
                  setData(original)
                }}
                className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
                Discard
              </button>
              <div className='flex gap-1'>
                <button
                  onClick={async () => {
                    const update = {}
                    Object.entries(data).forEach(([k, v]) => {
                      update[k] = v
                    })
                    delete update.id
                    delete update.createdAt
                    delete update.updatedAt
                    await setDoc(doc(db, 'locations-pages', data.id), {
                      ...update,
                      updatedAt: new Date()
                    }, { merge: true })
                    setOriginal(data)
                  }}
                  className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
                  Save
                </button>
                <button
                  onClick={async () => {
                    const update = {}
                    Object.entries(data).forEach(([k, v]) => {
                      update[k] = v
                    })
                    delete update.id
                    delete update.createdAt
                    delete update.updatedAt
                    await setDoc(doc(db, 'locations-pages', data.id), {
                      ...update,
                      updatedAt: new Date()
                    }, { merge: true })
                    setShowModal(false)
                  }}
                  className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-black bg-primary hover:bg-purple-600')}>
                  Save & Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='flex-1 p-4 h-full overflow-hidden'>
          <Content data={data} />
        </div>
      </div>
    </div>
  , document.body) : null
}

function TextInput({ data, location, property, label, onChange, isTextArea, isMeta }) {
  let value = data[property] ? data[property] : ''
  let isStatic = false

  if(isMeta) {
    if(!data.metaTags) data.metaTags = {}
    if(!data.metaTags[property]) {
      if(isMeta.value) {
        value = isMeta.value(location)
        isStatic = true
      } else if(isMeta.default) {
        value = isMeta.default(location)
      } else {
        value = ''
      }
    } else {
      value = data.metaTags[property]
    }
  }

  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      {isStatic &&
        <div className='text-sm text-black-500 leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'>
          {value}
        </div>
      }
      {!isStatic && isTextArea &&
        <textarea
          id={property}
          className='h-18 text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
          type='text'
          onChange={onChange}
          value={value} />
      }
      {!isStatic && !isTextArea &&
        <input
          id={property}
          className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
          type='text'
          onChange={onChange}
          value={value} />
        }
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}

function MetaTags({ location, data, setData }) {
  return (
    <div>
      <div className='space-y-12'>
        {metaTags.map(type =>
          <div key={type.type}>
            <h3 className='mb-6'>Meta Tags &mdash; {type.type}</h3>
            <ul className='space-y-2'>
              {type.tags.map(tag =>
                <li key={tag.name}>
                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      metaTags: {
                        [tag.name]: e.target.value
                      }
                    }))
                  }} location={location} isMeta={tag} data={data} property={tag.name} label={tag.name} />
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

function TinyMCE() {
  const editorRef = useRef(null)
   const log = () => {
     if (editorRef.current) {
       console.log(editorRef.current.getContent())
     }
   }
   return (
     <>
       <Editor
         inline={false}
         apiKey={process.env.REACT_APP_TINY_MCE_API}
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue='<p>This is the initial content of the editor.</p>'
         init={{
           height: 500,
           menubar: false,
           plugins: [
             'advlist autolink lists link image charmap anchor',
             'searchreplace visualblocks code fullscreen',
             'insertdatetime media table paste code'
           ],
           toolbar: 'undo redo | formatselect | ' +
           'bold italic backcolor | alignleft aligncenter ' +
           'alignright alignjustify | bullist numlist outdent indent | ' +
           'removeformat',
           images_upload_handler: async function (blobInfo, success, failure) {
              const formData = new FormData();
              formData.append('file', blobInfo.blob(), blobInfo.filename());

              try {
                // const res = await axios.post('http://my_server.com/upload_handler', formData);
                // Call the success callback and populate the Title field with the file name.
                // success(res.data.location);
              } catch (err) {
                // Handle the fail case, and call the failure callback
                // failure('Image upload failed');
              }
            },
           // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
         }}
       />
       <button onClick={() => {}}>Log editor content</button>
     </>
   )
}

function Content({ data }) {
  return (
    <div className='website-content h-full bg-white rounded-2xl text-black p-3 overflow-scroll'>
      <div className='min-w-0 flex-1' style={{ zoom: '60%' }}>
       <main>
        <section className='pt-10vh pb-20 space-y-12 aos-init aos-animate'>
           <h2 className='font-mono uppercase text-left sm:text-center'>Professional Solar Panel Installers</h2>
           <div className='sm:flex justify-center'>
              <div className='pl-4 sm:pl-8 pr-8 py-1 inline-flex gap-4 items-center justify-center bg-gray-1-40 sm:rounded-l-full rounded-r-full'>
                <span className='relative h-14 w-14 block'>

                </span>
              <span className='block text-left leading-tighter'>Ecowatch <span className='text-gray-6'>— Best Local Solar Installer<br />in Kansas City</span></span></div>
           </div>
           <h1 className='pb-4 text-4xl sm:text-5xl font-bold max-w-4xl text-left sm:text-center mx-auto'>Power your home with clean solar energy — <mark className='text-primary bg-transparent'>Kansas City</mark>, Kansas.</h1>
           <div className='flex flex-col lg:flex-row gap-3 overflow-hidden'>
              <div className='flex-1 aspect-16-9 sm:aspect-16-7 sm:rounded-2xl overflow-hidden bg-gray-1-40 relative relative'>
                 <div className='absolute top-0 h-full w-full'>
                  {data.imageMain &&
                    <img alt='Astrawatt Solar Installation' sizes='(max-width: 768px) 100vw, (max-width: 1200px) 1200px, 1200px' srcset='/_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=640&amp;q=75 640w, /_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=750&amp;q=75 750w, /_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=828&amp;q=75 828w, /_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=1080&amp;q=75 1080w, /_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=1200&amp;q=75 1200w, /_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=1920&amp;q=75 1920w, /_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=2048&amp;q=75 2048w, /_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=3840&amp;q=75 3840w' src='/_next/image?url=%2Fimages%2Flocations%2Fkansas-kansas-city.jpg&amp;w=3840&amp;q=75' decoding='async' data-nimg='fill' className='object-cover h-full w-full overflow-hidden' loading='lazy' />
                  }
                </div>
              </div>
              <div className='w-full lg:max-w-xs bg-gray-1-40 sm:rounded-2xl overflow-hidden aspect-[16/7] lg:aspect-square relative'>
              </div>
           </div>
           <div>
              <div className='w-full max-w-sm sm:m-auto'>
                 <p className='pb-2 text-left sm:text-center text-sm'>Solar Panels — Batteries — EV Chargers</p>
                 <ul className='w-full mb-2 items-center sm:justify-center flex gap-2'>
                    <li className='flex-1'>
                       <div className='w-full bg-primary rounded-full w-full inline-flex items-center justify-between py-2 px-4 font-bold' href='/quote'>
                          <span>Get a Quote</span>
                          <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-6 w-6'>
                             <path strokeLinecap='round' strokeLinejoin='round' d='M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3'></path>
                          </svg>
                       </div>
                    </li>
                    <li className='flex-1'>
                       <div className='w-full w-full bg-gray rounded-full w-full inline-flex items-center justify-between py-2 px-4 font-bold'>
                          <span>Call Us</span>
                          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' aria-hidden='true' className='h-4.5 w-4.5'>
                             <path fillRule='evenodd' d='M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z' clipRule='evenodd'></path>
                          </svg>
                       </div>
                    </li>
                 </ul>
                 <p className='text-gray-6 text-left sm:text-center text-sm'>(877) 937-4786 — (877) YES-4SUN</p>
              </div>
           </div>
           <div className='aspect-[16/7] max-w-2xl m-auto bg-gray-1-40 sm:rounded-2xl flex flex-col sm:flex-row gap-3 px-3 pt-2.5 pb-4'>

           </div>
           <div className='pt-2 px-4 max-w-4xl space-y-6 text-2xl text-left sm:text-center mx-auto'>
              <p>The cost of going solar in the Kansas City metro area has become cheaper for Kansas homeowners due to state incentives and the federal solar tax credit. You can use the federal solar tax credit and state incentives to save thousands of dollars when you go solar.</p>
           </div>
        </section>
        <section className='w-full max-w-4xl m-auto space-y-12 pb-20'>
           <h2 className='px-4 font-mono uppercase text-left sm:text-center -mb-6'>Installations Near You</h2>
           <h3 className='px-4 font-bold max-w-lg sm:m-auto text-2xl sm:text-center'>There are 616 Astrawatt Solar customers in your neighborhood.</h3>
           <div className='sm:aspect-[16/5] sm:rounded-full overflow-hidden bg-gray-1-40 relative'>

           </div>
        </section>
        <section className='mb-12'>
           <section className='space-y-12 aos-init aos-animate'>
              <h2 className='px-4 font-mono uppercase text-left sm:text-center -mb-6'>Our Reviews</h2>
              <h3 className='px-4 font-bold max-w-lg sm:m-auto text-2xl sm:text-center'>Here are some 5 star reviews from our customers on Google.</h3>
              <div className='overflow-hidden pb-5'>
                 <div className='relative reviews w-full m-auto !overflow-visible swiper-coverflow swiper-3d swiper-initialized swiper-horizontal swiper-autoheight swiper-watch-progress'>
                    <div className='swiper-wrapper flex gap-2'>
                       <div className='bg-gray-1-40 flex-1 h-64'></div>
                       <div className='bg-gray-1-40 flex-1 h-64'></div>
                       <div className='bg-gray-1-40 flex-1 h-64'></div>
                    </div>
                    <button className='flex z-10 h-10 w-10 bg-primary hover:bg-gray transition duration-200 rounded-full absolute top-1/2 left-0 transform -translate-y-1/2 ml-3'>
                       <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-4.5 w-4.5 m-auto'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18'></path>
                       </svg>
                    </button>
                    <button className='flex z-10 h-10 w-10 bg-primary hover:bg-gray transition duration-200 rounded-full absolute top-1/2 right-0 transform -translate-y-1/2 mr-3'>
                       <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-4.5 w-4.5 m-auto'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3'></path>
                       </svg>
                    </button>
                 </div>
              </div>
           </section>
        </section>
        <div className='aos-init aos-animate'>
           <h3 className='font-mono uppercase pb-12 text-left sm:text-center pt-1'>Get a custom quote</h3>
           <h2 className='font-bold pb-12 text-left sm:text-center m-auto max-w-3xl text-3xl md:text-4xl'>Our mission is to <mark className='text-primary bg-transparent'>provide better solar experiences</mark> — that starts with making it <mark className='text-primary bg-transparent'>easy</mark> for you to go solar.</h2>
           <p className='text-left sm:text-center text-2xl pb-16 max-w-xl mx-auto'>We provide you with all the information you need to make an educated decision.</p>
        </div>
        <ul className='pt-1 w-full mx-auto flex flex-col md:flex-row gap-6 lg:gap-8 md:items-start aos-init aos-animate'>
           <li className='flex-1 p-4 bg-gray-1-40 rounded-2xl sm:mt-12'>
              <p className='font-mono text-xs uppercase'>Popular</p>
              <h3 className='font-bold text-2xl pb-4'>Schedule a consultation</h3>
              <svg className='overflow-visible w-44 mx-auto pb-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 776.04 779.67'>
                 <path className='fill-gray-1-80 animate-ground' d='M15.71,240.47c-55.05-87.3,12.07-460.05,569.84,128.99,71.85,75.88,86.37,225.63,9.66,292.66-82.18,71.82-383.45,181.48-467.1,69.76C9.52,573.49,190.98,518.42,15.71,240.47Z'></path>
                 <path className='animate-stars fill-gray-3' d='M743.84,582.67H286.96c-17.76,0-32.21-14.45-32.21-32.21V32.21c0-17.76,14.45-32.21,32.21-32.21h456.88c17.76,0,32.21,14.45,32.21,32.21V550.46c0,17.76-14.45,32.21-32.21,32.21ZM286.96,6.15c-14.37,0-26.06,11.69-26.06,26.06V550.46c0,14.37,11.69,26.06,26.06,26.06h456.88c14.37,0,26.06-11.69,26.06-26.06V32.21c0-14.37-11.69-26.06-26.06-26.06H286.96Z'></path>
                 <g>
                    <circle className='fill-primary' cx='312.49' cy='703.1' r='27.51'></circle>
                    <path className='fill-gray-1-60' d='M305.92,717.02c-.53,0-1.05-.15-1.52-.45-.81-.52-1.29-1.4-1.29-2.36v-19.85c0-1.01,.52-1.92,1.4-2.43,.88-.51,1.92-.51,2.8,0l19.3,11.03c.92,.53,1.46,1.51,1.41,2.57-.05,1.06-.68,1.99-1.64,2.42l-19.3,8.82c-.37,.17-.77,.26-1.17,.26Z'></path>
                 </g>
                 <g>
                    <path className='animate-stars fill-gray-3' d='M690.95,437.71H332.34c-3.39,0-6.15,2.76-6.15,6.15s2.76,6.15,6.15,6.15h358.61c3.39,0,6.15-2.76,6.15-6.15s-2.76-6.15-6.15-6.15Z'></path>
                    <path className='fill-black-800' d='M569.54,437.71v12.3h-237.2c-3.39,0-6.15-2.76-6.15-6.15s2.76-6.15,6.15-6.15h237.2Z'></path>
                 </g>
                 <ellipse className='fill-black-800' cx='283.27' cy='41.85' rx='8.85' ry='9.05'></ellipse>
                 <ellipse className='fill-black-800' cx='313.85' cy='41.85' rx='8.85' ry='9.05'></ellipse>
                 <ellipse className='fill-black-800' cx='344.44' cy='41.85' rx='8.85' ry='9.05'></ellipse>
                 <g>
                    <polygon className='fill-[#9f616a]' points='199.7 721.13 214.52 721.13 221.58 663.94 199.69 663.94 199.7 721.13'></polygon>
                    <path className='fill-black-800' d='M250.5,720.8h0c.46,.78,.71,3.29,.71,4.19h0c0,2.78-2.25,5.03-5.03,5.03h-45.92c-1.9,0-3.43-1.54-3.43-3.43v-1.91s-2.27-5.75,2.41-12.83c0,0,5.81,5.55,14.5-3.14l2.56-4.64,18.54,13.56,10.28,1.26c2.25,.28,4.24-.04,5.4,1.9h0Z'></path>
                 </g>
                 <g>
                    <polygon className='fill-[#9f616a]' points='293.69 672.66 308.14 675.96 327.77 621.78 306.44 616.9 293.69 672.66'></polygon>
                    <path className='fill-black-800' d='M343.29,683.65h0c.28,.86-.05,3.36-.25,4.24h0c-.62,2.71-3.32,4.4-6.03,3.78l-44.76-10.24c-1.85-.42-3-2.26-2.58-4.11l.43-1.86s-.93-6.11,5.2-11.97c0,0,4.43,6.7,14.83,.17l3.53-3.95,15.05,17.35,9.74,3.52c2.13,.77,4.14,.9,4.84,3.06h0Z'></path>
                 </g>
                 <polygon className='fill-black-800' points='190.48 402.99 173.16 426.75 195.48 685.56 224.19 685.56 229.24 559.28 243 463.01 314.26 538.03 299.26 633.05 328.02 644.3 360.52 523.02 305.51 431.75 298.01 407.99 190.48 402.99'></polygon>
                 <path className='fill-primary' d='M269.25,196.69h-35.85l-11.67,15-41.26,18.75,6.25,153.79-13.75,42.51s65.02,31.26,125.03-18.75l-1.25-45.01,21.26-130.03-37.51-23.76-11.25-12.5h0Z'></path>
                 <g>
                    <path className='fill-[#9f616a]' d='M140.96,419.39c1.09-3.68,2.93-6.8,5.09-9.02l8.21-35.69,16.87,4.4-10.15,35.73c.6,3.04,.43,6.66-.67,10.34-2.5,8.4-8.86,13.92-14.21,12.33-5.35-1.59-7.65-9.69-5.15-18.09h0Z'></path>
                    <path className='fill-primary' d='M199.77,233l-11-5.63s-10.57,.73-15.47,10.53c-4.9,9.8-4.9,80.39-4.9,80.39l-22.55,81.37,19.61,10.78,26.65-75.48,7.66-101.95h0Z'></path>
                 </g>
                 <g>
                    <path className='fill-[#9f616a]' d='M318.37,428.62c-.08-3.84,.72-7.37,2.11-10.14l-3.03-36.49,17.41-.93,1.2,37.12c1.5,2.71,2.43,6.22,2.51,10.05,.17,8.76-4.21,15.96-9.78,16.07-5.58,.11-10.24-6.9-10.41-15.67h0Z'></path>
                    <path className='fill-primary' d='M317.71,233.17l-12.19-2.02s-9.85,3.91-11.54,14.73c-1.69,10.83,19.77,78.07,19.77,78.07l3.26,84.37,21.96,4.31,2.44-80.01-23.71-99.45h0Z'></path>
                 </g>
                 <circle className='fill-[#9f616a]' cx='254.37' cy='155.32' r='34.96'></circle>
                 <g>
                    <ellipse className='fill-gray-3' cx='623.08' cy='192.03' rx='12.77' ry='12.5'></ellipse>
                    <path className='fill-gray-3' d='M725.46,195.95h-68.74c-2.36,0-4.28-1.92-4.28-4.28s1.92-4.28,4.28-4.28h68.74c2.36,0,4.28,1.92,4.28,4.28s-1.92,4.28-4.28,4.28Z'></path>
                 </g>
                 <g>
                    <circle className='fill-black-800' cx='131.72' cy='423' r='17.5'></circle>
                    <path className='fill-gray-1-60' d='M140.82,412.75c-.83-.49-2.11-.23-2.57,.67-2.08,4.12-4.16,8.24-6.24,12.37-1.68-2.63-3.83-4.93-6.32-6.85-.8-.62-2.1-.12-2.57,.67-.57,.97-.13,1.94,.67,2.57,2.9,2.24,5.14,5.26,6.65,8.59,.57,1.27,2.64,1.19,3.24,0,2.6-5.15,5.2-10.3,7.8-15.45,.46-.9,.24-2.03-.67-2.57Z'></path>
                 </g>
                 <path className='fill-gray-3' d='M713.76,226.64h-190.47c-12.18,0-22.08-9.91-22.08-22.08V111.43c0-12.18,9.91-22.08,22.08-22.08h190.47c12.18,0,22.08,9.91,22.08,22.08v93.13c0,12.18-9.91,22.08-22.08,22.08Zm-190.47-135.25c-11.05,0-20.03,8.99-20.03,20.03v93.13c0,11.05,8.99,20.03,20.03,20.03h190.47c11.05,0,20.03-8.99,20.03-20.03V111.43c0-11.05-8.99-20.03-20.03-20.03h-190.47Z'></path>
                 <path className='fill-black-800' d='M293.37,132.81c-9.12,.72-7.87-3.63-7.87-3.63l.82-7.42c-.24-1.28-.68-2.32-1.57-2.82-2.08-1.18-4.19,1.05-6.4,.12-2.01-.84-4.03-1.69-6.13-2.26-2.67-.72-3.97,2.16-6.73,2.38-2.61,.2-6.66-2.32-9.21-1.7-2.24,.55,.69-2.93-2.05-3.3l-8.75,5s-3.34,2.92-3.75,0c-7.14,1.15-12.73-3.84-15.09,8.3-1.5,7.68-9.15,11.87-11.19,19.43-2.91,10.76,2.03,20.08,8.55,27.55,1.61,1.84-.93,5.07,.24,7.22,1.09,2,3.56,2.93,4.67,4.92,.95,1.7,2.67-1.13,3.78,.48,.95,1.38-2.64,2.1-.96,2.12,.82,.01,2.95,.18,3.75,0,3.04,.2,.66,.07,3.2,.23,1.11-6.46-3.22-8.33-5.49-14.48-.76-2.05-1.69-4.06-2.15-6.2-.46-2.14-.4-4.5,.76-6.36,1.77-2.84,5.88-3.72,8.88-2.23,3,1.49,4.77,4.92,4.74,8.26,2.59-2.2,4.62-4.97,6.05-8.04l-.99-4.94s1.16,.95,2.15,1.96c1.82-5.68,1.69-12.01-.76-17.46,1.28-1.01,10.65,3.05,18.61,4.86v-.62l6.25,1.25c6.18,1.12,7.85,2.65,6.25-3.75-.52-2.09,2.5,7.5,7.5,5,3.13-1.57-.61-4.56,1.43-7.4,1.33-1.85,1.73-4.19,1.43-6.47h0Z'></path>
                 <rect className='fill-black-800' x='454.69' y='297.99' width='199.88' height='1.02'></rect>
                 <g>
                    <path className='animate-stars fill-black-800' d='M546.42,242.16l-8.43-3.99c3.46,4.83,6.44,12.37,8.03,18.36,2.7-5.59,7.06-12.42,11.37-16.51l-8.91,2.29c5.49-26.91,26.15-46.24,49.8-46.24v-2.05c-24.71,0-46.25,20.13-51.86,48.13Z'></path>
                    <path className='fill-primary' d='M585.23,283.17h-53.28c-2.26,0-4.1-1.84-4.1-4.1s1.84-4.1,4.1-4.1h53.28c2.26,0,4.1,1.84,4.1,4.1s-1.84,4.1-4.1,4.1Z'></path>
                 </g>
              </svg>
              <h4 className='font-bold'>In-home consultation</h4>
              <p className='pb-4 text-sm'>Schedule a time to meet with one of our solar consultants at your home.</p>
              <h4 className='font-bold'>Virtual consultation</h4>
              <p className='pb-8 text-sm'>Schedule a time to meet with one of our expert solar consultants on a virtual meeting.</p>
              <p><a className='text-left sm:text-center rounded-full inline-block cursor-pointer py-1.5 px-8 font-bold text-black bg-primary' href='/schedule'>Schedule</a></p>
           </li>
           <li className='order-first md:order-none w-full md:w-4/12 p-4 ring-4 ring-primary rounded-2xl'>
              <div className='flex justify-between'>
                 <div className='pb-4'>
                    <p className='font-mono text-xs uppercase'>Most Popular</p>
                    <h3 className='font-bold text-2xl'>AI savings estimate</h3>
                 </div>
                 <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' aria-hidden='true' className='h-7 w-7 fill-primary'>
                    <path d='M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z'></path>
                 </svg>
              </div>
              <svg className='overflow-visible w-44 mx-auto pb-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 528.58 530.39'>
                 <path className='fill-gray-1-80 animate-ground-reverse' d='M62.79,178.18c29.61-22.85,101.5-17.96,205.29-54.88,86.78-30.87,155.36-183.62,208.3-97.08,47.78,78.11-49.97,220.45,3.2,293.76,95.34,131.44,43.59,244.45-86.67,146.59-182.29-136.96-155.76-63.55-270.73-51.54-64.37,6.73-202.9-126.1-59.39-236.85Z'></path>
                 <circle className='fill-gray-4' cx='53.38' cy='159.74' r='4.76'></circle>
                 <path className='animate-stars fill-black-800' d='M167.11,196.8c-18.5-7.68-36.27-17-53.11-27.86-40.42-26-67.98-56.07-68.58-74.83-.18-5.48,1.86-9.83,6.05-12.92,6.78-5,18.87-6.2,34.96-3.47l-.4,2.34c-15.44-2.62-26.9-1.57-33.15,3.04-3.53,2.6-5.24,6.28-5.09,10.93,.58,18.01,27.7,47.31,67.49,72.91,16.71,10.78,34.35,20.04,52.72,27.66l-.9,2.2Z'></path>
                 <path className='animate-stars fill-black-800' d='M224.34,210.89c-7.05,0-15.4-1.14-24.9-3.41l.55-2.31c19.94,4.78,35.03,4.38,42.48-1.12,3.53-2.6,5.24-6.28,5.09-10.93-.58-18.01-27.7-47.31-67.49-72.91-20.17-12.97-41.59-23.78-61.94-31.24l.82-2.23c20.52,7.53,42.1,18.41,62.41,31.47,40.42,26,67.98,56.07,68.58,74.83,.18,5.48-1.86,9.83-6.05,12.92-4.45,3.28-11.06,4.93-19.55,4.93Z'></path>
                 <path className='animate-stars fill-black-800' d='M71.1,210.87c-8.52,0-15.18-1.63-19.62-4.91-4.19-3.09-6.23-7.44-6.05-12.92,.6-18.76,28.16-48.83,68.58-74.83,45.17-29.06,95.88-46.28,120.57-40.94l-.5,2.32c-24.13-5.22-74.08,11.87-118.78,40.62-39.79,25.59-66.91,54.89-67.49,72.91-.15,4.65,1.56,8.33,5.09,10.93,17.85,13.17,74.91-3.48,127.19-37.1,35.91-23.1,61.43-48.94,66.59-67.45l2.29,.64c-5.32,19.04-31.22,45.41-67.6,68.81-25.6,16.47-53.13,29.41-77.52,36.44-12.67,3.65-23.74,5.48-32.75,5.48Z'></path>
                 <circle className='animate-stars fill-primary' cx='102.69' cy='82.46' r='7.13'></circle>
                 <circle className='animate-stars fill-primary' cx='182.34' cy='201.74' r='7.13'></circle>
                 <circle className='fill-gray-4' cx='204.53' cy='266.73' r='4.76'></circle>
                 <circle className='fill-gray-4' cx='87.23' cy='37.68' r='4.76'></circle>
                 <circle className='animate-stars fill-primary' cx='244.96' cy='86.02' r='7.13'></circle>
                 <polygon className='fill-[#9f616a]' points='302.74 466.67 283.34 461.04 274.87 513.55 288.01 517.37 302.74 466.67'></polygon>
                 <path className='fill-black-800' d='M245.98,518.39l.56-1.92c2.25-7.75,10.36-12.21,18.11-9.96l27.27,7.92h0l-4.64,15.96h0l-41.31-12h0Z'></path>
                 <polygon className='fill-[#9f616a]' points='423.84 464.89 403.64 464.89 410.15 517.68 423.83 517.68 423.84 464.89'></polygon>
                 <path className='fill-black-800' d='M383.75,530.38v-2c0-8.07,6.54-14.62,14.62-14.62h28.4s0,16.62,0,16.62h-43.01s0,0,0,0Z'></path>
                 <path className='fill-[#9f616a]' d='M405.26,336.97c5.62-2.69,7.99-9.43,5.3-15.06l-3.83-36.03-19.06-8.15,7.11,39.25c-5.41,3.09-7.29,9.99-4.19,15.4,2.94,5.13,9.34,7.13,14.67,4.57Z'></path>
                 <path className='fill-primary' d='M401.85,239.93l-22.36-67.89c-.47-.78-6.03-9.54-18.15-5.81-2.7,.76-4.97,2.61-6.26,5.11-3.55,6.8-.24,17.31-.22,17.37l27.53,61.7,.03,.06,8.69,63.36,18.77-3.81-8.02-70.08Z'></path>
                 <path className='fill-black-800' d='M253.2,491.1l33.66-103.22,39.03-89.21-.56-43.27,46.31-10.8,5.39,18.13c31.19,20.58,20.92,60.17,20.18,62.83l47,173.45-51.25,5.69-34.4-164.23-31.72,66.73-22.43,99.82-51.22-15.93Z'></path>
                 <path className='fill-primary' d='M321.99,219.08c-9.21-17.65-2.55-24.37,7.52-34.54,4.11-4.02,7.98-8.28,11.56-12.78,15.71-20.19,33.94-10.28,34.12-10.17l.1,.06,4.5,6.76c14.84,14.94-7.67,55.98-9.37,59.04l1.13,29.35-47.86,4.56-1.69-42.27Z'></path>
                 <circle className='fill-[#9f616a]' cx='351.56' cy='125.46' r='27.42'></circle>
                 <path className='fill-[#9f616a]' d='M340.23,332.39c2.58,5.67,9.28,8.18,14.95,5.59,4.75-2.16,7.39-7.29,6.4-12.41l5.41-35.82-16.38-12.7-3.06,39.77c-6,1.58-9.58,7.72-8,13.71,.17,.64,.39,1.26,.67,1.86Z'></path>
                 <path className='fill-primary' d='M343.7,313.74l7.73-64.4-11.02-66.64c-.03-.47-.57-11.52,4.86-17.51,2.05-2.29,4.96-3.62,8.04-3.68,13.77-.6,17.06,10.87,17.1,10.99l4.48,71.59-10.12,70.82-21.07-1.17Z'></path>
                 <path className='fill-black-800' d='M323.6,120.72l1.49-2.4c7.61-1.52,15.34,1.96,19.25,8.66,.94-3.47,6.54-3.29,8.61-.35,2.07,2.94,1.61,6.91,.94,10.44-.67,3.53-1.43,7.37,.2,10.57,1.77,3.05,5.04,4.91,8.57,4.87,3.47-.08,6.85-1.16,9.73-3.1,10.81-6.88,16.72-19.37,15.18-32.09-.7-4.86-2.5-9.49-5.27-13.54,1.59-.12,3.07-.84,4.15-2.02,1.52-1.98,1.8-4.64,.73-6.89-.92-1.93-2.32-3.59-4.07-4.83,1.26,1,2.73,1.69,4.3,2.01,1.93,.28,4.19-.73,4.54-2.59,.33-1.78-1.09-3.39-2.4-4.69-.85-.84-1.71-1.69-2.56-2.53,.44-.5,.85-1.03,1.23-1.58,2.9-4.07,3.15-9.46,.64-13.78,2.44,4.17,.3,9.13-4,12.5-.14,.11-.29,.2-.44,.31l-1.63-1.62c-1.28-1.45-2.84-2.62-4.59-3.43-1.8-.69-4.11-.49-5.29,.98-1.26,1.57-.91,4.07-2.38,5.45-1.29,1.21-3.39,1.01-5.13,.5-1.74-.51-3.53-1.26-5.29-.87-2.45,.72-3.95,3.18-3.47,5.69,.2,1.04,.64,2.02,1.28,2.87-4.85-.41-9.73-.43-14.59-.05-5.72,.32-11.56,.71-16.83,2.95-5.27,2.23-9.96,6.67-10.93,12.31-.97,5.64,2.33,15.75,8.03,16.24Z'></path>
              </svg>
              <p className='pb-4'>Our AI enabled solar savings calculator takes about a minute and we instantly answer your questions about solar.</p>
              <ul className='space-y-3 pb-8'>
                 <li className='flex gap-3 items-center'>
                    <span className='shrink-0 h-9 w-9 bg-gray-1-60 rounded-full flex'>
                       <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-5 w-5 m-auto'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5'></path>
                       </svg>
                    </span>
                    <div>
                       <h4 className='text-sm font-bold'>Number of panels for your home</h4>
                       <p className='text-xs text-gray-11'>How many solar panels you will need and the ideal system size for your home.</p>
                    </div>
                 </li>
                 <li className='flex gap-3 items-center'>
                    <span className='shrink-0 h-9 w-9 bg-gray-1-60 rounded-full flex'>
                       <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-5 w-5 m-auto'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5'></path>
                       </svg>
                    </span>
                    <div>
                       <h4 className='text-sm font-bold'>New Monthly Electricity Bills</h4>
                       <p className='text-xs text-gray-11'>How much will you reduce your estimated monthly electricity bills if you go solar.</p>
                    </div>
                 </li>
                 <li className='flex gap-3 items-center'>
                    <span className='shrink-0 h-9 w-9 bg-gray-1-60 rounded-full flex'>
                       <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-5 w-5 m-auto'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5'></path>
                       </svg>
                    </span>
                    <div>
                       <h4 className='text-sm font-bold'>Your Estimated Savings</h4>
                       <p className='text-xs text-gray-11'>How much money you can expect to save over time if you go solar.</p>
                    </div>
                 </li>
                 <li className='flex gap-3 items-center'>
                    <span className='shrink-0 h-9 w-9 bg-gray-1-60 rounded-full flex'>
                       <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-5 w-5 m-auto'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5'></path>
                       </svg>
                    </span>
                    <div>
                       <h4 className='text-sm font-bold'>Recommended Panel Locations</h4>
                       <p className='text-xs text-gray-11'>We will show you recommended locations for solar panels on your home to get the most production out of your solar panels.</p>
                    </div>
                 </li>
              </ul>
              <p><a className='text-left sm:text-center rounded-full inline-block cursor-pointer py-1.5 px-8 font-bold text-black bg-primary' href='/quote/ai-solar-calculator'>Get a Quote</a></p>
           </li>
           <li className='flex-1 p-4 bg-gray-1-40 rounded-2xl md:mt-12'>
              <p className='font-mono text-xs uppercase'>Popular</p>
              <h3 className='font-bold text-2xl pb-4'>Give us a call</h3>
              <svg className='overflow-visible w-44 mx-auto pb-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 623.58 633.22'>
                 <path className='fill-gray-1-80 animate-ground' d='M54.23,260.39C138.52,176.75,541.97-1.74,620.21,189.48c27.17,66.4-120.63,129.84-63.36,208.8,102.69,141.58,7,306.97-133.31,201.56C227.2,452.32,70,483.79,20.15,438.43c-27.81-25.31-35.43-109.05,34.08-178.04Z'></path>
                 <path className='fill-gray-3' d='M558.29,347.8h-17.67c-24.65,0-44.63,19.98-44.63,44.63v74.37h14v-41.04c0-24.65,19.98-44.63,44.63-44.63h-10.33c24.65,0,44.63,19.98,44.63,44.63v41.04h14v-74.37c0-24.65-19.98-44.63-44.63-44.63Z'></path>
                 <path className='fill-gray-5' d='M461.95,147.73C389.64,106.71,417.3,1.08,337.87,34.73c-103.14-24.56-44.52,78.03-136.07,113-17.63,0-31.93,14.29-31.93,31.93h0c0,17.63,14.29,31.93,31.93,31.93,.37,0,.75,0,1.12-.02l11.95-.42v100.58h234v-100.58l11.95,.42c17.62,.62,32.41-13.16,33.03-30.79,.01-.37,.02-.75,.02-1.12h0c0-17.63-14.29-31.93-31.93-31.93h0Z'></path>
                 <path className='fill-gray-5' d='M439.62,358.73H221.13c-5.11,0-9.25,4.14-9.25,9.25h0v13.5c0,5.11,4.14,9.25,9.25,9.25h16.75v78h12v-78h156v78h12v-78h21.75c5.11,0,9.25-4.14,9.25-9.25v-13.5c0-5.11-4.14-9.25-9.25-9.25Z'></path>
                 <path className='fill-gray-3' d='M484.62,336.73h-6.7c1.94-3.34,2.95-7.14,2.95-11V197.73c0-12.15-9.85-22-22-22s-22,9.85-22,22v105.79c-164.69-23.13-57.36-22.9-206,.74v-106.53c0-12.15-9.85-22-22-22s-22,9.85-22,22v128c0,3.86,1.01,7.66,2.95,11h-13.7c-5.11,0-9.25,4.14-9.25,9.25h0v13.5c0,5.11,4.14,9.25,9.25,9.25H484.62c5.11,0,9.25-4.14,9.25-9.25v-13.5c0-5.11-4.14-9.25-9.25-9.25Z'></path>
                 <path className='fill-[#ffb6b6]' d='M173.92,206.01c8.08,.74,15.28-5.09,16.24-13.15,.08-.79,.1-1.58,.05-2.38l91.61-45.33-22.26-26.59-85.96,58.61c-6.72,.95-11.95,6.3-12.75,13.04-.85,7.85,4.83,14.9,12.68,15.75,.13,.01,.26,.03,.39,.04Z'></path>
                 <path className='fill-black-800' d='M375.23,123.75s8.9-17.65-18.88-24.06-79.06,15.16-81.67,17.63,.78-.88-7.47-2.17c-3.96-.62-11.87-7.32-13.5-1.68s-1.18,12.42-5.93,6.93,13.98,46.89,13.98,46.89c0,0,2.42-.39,10.99-4.93,4.81-2.55,4.86-13.15,8.08-10.18s31.85,2.46,33.45,2.07,8.82-13.67,8.82-13.67l52.13-16.84Z'></path>
                 <polygon className='fill-[#ffb6b6]' points='226.36 452.24 211.11 449.06 216.11 388.72 238.62 393.41 226.36 452.24'></polygon>
                 <path className='animate-stars fill-black-800' d='M223.58,468.26l-46.9-9.78,.12-.59c2.1-10.08,11.98-16.55,22.06-14.45h0s9.92-4.71,9.92-4.71l14.63,9.83,4.09,.85-3.93,18.85Z'></path>
                 <polygon className='fill-[#ffb6b6]' points='488.96 382.3 504.14 378.8 497.84 318.58 475.43 323.75 488.96 382.3'></polygon>
                 <path className='fill-black-800' d='M492.08,398.26l46.68-10.78-.14-.59c-2.32-10.03-12.33-16.29-22.36-13.97h0s-10.02-4.5-10.02-4.5l-14.41,10.14-4.07,.94,4.33,18.76Z'></path>
                 <path className='fill-black-800' d='M309.87,243.73l-2.62,29.62s-85.02-27.3-86.05,36.92c-.33,20.46-9.33,99.46-9.33,99.46l26,4s14-21,14-28,14-58,14-58l72.97-7.18c77.03-4.82,101.03-6.82,118.03-61.82,0,0,10.36,73.59,16.18,79.29s10.12,.03,2.97,7.37-12.15,4.34-7.15,7.34,35-2,35-2c6.7,.75-1.89-106.47-24.35-138.12-20.04-28.24-89.17,60.5-89.17,60.5l-80.49-29.38Z'></path>
                 <path className='fill-black-800' d='M399.96,117.75s25.99-2.95,31.52,9.5,19.48,38.39,19.48,38.39c0,0-7.15,3.05,.15-4.87,3.27-3.55-2.77-1.38,1.38-2.77s5.54,0,5.54,0l13.84,23.52s-1.11,5.36-8.3,5.54,4.15-6.92-5.54,2.77c-5,5-23.16,11.9-23.16,11.9l-46.42-42.66,11.51-41.32Z'></path>
                 <path className='fill-primary' d='M318.01,105.11l26.27-6.81,12.26-16.91,29.12,3.58,6.2,23.07,20.73,12.03s-11.46,121.18-8.73,127.83-3.89,12.22-12.23,20.8,6.93-.99,8.87,8.35,16.05,19.22,0,19.12-104.35,4.36-101.58-6.71-5.84-14.1-1.67-21.04,6.98-3.06,5.58-13.3-.02-17.16-.02-22.7,4.64-55.83,4.64-55.83l10.59-71.47Z'></path>
                 <path className='fill-black-800' d='M412.37,296.38c5.01,8.94-23.21,11.57-53.77,10.36-.59-2.26-1.57-4.41-2.89-6.34-3.38-4.9,.71-38.04-4.22-41.39,2.23,5.4-6.78,41.26-8.66,46.79-27.65-2.21-52.85-7.24-52.54-13.22,.08-1.74,5.85-34.95,8.65-37.47,2.78-2.52-.03-50.07,0-60.26,.01-10.21,15.19-85.28,15.19-88.05s35.98-16.61,35.98-16.61l15.22,87.18,23.14-81.98c2.26,3.08,4.88,5.88,7.79,8.34,4.64,3.86,23.04,19.66,23.04,19.66l-15.76,116.63s7.46,17.6,7.07,24.59c-.15,5.54-1.24,11.02-3.22,16.2-2.24,6.57-2.01,3.04,5,15.55Z'></path>
                 <path className='animate-stars fill-[#ffb6b6]' d='M437.13,55.4c6,5.47,6.55,14.72,1.25,20.86-.53,.59-1.1,1.14-1.72,1.64l28.35,98.2-34.64,1.51-14.72-102.99c-3.83-5.6-3.41-13.08,1.03-18.21,5.22-5.92,14.26-6.49,20.18-1.27h0c.1,.09,.2,.17,.29,.26Z'></path>
                 <path className='fill-black-800' d='M424.9,169.23c1.11-.88,35.58-12.07,35.58-12.07,0,0,14.64,21.79,5.79,28.83s-28.18,8.89-28.18,8.89c0,0-8.12-4.57-7.93-9.95s-5.27-15.69-5.27-15.69Z'></path>
                 <path className='fill-black-800' d='M403.56,68.29l3.75-27.6c.52-3.83,3.13-6.34,5.83-5.6l6.7,1.84c2.69,.74,4.46,4.45,3.94,8.28l-3.75,27.6c-.52,3.83-3.13,6.34-5.83,5.6l-6.7-1.84c-2.69-.74-4.46-4.45-3.94-8.28Z'></path>
                 <path className='fill-primary' d='M405.42,66.69l3.82-25.25c.53-3.5,2.44-5.72,4.25-4.95l4.51,1.91c1.81,.77,2.86,4.24,2.33,7.75l-3.82,25.25c-.53,3.5-2.44,5.72-4.25,4.95l-4.51-1.91c-1.81-.77-2.86-4.24-2.33-7.75Z'></path>
                 <path className='fill-black-800' d='M334.16,40.46c2.6,21.02,17.17,38.35,38.35,38.35s38.35-17.17,38.35-38.35h0c0-21.18-17.26-36.39-38.35-38.35-22.37-2.08-41.69,11.36-38.35,38.35Z'></path>
                 <circle className='fill-[#ffb6b6]' cx='376.74' cy='47.08' r='34'></circle>
                 <path className='fill-black-800' d='M341.53,50.79c1.08,7.39,7.37,17.7,5.9,17.7s-11.96-30.46-1.47-32.45c7.61-1.44,9.31-.18,16.41-3.26l-1,.94c4.7,3.42,11.25,1.31,16.62-.91,5.38-2.21,11.92-4.32,16.64-.91,2.96,2.14,4.22,5.86,6.06,9.01,1.84,3.17,5.25,6.16,8.76,5.19,2.82-.78,4.44-3.94,4.42-6.86s-3.82-5.7-2.54-8.32c3.79-7.76,.38-13.06-7.89-16.71-3.01-.46-6.02-.92-9.03-1.4,1.58-2.18,3.49-4.1,5.66-5.69-1.12-2.29-2.4-4.59-4.57-5.86-2.4-1.43-5.38-1.33-8.17-1.18-6.22,.32-12.44,.65-18.66,.97-4.6,.24-9.37,.52-13.48,2.63-5.13,2.64-8.5,7.79-10.94,13.02-5.49,11.77-4.62,21.24-2.73,34.08Z'></path>
              </svg>
              <h4 className='font-bold'>We're Here for You</h4>
              <p className='pb-3 text-sm'>Call us to ask any questions you may have about going solar or to schedule a solar consultation.</p>
              <p className='text-sm pb-5 text-gray-9'>816 489-4154</p>
              <p>
                 <a href='tel:816 489-4154' className='rounded-full inline-flex items-center justify-center space-x-1 cursor-pointer py-1.5 pl-4 pr-6 font-bold text-black bg-primary'>
                    <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
                       <path d='M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z'></path>
                    </svg>
                    <span>Call Us</span>
                 </a>
              </p>
           </li>
        </ul>
        <section className='px-4 pt-12 pb-20 prose prose-lg w-full max-w-2xl mx-auto aos-init aos-animate'>
           <div>
              <h2>Kansas Solar Incentives and Tax Credits</h2>
              <h3>Federal Solar Tax Credit</h3>
              <p>The federal solar tax credit will reduce your costs of going solar more than anything else if you live in Kansas.</p>
              <p>The Investment Tax Credit (ITC), also called the federal solar tax credit, helps you reduce the cost of your solar energy system. You can deduct 30% of the cost of your solar energy system from your federal income taxes. There is no cap on the value of your tax credit and it applies to both residential and commercial systems.</p>
              <p>The <a target='_blank' href='https://www.energy.gov/eere/solar/articles/solar-investment-tax-credit-what-changed'>federal solar tax credit</a> was recently increased to 30% and its timeline has been extended. If you install a solar PV system between now and 2032 you will receive a 30% tax credit. If you’ve already installed your solar system in 2022, your tax credit has been increased from 26% to 30% and you can take advantage of the increased tax savings.</p>
              <h3>Net Metering in Kansas</h3>
              <p>Net Metering is when your utility company monitors how much energy your home’s solar system produces and how much energy you consume. If your home’s solar system generates a surplus of energy, you will receive a credit from your utility company for that surplus energy.</p>
              <p>Homeowners in some states receive full retail rate credit for the amount of surplus energy you send back into the grid. In Kansas, at a system cost rate for the amount of surplus energy you generate.</p>
              <p>Under the current <a target='_blank' href='https://www.solarreviews.com/solar-incentives/kansas'>Net Metering Law in Kansas</a>, the system cost rate is a utility company’s wholesale cost - about 2.1 cents per kWH for wholesale cost compared to 11.3 cents per kWH for full retail cost.</p>
              <p>Although this is significantly less than full retail credit, net metering in Kansas can provide substantial long-term savings for Kansas homeowners over the lifetime of their solar system.</p>
              <h3>Kansas Property Tax Exemptions for Solar Homeowners</h3>
              <p>Installing solar panels on your home will increase your home’s value by several thousand dollars. This is due to the substantial energy savings you will receive over the life of your solar power system.</p>
              <h3>Kansas Solar Rebates</h3>
              <p>There are no solar rebates currently available for homeowners in Kansas.</p>
              <h3>DSIRE Incentives Database - Kansas</h3>
              <p>The <a target='_blank' href='https://www.dsireusa.org/'>Database of State Incentives for Renewables and Efficiency</a> (DSIRE) is a comprehensive database that Kansas residents can use to search for and find solar energy incentives in Kansas and across the entire country.</p>
           </div>
        </section>
        <div className='px-4'>
           <div className='w-full max-w-sm sm:m-auto'>
              <p className='pb-2 text-left sm:text-center text-sm'>Solar Panels — Batteries — EV Chargers</p>
              <ul className='w-full mb-2 items-center sm:justify-center flex gap-2'>
                 <li className='flex-1'>
                    <div className='w-full bg-primary rounded-full w-full inline-flex items-center justify-between py-2 px-4 font-bold' href='/quote'>
                       <span>Get a Quote</span>
                       <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='2' stroke='currentColor' aria-hidden='true' className='h-6 w-6'>
                          <path strokeLinecap='round' strokeLinejoin='round' d='M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3'></path>
                       </svg>
                    </div>
                 </li>
                 <li className='flex-1'>
                    <div className='w-full w-full bg-gray rounded-full w-full inline-flex items-center justify-between py-2 px-4 font-bold'>
                       <span>Call Us</span>
                       <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' aria-hidden='true' className='h-4.5 w-4.5'>
                          <path fillRule='evenodd' d='M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z' clipRule='evenodd'></path>
                       </svg>
                    </div>
                 </li>
              </ul>
              <p className='text-gray-6 text-left sm:text-center text-sm'>(877) 937-4786 — (877) YES-4SUN</p>
           </div>
        </div>
        <section className='py-20 px-4'>
           <h2 className='font-mono uppercase text-left sm:text-center'>Our Locations</h2>
           <ul className='py-12 flex flex-wrap gap-2 justify-center max-w-4xl m-auto aos-init'>
              <li><div className='flex gap-1.5 items-center block text-sm bg-gray rounded-full pl-1 pr-4 py-1 h-10 w-44'></div></li>
              <li><div className='flex gap-1.5 items-center block text-sm bg-gray rounded-full pl-1 pr-4 py-1 h-10 w-44'></div></li>
              <li><div className='flex gap-1.5 items-center block text-sm bg-gray rounded-full pl-1 pr-4 py-1 h-10 w-44'></div></li>
              <li><div className='flex gap-1.5 items-center block text-sm bg-gray rounded-full pl-1 pr-4 py-1 h-10 w-44'></div></li>
              <li><div className='flex gap-1.5 items-center block text-sm bg-gray rounded-full pl-1 pr-4 py-1 h-10 w-44'></div></li>
              <li><div className='flex gap-1.5 items-center block text-sm bg-gray rounded-full pl-1 pr-4 py-1 h-10 w-44'></div></li>
              <li><div className='flex gap-1.5 items-center block text-sm bg-gray rounded-full pl-1 pr-4 py-1 h-10 w-44'></div></li>
           </ul>
        </section>
     </main>
     <footer className='max-w-7xl mx-auto border-t border-gray aos-init'>
        <div className='text-sm py-10 px-4 sm:px-6 landscape:flex portrait:space-y-8 landscape:space-x-4'>
           <div className='w-full sm:w-1/2 '>
              <svg className='w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 189.91 145.65'>
                 <circle className='fill-black group-hover:fill-primary transition duration-200' cx='36.41' cy='72.82' r='36.41'></circle>
                 <polygon className='fill-primary group-hover:fill-black transition duration-200' points='134.43 0 78.95 88.08 134.43 88.08 134.43 145.65 189.91 57.57 134.43 57.57 134.43 0'></polygon>
              </svg>
              <address className='not-italic py-6'>3525 Roanoke Rd, Ste 110<br />Kansas City, MO 64111<br /><a className='text-primary' href='tel:8779374786'>(877) 937-4786</a> | <a className='text-primary' href='mailto:info@astrawatt.solar'>info@astrawatt.solar</a></address>
              <p>© Astrawatt Solar 2021. All rights reserved.</p>
              <p className='flex gap-3 pt-6'><a className='border-b border-black' href='/privacy'>Privacy</a><a className='border-b border-black' href='/terms'>Terms</a></p>
           </div>
           <div className='flex-1'>
              <ul className='flex flex-row flex-wrap sm:flex-col gap-2'>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-primary rounded-full' href='/quote'>Get a Quote</a></li>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/education'>Learn About Solar</a></li>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/schedule'>Schedule an Appointment</a></li>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/contact'>Contact Us</a></li>
              </ul>
           </div>
           <div className='flex-1'>
              <ul className='flex flex-row flex-wrap sm:flex-col gap-2'>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/locations'>Our Locations</a></li>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/locations'>About Us</a></li>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/franchise'>Start a Franchise</a></li>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/franchise/resources'>Franchise Resources</a></li>
                 <li><a className='whitespace-nowrap inline-block py-1.5 px-4 bg-gray-1 rounded-full' href='/careers'>Careers</a></li>
              </ul>
           </div>
        </div>
     </footer>
    </div>
    </div>
  )
}

const metaTags = [
  {
    type: 'General',
    tags: [
      {
        name: 'url',
        value: (location) => 'https://astrawatt.solar/locations/' + location.states[0].value + '/solar-panel-installers/' + location.slug + (location.slug === '/landing' ? '' : '')
      },
      {
        name: 'title',
        default: (location) => location.name + ' - Solar Panel Installers - ' + location.states[0].value + ' - Locations - Astrawatt Solar'
      },
      {
        name: 'keywords'
      },
      {
        name: 'description'
      }
    ]
  },
  {
    type: 'Open Graph',
    tags: [
      {
        name: 'og:image',
        default: (location) => location.imageFeature ? location.imageFeature : ''
      },
      {
        name: 'og:image:alt',
        default: (location) => location.name + ' - Solar Panel Installers - ' + location.states[0].value
      },
      {
        name: 'og:type',
        default: (location) => 'website'
      }
    ]
  },
  {
    type: 'Twitter',
    tags: [
      {
        name: 'twitter:card',
        default: (location) => 'summary_large_image'
      },
      {
        name: 'twitter:image',
        default: (location) => location.imageFeature ? location.imageFeature : ''
      }
    ]
  }
]

import { useState, useRef, useEffect } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/solid'

export default function Index({ zips, zip, setZip, serviceArea }) {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(zips)

  useEffect(() => {
    if(zips) {
      setData(zips)
    }
  }, [zips])

  useEffect(() => {
    if(zips) {
      if(search && search !== '') {
        setData(zips.filter(z => z.toLowerCase().includes(search.toLowerCase())))
      } else {
        setData(zips)
      }
    }
  }, [search])

  return (
    <div className='h-full w-full overflow-scroll relative'>
      {/*<p className='text-black-400 p-2'>Zip Codes &mdash; {zips && zips.length.toLocaleString()}</p>*/}
      <div className='z-10 p-2 pb-0 backdrop-filter backdrop-blur-md sticky top-0'>
        <input
          className='font-mono p-2 placeholder-black-500 rounded-lg bg-black-825 w-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search zip codes'
        />
      </div>
      <ul className={'pb-6 pt-6 px-1 z-0 transition duration-200 ' + (zips && zips.length < 2 ? ' opacity-20 pointer-events-none' : ' ')}>
        {data && data.map((metric, index) =>
          <li className='fade-in' key={metric + '-' + index}>
            <button
              onClick={() => {
                let hasMetric = zip && zip.find(s => s === metric)
                if(zip) {
                  if(hasMetric) {
                    setZip(prev => prev.filter(s => s !== metric))
                  } else {
                    setZip(prev => ([
                      ...prev,
                      metric
                    ]))
                  }
                } else {
                  setZip([metric])
                }
              }}
              className='truncate overflow-hidden flex gap-3 items-center w-full px-2 py-2 text-left hover:bg-black-825 rounded-full'>
              <span className={'h-1.5 w-1.5 rounded-full ' + (zip && zip.find(s => s === metric) ? 'bg-primary' : 'bg-black-725') } />
              <span className='-mt-px block flex-1 truncate overflow-hidden'>{metric}</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}

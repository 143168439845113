import { useState, useRef, useEffect } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/solid'

import { months } from 'lib/utils'

export default function Index({ month, setMonth, year }) {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(months)

  useEffect(() => {
    if(search && search !== '') {
      console.log(search)
      setData(months.filter(d => d.label.toLowerCase().includes(search.toLowerCase())).sort((a,b) => a.value - b.value))
    } else {
      setData(months.sort((a,b) => a.value - b.value))
    }
  }, [search])

  return (
    <div className='h-full w-full overflow-scroll relative'>
      {/*<p className='text-black-400 p-2'>Months &mdash; {months && months.length.toLocaleString()}</p>*/}
      <div className='z-10 p-2 pb-0 backdrop-filter backdrop-blur-md sticky top-0'>
        <input
          className='font-mono p-2 placeholder-black-500 rounded-lg bg-black-825 w-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search months'
        />
      </div>
      <ul className={'z-0 pb-6 pt-6 px-1 transition duration-200 ' + (year && year.length > 0 ? '' : 'opacity-20 pointer-events-none')}>
        {data.map((metric, index) =>
          <li className='fade-in' key={metric.label + '-' + index}>
            <button
              onClick={() => {
                // let hasMetric = month && month.find(s => s.label === metric.label)
                // if(hasMetric) {
                //   setMonth(false)
                // } else {
                //   setMonth([{
                //     label: metric.label,
                //     value: metric.value
                //   }])
                // }
                let hasMetric = month && month.find(s => s.label === metric.label)
                if(month) {
                  if(hasMetric) {
                    setMonth(prev => prev.filter(s => s.label !== metric.label))
                  } else {
                    setMonth(prev => ([
                      ...prev,
                      {
                        label: metric.label,
                        value: metric.value
                      }
                    ]))
                  }
                } else {
                  setMonth([{
                    label: metric.label,
                    value: metric.value
                  }])
                }
              }}
              className='truncate overflow-hidden flex gap-3 items-center w-full px-2 py-2 text-left hover:bg-black-825 rounded-full'>
              <span className={'h-1.5 w-1.5 rounded-full ' + (month && month.find(s => s.label === metric.label) ? 'bg-primary' : 'bg-black-725') } />
              <span className='-mt-px block flex-1 truncate overflow-hidden'>{metric.long}</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}

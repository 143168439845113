import { useEffect, useState, useRef, useContext, useCallback } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, orderBy, onSnapshot } from 'firebase/firestore'

import { getStorage, ref, getBlob } from 'firebase/storage'

import { yearsReports, months } from 'lib/utils'

import { Context } from 'store/index'

import FilterYear from './components/filter-year'
import FilterMonth from './components/filter-month'
import FilterServiceArea from './components/filter-service-area'
import FilterLeadSource from './components/filter-lead-source'
import FilterQuarter from './components/filter-quarter'

import ReportList from './components/report-list'

import { FolderArrowDownIcon } from '@heroicons/react/24/solid'

import { read, utils, writeFileXLSX } from 'xlsx'

import { selectReportData, formatReportDataExport, columns } from './components/select'

const reportTypes = [
  {
    label: 'Overview',
    value: 'overview'
  },
  {
    label: 'Deals',
    value: 'deals'
  },
  {
    label: 'No Deals',
    value: 'no-deals'
  },
  {
    label: 'Appointements',
    value: 'appointments'
  },
  {
    label: 'No Appointements',
    value: 'no-appointments'
  }
]

const contactKeys = [
  'type',
  'id',
  'year',
  'month',
  'day',
  'lead_status',
  'lead_source',
  'lifecycle',
  'call_number',
  'service_area',
  'is_lead',
  'lead_cost',
  'meeting_set',
  'zip'
]

const dealKeys = [
  'type',
  'id',
  'year',
  'month',
  'day',
  'lead_source',
  'service_area',
  'close_year',
  'close_month',
  'close_day',
  'status',
  'revenue',
  'system_size',
  'zip'
]

const keys = [...new Set([...contactKeys ,...dealKeys])]

export default function Index() {
  const [state] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp)

  const scrollRef = useRef(null)
  const mounted = useRef(null)

  const [sort, setSort] = useState(false)

  const [year, setYear] = useState(false)
  const [month, setMonth] = useState(false)
  const [quarter, setQuarter] = useState(false)
  const [updateQuarters, setUpdateQuarters] = useState(false)
  const [serviceArea, setServiceArea] = useState(false)
  const [leadSource, setLeadSource] = useState(false)
  const [reportType, setReportType] = useState(reportTypes[1])

  const [htmlDb, setHtmlDb] = useState(false)
  const [data, setData] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [filteredData, setFilteredData] = useState(false)

  async function exportFile() {
    const headers = [columns.map(c => { return c.label })]
    const widths = columns.map(c => { return { wch: 15 } })

    const wb = utils.book_new()

    let data, formattedData, ws = false

    for(let rt in reportTypes) {
      data = await selectReportData({ year, month, serviceArea, leadSource, reportType: rt, htmlDb })
      formattedData = formatReportDataExport({ data: data })
      ws = utils.json_to_sheet(formattedData)
      utils.sheet_add_aoa(ws, headers, { origin: 'A1' })
      ws['!cols'] = widths
      utils.book_append_sheet(wb, ws, rt.label)
    }

    // data = await selectReportData({ year, month, serviceArea, leadSource, reportType: reportTypes.filter(t => t.label === 'Overview')[0], htmlDb })
    // formattedData = formatReportDataExport({ data: data })
    // ws = utils.json_to_sheet(formattedData)
    // utils.sheet_add_aoa(ws, headers, { origin: 'A1' })
    // ws['!cols'] = widths
    // utils.book_append_sheet(wb, ws, 'Overview')
    //
    // data = await selectReportData({ year, month, serviceArea, leadSource, reportType: reportTypes.filter(t => t.label === 'Deals')[0], htmlDb })
    // formattedData = formatReportDataExport({ data: data })
    // ws = utils.json_to_sheet(formattedData)
    // utils.sheet_add_aoa(ws, headers, { origin: 'A1' })
    // ws['!cols'] = widths
    // utils.book_append_sheet(wb, ws, 'Deals')
    //
    // data = await selectReportData({ year, month, serviceArea, leadSource, reportType: reportTypes.filter(t => t.label === 'No Deals')[0], htmlDb })
    // formattedData = formatReportDataExport({ data: data })
    // ws = utils.json_to_sheet(formattedData)
    // utils.sheet_add_aoa(ws, headers, { origin: 'A1' })
    // ws['!cols'] = widths
    // utils.book_append_sheet(wb, ws, 'No Deals')
    //
    // data = await selectReportData({ year, month, serviceArea, leadSource, reportType: reportTypes.filter(t => t.label === 'Appointements')[0], htmlDb })
    // formattedData = formatReportDataExport({ data: data })
    // ws = utils.json_to_sheet(formattedData)
    // utils.sheet_add_aoa(ws, headers, { origin: 'A1' })
    // ws['!cols'] = widths
    // utils.book_append_sheet(wb, ws, 'Appointments')
    //
    // data = await selectReportData({ year, month, serviceArea, leadSource, reportType: reportTypes.filter(t => t.label === 'No Appointements')[0], htmlDb })
    // formattedData = formatReportDataExport({ data: data })
    // ws = utils.json_to_sheet(formattedData)
    // utils.sheet_add_aoa(ws, headers, { origin: 'A1' })
    // ws['!cols'] = widths
    // utils.book_append_sheet(wb, ws, 'No Appointments')

    writeFileXLSX(wb, 'AstrawattSolarReports—' + new Date().toLocaleString() + '.xlsx')
  }

  useEffect(() => {
    console.log('set db')
    setHtmlDb(openDatabase('mydb', '1.0', 'LaunchPad', 5 * 1024 * 1024))

    mounted.current = true
    return () => mounted.current = false
  }, [])

  useEffect(() => {
    if(htmlDb) {
      getData()
    }
  }, [htmlDb])

  useEffect(() => {
    if(loaded) {
      scrollRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }, [loaded, year, month, serviceArea, leadSource, reportType])

  async function doSelect() {
    console.log('do select')
    setFilteredData(await selectReportData({ year, month, serviceArea, leadSource, reportType, htmlDb }))
  }

  useEffect(() => {
    if(loaded) doSelect()
  }, [loaded, year, month, serviceArea, leadSource, reportType])

  useEffect(() => {
    if(loaded) {
      if(filteredData.data && sort) {
        setFilteredData(prev => {
          let newData = []
          if(sort.direction === 'asc') {
            newData = prev.data.sort((a,b) => a[sort.key] - b[sort.key])
          } else {
            newData = prev.data.sort((a,b) =>  b[sort.key] - a[sort.key])
          }
          return {
            ...filteredData,
            data: newData
          }
        })
      } else {
        doSelect()
      }
    }
  }, [loaded, sort])

  useEffect(() => {
    if(updateQuarters) {
      const newMonths = months.filter(m => {
        let found = false
        for(const q of quarter) {
          const find = q.months.find(qm => {
            return qm === m.value
          })
          if(find !== undefined) found = true
        }
        return found
      })
      setMonth(newMonths)
    }
  }, [updateQuarters])

  async function getData() {
    console.log('get data')
    if(!data) {
      const update = {}

      const sources = [
        'contacts',
        'deals'
      ]

      for(let source of sources) {
        const b = await getBlob(ref(storage, 'reports/' + source + '.json'))
        const fr = new FileReader()

        fr.onload = (e) => {
          const data = JSON.parse(e.target.result)
          if(data) {
            console.log('has data')

            update[source] = []

            if(source === 'contacts') {
              console.log('convert contacts')
              data.data.forEach(m => {
                update[source].push({
                  'type': 'contact',
                  'id': m.id,
                  'year': m.ye,
                  'month': m.mo,
                  'day': m.da,
                  'lead_status': m.st,
                  'lead_source': m.so,
                  'lifecycle': m.cy,
                  'call_number': m.cn,
                  'service_area': m.sa,
                  'is_lead': m.il,
                  'zip': !m.zi || m.zi === undefined || m.zi === '' ? 'undefined' : m.zi,
                  'lead_cost': m.lc,
                  'meeting_set': m.ms
                })
              })
            } else if(source === 'deals') {
              console.log('convert deals')
              data.data.forEach(m => {
                update[source].push({
                  'type': 'deal',
                  'id': m.id,
                  'year': m.ye,
                  'month': m.mo,
                  'day': m.da,
                  'lead_source': m.so,
                  'service_area': m.sa,
                  'close_year': m.cy,
                  'close_month': m.cm,
                  'close_day': m.cd,
                  'status': m.ds,
                  'revenue': m.re,
                  'system_size': m.ss,
                  'zip': !m.zi || m.zi === undefined || m.zi === '' ? 'undefined' : m.zi,
                })
              })
            }
          }
        }

        fr.readAsText(b)
      }

      setData(update)
    }
  }

  useEffect(() => {
    if(data) importData()
  }, [data])

  function importData() {
    console.log('load data')

    htmlDb.transaction(tx => {
      tx.executeSql('DROP TABLE IF EXISTS DATA')
    })

    htmlDb.transaction(tx => {
      tx.executeSql('CREATE TABLE IF NOT EXISTS DATA (' + keys.join(', ') + ')')
      console.log('load contacts')
      data.contacts.forEach((d, i) => {
        const values = []
        keys.forEach(key => {
          values.push(d[key])
        })
        tx.executeSql('INSERT INTO DATA (' + keys.join(', ') + ') VALUES (' + [...Array(keys.length).fill('?')].join(',') + ')', values)
      })
      console.log('load deals')
      data.deals.forEach((d, i) => {
        const values = []
        keys.forEach(key => {
          values.push(d[key])
        })
        tx.executeSql('INSERT INTO DATA (' + keys.join(', ') + ') VALUES (' + [...Array(keys.length).fill('?')].join(',') + ')', values)
      })
    })

    console.log('data loaded')
    setLoaded(true)
  }

  return (
    <div data-aos='fade-in' className='h-screen px-3 py-16 w-full h-screen'>
      <div className='bg-black-875 rounded-2xl overflow-hidden h-full w-full flex'>
        <div className='flex-1 flex flex-col w-full overflow-hidden'>
          <div className='h-44 text-xs divide-x flex overflow-hidden border-b'>
            <div className='flex-1 overflow-hidden'>
              <FilterYear year={year} setYear={setYear} />
            </div>
            <div className='flex-1 overflow-hidden'>
              <FilterQuarter quarter={quarter} setQuarter={setQuarter} setUpdateQuarters={setUpdateQuarters} />
            </div>
            <div className='flex-1 overflow-hidden'>
              <FilterMonth quarter={quarter} setQuarter={setQuarter} year={year} month={month} setMonth={setMonth} />
            </div>
            <div className='flex-1 overflow-hidden'>
              <FilterServiceArea serviceArea={serviceArea} setServiceArea={setServiceArea} />
            </div>
            <div className='flex-1 overflow-hidden'>
              <FilterLeadSource leadSource={leadSource} setLeadSource={setLeadSource} />
            </div>
          </div>
          <div className='flex-1 overflow-hidden'>
            <div className='h-full w-full flex flex-col relative'>
              <div className='flex justify-between items-center bg-black-850'>
                <ul className='p-2 gap-1 flex n'>
                  {reportTypes.map((type, i) =>
                    <li key={type.label + '-' + i}>
                      <button
                        onClick={() => {
                          setReportType(type)
                        }}
                        className={'text-xs px-4 py-1.5 rounded-full leading-none ' + (reportType.label === type.label ? 'bg-primary text-black': 'bg-black-775 hover:bg-black-750')}>
                        {type.label}
                      </button>
                    </li>
                  )}
                </ul>
                <div className='p-1'>
                  <button
                    onClick={() => {
                      exportFile()
                    }}
                    className='h-9 w-9 rounded-full flex hover:bg-black-775'>
                    <FolderArrowDownIcon className='text-blue-500 h-4 w-4 m-auto' />
                  </button>
                </div>
              </div>
              <div ref={scrollRef} className='w-full flex-1 overflow-scroll whitespace-nowrap flex flex-col relative'>
                <ReportList sort={sort} setSort={setSort} htmlDb={htmlDb} filteredData={filteredData} setFilteredData={setFilteredData} loaded={loaded} year={year} month={month} serviceArea={serviceArea} leadSource={leadSource} reportType={reportType} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

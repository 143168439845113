import { useContext, useState, useEffect } from 'react'

import { Context } from 'store/index'

import { XMarkIcon } from '@heroicons/react/24/solid'

export default function Index({ leadSource, setLeadSource }) {
  const [state] = useContext(Context)

  const [search, setSearch] = useState('')
  const [data, setData] = useState(false)

  useEffect(() => {
    if(state.contactProperties.lead_source) {
      setData(state.contactProperties.lead_source.sort((a,b) => a.label.localeCompare(b.label)))
    }
  }, [state.contactProperties.lead_source])

  useEffect(() => {
    if(state.contactProperties.lead_source) {
      if(search && search !== '') {
        setData(state.contactProperties.lead_source.filter(d => d.label.toLowerCase().includes(search.toLowerCase())).sort((a,b) => a.label.localeCompare(b.label)))
      } else {
        setData(state.contactProperties.lead_source.sort((a,b) => a.label.localeCompare(b.label)))
      }
    }
  }, [search])

  return (
    <div className='h-full w-full overflow-scroll relative'>
      {/*<p className='text-black-400 p-2'>Lead Sources &mdash; {state.contactProperties.lead_source && state.contactProperties.lead_source.length.toLocaleString()}</p>*/}
      <div className='z-10 p-2 pb-0 backdrop-filter backdrop-blur-md sticky top-0'>
        <input
          className='font-mono p-2 placeholder-black-500 rounded-lg bg-black-825 w-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search sources'
        />
      </div>
      <ul className='z-0 pb-6 pt-3 px-1'>
        {data && data.map((metric, index) =>
          <li className='fade-in' key={metric.label + '-' + index}>
            <button
              onClick={() => {
                let hasMetric = leadSource && leadSource.find(s => s.label === metric.label)
                if(leadSource) {
                  if(hasMetric) {
                    setLeadSource(prev => prev.filter(s => s.label !== metric.label))
                  } else {
                    setLeadSource(prev => ([
                      ...prev,
                      {
                        label: metric.label,
                        value: metric.value
                      }
                    ]))
                  }
                } else {
                  setLeadSource([{
                    label: metric.label,
                    value: metric.value
                  }])
                }
              }}
              className='truncate overflow-hidden flex gap-3 items-center w-full px-2 py-2 text-left hover:bg-black-825 rounded-full'>
              <span className={'h-1.5 w-1.5 rounded-full ' + (leadSource && leadSource.find(s => s.label === metric.label) ? 'bg-primary' : 'bg-black-725') } />
              <span className='-mt-px block flex-1 truncate overflow-hidden'>{metric.label}</span>
            </button>
          </li>
        )}
      </ul>
      {leadSource && leadSource.length > 0 &&
        <div className='-mx-px fade-in bg-black-850 sticky bottom-0 p-2 flex flex-wrap items-end gap-1 overflow-hidden'>
          {leadSource.map(o => <button key={o.label} onClick={() => {
            setLeadSource(prev => prev.filter(p => p.label !== o.label))
          }} className='max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>)}
        </div>
      }
    </div>
  )
}

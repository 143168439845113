import { useState, useRef, useEffect } from 'react'

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

export default function Index({ metrics, metric, setMetric }) {
  const [show, setShow] = useState([])

  const [search, setSearch] = useState('')
  const [data, setData] = useState(false)
  const [unique, setUnique] = useState(false)

  useEffect(() => {
    if(search && search !== '') {
      const newData = metrics.filter(d => d.label.toLowerCase().includes(search.toLowerCase()))
      const newUnique = [...new Set(newData.map(m => m.menuType))]
      setData(newData)
      setUnique(newUnique)
    } else {
      setData(metrics)
      setUnique([...new Set(metrics.map(m => m.menuType))])
    }
  }, [search])

  return (
    <div className='h-full w-full overflow-scroll relative'>
      <div className='z-10 p-2 pb-0 bg-black-875 bg-opacity-80 backdrop-filter backdrop-blur-md sticky top-0'>
        <input
          className='font-mono p-2 placeholder-black-500 rounded-lg bg-black-825 w-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search metrics'
        />
      </div>
      {unique && unique.map(u =>
        <div className='fade-in py-6' key={u}>
          <p className='w-full text-sm p-2 text-black-400 pb-6'>
            {u}
          </p>
          <ul className='space-y-1 px-2'>
            {data && data.filter(m => m.menuType === u).map((option, index) =>
              <li className='fade-in' key={option.label + '-' + index}>
                <button
                  onClick={() => setMetric(option)}
                  className={'w-full p-2 text-left rounded-lg ' + (metric && metric.label === option.label ? ' bg-emerald-500 text-black' : ' bg-black-825 hover:bg-black-800')}>
                  <span className='block text-xs leading-none pb-2 font-bold'>{option.label}</span>
                  <span className={'pr-3 block leading-tight text-xxs ' + (metric && metric.label === option.label ? ' text-black' : ' text-black-400')}>{option.longDescription}</span>
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  )
}

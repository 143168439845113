import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { renderToString } from 'react-dom/server'
import { getApp } from 'firebase/app'
import { getStorage, ref, getBlob } from 'firebase/storage'

import { MarkerClusterer } from '@googlemaps/markerclusterer'
import Color from 'color'

import { ChevronDownIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/solid'

import { Context } from 'store/index'

import Filter from './filter'

import * as L from 'leaflet'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

export default function Index() {
  const [state] = useContext(Context)

  const navigate = useNavigate()

  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp)

  const mapRef = useRef(null)
  const markerCluster = useRef(null)
  const currentTiles = useRef(null)

  const [map, setMap] = useState()
  const [type, setType] = useState('roadmap-google')
  const [markers, setMarkers] = useState(false)
  const [clusters, setClusters] = useState(false)

  const [htmlDb, setHtmlDb] = useState(false)
  const [location, setLocation] = useState({
    label: 'All Areas',
    value: 'all'
  })
  const [data, setData] = useState(false)
  const [filteredData, setFilteredData] = useState(false)
  const [loadData, setLoadData] = useState(false)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setHtmlDb(openDatabase('mydb', '1.0', 'Ops', 5 * 1024 * 1024))
  }, [])

  useEffect(() => {
    if(location && loaded) setLoadData(new Date())
  }, [loaded, location])

  useEffect(() => {
    if(mapRef.current && !map) {
      console.log('init map')
      const initMap = L.map('map', {
        center: [51.505, -0.09],
        zoom: 8,
        scrollWheelZoom: false,
        zoomControl: false
      })
      setMap(initMap)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, map])

  useEffect(() => {
    if(map && type) {
      let newTiles = false
      if(type === 'roadmap-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1
        }).addTo(map)
      } else if(type === 'roadmap-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrsf=m&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
        }).addTo(map)
      } else if(type === 'satellite-mapbox') {
        newTiles = L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=' + process.env.REACT_APP_MAPBOX_API_KEY, {
         attribution: '&copy; <a href="https://www.mapbox.com/feedback/">Mapbox</a>',
         tileSize: 512,
         zoomOffset: -1
        }).addTo(map)
      } else if(type === 'satellite-usgs') {
        newTiles = L.tileLayer('https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}', {
        	maxZoom: 20,
        	attribution: '&copy; <a href="https://usgs.gov/">U.S. Geological Survey</a>'
        }).addTo(map)
      } else if(type === 'satellite-google') {
        newTiles = L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3'],
          attribution: '&copy; <a href="http://bing.com/maps">Google Maps</a>'
        }).addTo(map)
      } else if(type === 'satellite-bing') {
        async function getBing() {
          const url = 'https://dev.virtualearth.net/REST/V1/Imagery/Metadata/Aerial?output=json&include=ImageryProviders&key='+process.env.REACT_APP_BING_API
          const tiles = await fetch(url)
          const tilesJSON = await tiles.json()

          var BingLayer = L.TileLayer.extend({
            getTileUrl: function (tilePoint) {
                // this._adjustTilePoint(tilePoint);
                return L.Util.template(this._url, {
                    s: this._getSubdomain(tilePoint),
                    q: this._quadKey(tilePoint.x, tilePoint.y, this._getZoomForUrl())
                });
            },
            _quadKey: function (x, y, z) {
                var quadKey = [];
                for (var i = z; i > 0; i--) {
                    var digit = '0';
                    var mask = 1 << (i - 1);
                    if ((x & mask) != 0) {
                        digit++;
                    }
                    if ((y & mask) != 0) {
                        digit++;
                        digit++;
                    }
                    quadKey.push(digit);
                }
                return quadKey.join('');
            }
          })

          const tilesUrl = tilesJSON.resourceSets[0].resources[0].imageUrl.replace('{subdomain}', '{s}').replace('{quadkey}', '{q}')

          newTiles = new BingLayer(tilesUrl, {
            maxZoom: 20,
            subdomains: tilesJSON.resourceSets[0].resources[0].imageUrlSubdomains,
            attribution: '&copy; <a href="http://bing.com/maps">Bing Maps</a>'
          }).addTo(map)
        }
        getBing()
      } else if(type === 'satellite-arcgis') {
        newTiles = L.tileLayer('http://{s}.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 20,
          subdomains: ['server', 'services'],
          attribution: '&copy; <a href="http://www.arcgis.com/">ArcGIS esri</a>'
        }).addTo(map)
      }

      if(currentTiles.current) currentTiles.current.remove()
      currentTiles.current = newTiles
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, type])

  useEffect(() => {
    async function getMarkers() {
      console.log('set markers')
      console.log(filteredData)

      setMarkers(false)

      setMarkers(filteredData.filter(doc => {
        if(!doc.lat || doc.lat === 'undefined' || !doc.lng || doc.lng === 'undefined') return false
        return true
      }).map(doc => {
        const position = [parseFloat(doc.lat), parseFloat(doc.lng)]
        const svgIcon = L.divIcon({
          html: `
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
</svg>`,
          className: 'text-yellow-500',
          iconSize: [32, 32],
          iconAnchor: [16, 32],
        })

        const marker = L.marker(position, {
          icon: svgIcon
        })

        const newDiv = document.createElement('div')
        const text = document.createElement('p')
        text.innerHTML = '<p><b>' + doc.first_name + ' ' + doc.last_name + '</b></br>' + doc.address + '</p>'

        const button = document.createElement('button')
        button.appendChild(document.createTextNode('View'))
        button.onclick = () => {
          alert('hi')
        }
        button.className = 'font-bold bg-primary px-3 py-1 rounded-full'

        newDiv.appendChild(text)
        newDiv.appendChild(button)

        marker.bindPopup(newDiv).openPopup()

        return marker
      }))
    }

    if(filteredData && map) getMarkers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData, map])

  useEffect(() => {
    if(clusters) map.removeLayer(clusters)
    if(markers && markers.length > 0) {
      console.log(markers)

      const newClusters = L.markerClusterGroup()
      markers.forEach(m => {
        newClusters.addLayer(m)
      })
      map.addLayer(newClusters)

      setClusters(newClusters)

      const features = new L.featureGroup(markers)
      map.fitBounds(features.getBounds())
    }
    // const renderer = {
    //   render: ({ count, position }, stats) => {
    //     // use d3-interpolateRgb to interpolate between red and blue
    //     // const color = this.palette(count / stats.clusters.markers.max)
    //     // console.log(count / stats.clusters.markers.max)
    //     const red = [255,0,75]
    //     const blue = [75,0,255]
    //     const color = Color.rgb(blue).mix(Color.rgb(red), count / stats.clusters.markers.max).rgb().string()
    //     const svg = window.btoa(`
    //     <svg fill='${color}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240 240'>
    //       <circle cx='120' cy='120' opacity='.9' r='70' />
    //     </svg>`)
    //     return new window.google.maps.Marker({
    //       position,
    //       icon: {
    //         url: `data:image/svg+xml;base64,${svg}`,
    //         scaledSize: new window.google.maps.Size(60, 60),
    //       },
    //       label: {
    //         text: String(count),
    //         color: 'rgba(255,255,255,0.9)',
    //         fontSize: '11px',
    //       },
    //       zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
    //     })
    //   }
    // }
    //
    // if(map && markers) {
    //   console.log('cluster')
    //   markerCluster.current = new MarkerClusterer({
    //     map,
    //     markers,
    //     renderer
    //   })
    //   const bounds = new window.google.maps.LatLngBounds()
    //   markers.forEach(marker => {
    //     bounds.extend(marker.getPosition())
    //   })
    //   setTimeout(() => {
    //     map.fitBounds(bounds)
    //   }, 600)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markers])

  useEffect(() => {
    if(htmlDb) getData()
  }, [htmlDb])

  async function getData() {
    // console.log('get data')
    if(!data) {
      const update = []

      const b = await getBlob(ref(storage, 'reports/customers.json'))
      const fr = new FileReader()

      fr.onload = (e) => {
        const data = JSON.parse(e.target.result)
        if(data) {
          // console.log(data)
          // console.log('has data')
          // console.log('convert customers')
          data.data.forEach(m => {
            update.push({
              'id': m.id,
              'year': m.ye,
              'month': m.mo,
              'day': m.da,
              'service_area': m.sa,
              'lat': m.lt,
              'lng': m.lo,
              'email': m.em,
              'address': m.ad,
              'phone': m.ph,
              'first_name': m.fn,
              'last_name': m.ln
            })
          })
        }
        setData(update)
        setLoadData(new Date())
      }
      fr.readAsText(b)
    } else {
      setLoadData(new Date())
    }
  }

  useEffect(() => {
    if(loadData) {
      // console.log('load data')

      const keys = [
        'id',
        'year',
        'month',
        'day',
        'service_area',
        'lat',
        'lng',
        'email',
        'address',
        'phone',
        'first_name',
        'last_name'
      ]

      htmlDb.transaction(tx => {
        tx.executeSql('DROP TABLE IF EXISTS DATA')
      })

      htmlDb.transaction(tx => {
        tx.executeSql('CREATE TABLE IF NOT EXISTS DATA (' + keys.join(', ') + ')')
        data.forEach((d, i) => {
          const values = []
          keys.forEach(key => {
            values.push(d[key])
          })
          tx.executeSql('INSERT INTO DATA (' + keys.join(', ') + ') VALUES (' + [...Array(keys.length).fill('?')].join(',') + ')', values)
        })
      })

      console.log('data loaded')
      setLoaded(true)

      console.log('select data')

      let query = 'SELECT * FROM DATA'
      if(location.value !== 'all') {
        query += ' WHERE service_area = "' + location.value + '"'
      }

      // console.log(query)

      htmlDb.transaction(tx => {
        tx.executeSql(query, [], (tx, results) => {
          var len = results.rows.length, i
          console.log('Found rows: ' + len)
          console.log(results.rows)

          const update = []
          for(i = 0; i < len; i++) {
            update.push(results.rows.item(i))
          }
          setFilteredData(update)
        }, null)
      })

      htmlDb.transaction(tx => {
        tx.executeSql('DROP TABLE IF EXISTS DATA')
      })

      // console.log('data selected')
    }

  }, [loadData])

  return (
    <div data-aos='fade-in' className='h-screen w-full px-3 py-16'>
      <div className='bg-black-850 rounded-2xl overflow-hidden flex h-full'>
        <div className='w-1/4 h-full'>
          <Filter markers={markers} type={type} setType={setType} map={map} location={location} setLocation={setLocation} />
        </div>
        <div className='h-full flex-1 relative'>
          <ul className='p-2 absolute top-0 left-0 flex gap-1 z-10'>
            <li>
              <button
                className='shadow-lg flex text-sm bg-white text-black rounded-full h-8 w-8 transition duration-200 hover:bg-black-25 active:bg-black-25 flex gap-3 items-center'
                onClick={() => map.setZoom(map.getZoom() + 1)}>
                <PlusIcon className='h-5 w-5 m-auto' />
              </button>
            </li>
            <li>
              <button
                className='shadow-lg flex text-sm bg-white text-black rounded-full h-8 w-8 transition duration-200 hover:bg-black-25 active:bg-black-25 flex gap-3 items-center'
                onClick={() => map.setZoom(map.getZoom() - 1)}>
                <MinusIcon className='h-5 w-5 m-auto' />
              </button>
            </li>
          </ul>
          <div className='h-full w-full relative z-0'>
            <div ref={mapRef} id='map' className='h-full h-full rounded-2xl overflow-hidden' />
          </div>
        </div>
      </div>
    </div>
  )
}

import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, setDoc, onSnapshot, orderBy, doc } from 'firebase/firestore'

import QRCode from 'qrcode'

import ReactTooltip from 'react-tooltip'
import { XMarkIcon, PencilIcon, EnvelopeIcon, UserIcon, PlusIcon } from '@heroicons/react/24/solid'

import EditModal from './components/edit-modal'
import AddModal from './components/add-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const linksUnsub = useRef(null)

  const [qrCodes, setQrCodes] = useState(false)

  const [currentLink, setCurrentLink] = useState(false)

  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(linksUnsub.current) linksUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getLinks() {
      const q = query(collection(db, 'go-short-links'), orderBy('createdAt', 'desc'))
      linksUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            const result = querySnapshot.docs.map(doc => {
              return {
                id: doc.id,
                ...doc.data()
              }
            })
            setQrCodes(result)
          } else setQrCodes(null)
        }
      })
    }
    getLinks()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function downloadQr(doc) {
    const src = await QRCode.toDataURL(doc.domain+doc.lid, {
      color: {
        dark: '#000000',
        light: '#ffffff'
      }
    })

    const link = document.createElement('a')
    link.download = 'qr-'+doc.lid+'.png'
    link.href = src
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div data-aos='fade-in' className='h-screen w-full px-3 py-16'>
      <div className='relative bg-black-875 rounded-2xl h-full'>
      <div><div className='p-3 absolute top-0 right-0 z-10'>
        <button
          className='bg-black-800 hover:bg-black-750 rounded-full px-4 text-primary py-1 text-sm font-bold'
          onClick={() => {
            setShowAddModal(true)
          }}>
          Add Link
        </button>
      </div>
      </div>
      {qrCodes &&
        <div data-aos='fade-in' className='divide-y py-16 overflow-scroll h-full'>
          {qrCodes.map(code =>
            <div className='py-2 flex items-center space-x-6 px-8' key={code.lid}>
              <div className='flex-1 flex space-x-4 items-center'>
                <button
                  data-tip='Edit link'
                  data-for={code.id + '-edit'}
                  data-delay-show={1000}
                  onClick={() => {
                    setCurrentLink(code)
                    setShowEditModal(true)
                  }}
                  className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                  <PencilIcon className='h-4 w-4 m-auto' />
                  <ReactTooltip
                    className='text-sm'
                    id={code.id + '-edit'}
                    place='right'
                    type='dark'
                    effect='solid'
                    multiline={true}
                  />
                </button>
                <button className='font-bold text-black bg-primary rounded-full px-4 py-1 text-xs' onClick={() => downloadQr(code)}>
                  Download QR
                </button>
                <p>
                  <a className='w-14 block' target='_blank' href={code.domain+code.lid}><img src={code.src} /></a>
                </p>
                <h2 className='font-bold mb-2'>{code.title}</h2>
              </div>
              <ul className='w-1/2 space-y-.5 text-sm overflow-hidden'>
                <li className='flex hidden'><span className='flex-1 font-mono uppercase text-xs'>id</span><span className='w-4/5 truncate leading-none'>{code.lid}</span></li>
                <li className='flex hidden'><span className='flex-1 font-mono uppercase text-xs'>from</span><span className='w-4/5 truncate leading-none'>{code.domain}</span></li>
                <li className='flex'><span className='text-black-500 block w-4/5 leading-none break-words overflow-hidden'>{code.destination}</span></li>
              </ul>
            </div>
           )}
         </div>
       }
      </div>
      <EditModal link={currentLink} setCurrentLink={setCurrentLink} showModal={showEditModal} setShowModal={setShowEditModal} />
      <AddModal setCurrentLink={setCurrentLink} showModal={showAddModal} setShowModal={setShowAddModal} setShowEditModal={setShowEditModal} />
    </div>
  )
}

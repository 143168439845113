import { useRef, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { renderToString } from 'react-dom/server'

import { getApp } from 'firebase/app'
import { getStorage, ref, uploadBytes, getDownloadURL, getBlob } from 'firebase/storage'

import { MarkerClusterer } from '@googlemaps/markerclusterer'

import { Context } from 'store/index'

import { getAddress, personalizationTokens, getSanitized } from 'lib/utils'

const locations = [
  { label: 'Kansas City', value: 'KC Metro', id: '1389', center: { lat: 39.0997, lng: -94.5786 } },
  { label: 'Wichita', value: 'Wichita', id: '1390', center: { lat: 39.0473, lng: -95.6752 } },
  { label: 'St. Louis', value: 'St. Louis', id: '1391', center: { lat: 38.6270, lng: -90.1994 } },
  { label: 'Houston, TX', value: 'Houston', id: '1392', center: { lat: 29.7604, lng: -95.3698 } },
  { label: 'Texas', value: 'Dallas Fort Worth', id: '1393', center: { lat: 29.7604, lng: -95.3698 } },
  { label: 'Oklahoma City, OK', value: 'Oklahoma City', id: '1394', center: { lat: 35.4676, lng: -97.5164 } },
  { label: 'Oklahoma', value: 'Oklahoma City (Out)', id: '1589', center: { lat: 35.4676, lng: -97.5164 } },
]

const pinSVG = 'M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z'

export default function Index({ contact }) {
  const navigate = useNavigate()

  const firebaseApp = getApp()
  const storage = getStorage(firebaseApp)

  const mapRef = useRef(null)
  const mounted = useRef(null)
  const markerCluster = useRef(null)

  const [state, dispatch] = useContext(Context)

  const [map, setMap] = useState()
  const [markers, setMarkers] = useState(false)

  const location = locations.find(location => contact.service_area === location.value)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if(mapRef.current && !map) {
      const initMap = new window.google.maps.Map(mapRef.current, {
        center: location.center,
        zoom: 14,
        styles: mapStyles,
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        streetViewControl: false
      })
      setMap(initMap)

      const markerImage = {
        path: pinSVG,
        anchor: new window.google.maps.Point(12,17),
        fillOpacity: 1,
        fillColor: '#00bc70',
        strokeWeight: 0,
        strokeColor: '',
        scale: 1.2,
        labelOrigin: new window.google.maps.Point(12,15)
      }

      const marker = new window.google.maps.Marker({
        position: location.center,
        icon: markerImage,
        map: initMap
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, map])

  useEffect(() => {
    async function getLocation() {
      const b = await getBlob(ref(storage, 'customers/'+location.value+'.json'))
      const fr = new FileReader()

      fr.onload = (e) => {
        const newContacts = JSON.parse(e.target.result)
        if(newContacts) {
          const infoWindow = new window.google.maps.InfoWindow({
            content: '',
            disableAutoPan: true,
          })

          const markerImage = {
            path: pinSVG,
            anchor: new window.google.maps.Point(12,17),
            fillOpacity: 1,
            fillColor: '#f7be00',
            strokeWeight: 0,
            strokeColor: '',
            scale: 1.2,
            labelOrigin: new window.google.maps.Point(12,15)
          }

          setMarkers(newContacts.filter(doc => {
            if(!doc.lat || !doc.lng) return false
            if(parseInt(doc.vid) === parseInt(contact.vid)) return false
            return true
          }).map(doc => {
            const position = { lat: parseFloat(doc.lat), lng: parseFloat(doc.lng) }

            const marker = new window.google.maps.Marker({
              position: position,
              icon: markerImage,
              // map: map
            })

            const InfoWindowContent = (
              <div className='text-black pb-2.5 pr-4'>
                <p className='font-bold cursor-pointer' onClick={() => navigate('/contacts')}>{doc.firstname} {doc.lastname}</p>
                <p>{doc.email}</p>
                <p>{doc.formatted_address}</p>
              </div>
            )

            marker.addListener('click', () => {
              const content = renderToString(InfoWindowContent)
              infoWindow.setContent(content)
              // infoWindow.setContent("<div class='text-black pb-2.5 pr-4'><p class='font-bold'><Link to='contacts/'>"+doc.firstname+" "+doc.lastname+"</Link></p><p>"+doc.email+"</p><p>"+doc.formatted_address+"</p></div>")
              infoWindow.open(map, marker)
            })

            return marker
          }))
        } else {
          setMarkers(null)
        }
      }

      fr.readAsText(b)
    }

    if(location && map) getLocation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, map])

  useEffect(() => {
    if(map && markers) {
      if(markerCluster.current) markerCluster.current.clearMarkers()
      markerCluster.current = new MarkerClusterer({
        map,
        markers
      })
      // const bounds = new window.google.maps.LatLngBounds()
      // markers.forEach(marker => {
      //   bounds.extend(marker.getPosition())
      // })
      // map.fitBounds(bounds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, markers])

  return (
    <div data-aos='fade-in' className='h-full w-full flex flex-col'>
      <div className='h-6 mb-3 flex justify-end'>

      </div>
      <div className='flex-1 rounded-2xl overflow-hidden w-full h-full'>
        <div ref={mapRef} className='h-full flex-1 rounded-2xl overflow-hidden' />
      </div>
      <p className='py-3 text-black-500 text-xs'>
        {markers && <><span className='text-primary'>{markers.length.toLocaleString()}</span> customers in {location.label}</>}
        {!markers && <span>Loading...</span>}
      </p>
    </div>
  )
}

const mapStyles = [
  { elementType: 'geometry', stylers: [{ color: '#121212' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#000000' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#777777' }] },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#444444' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#444444' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#000000' }],
  },
  // {
  //   featureType: 'road',
  //   elementType: 'labels',
  //   stylers: [{ visibility: 'off' }],
  // },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#444444' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    // elementType: 'labels.text.fill',
    // stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    // elementType: 'labels.text.stroke',
    // stylers: [{ color: '#17263c' }],
  },
]

import { useEffect, useState, useRef, useContext } from 'react'

import { DocumentTextIcon } from '@heroicons/react/24/solid'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/outline'

import { formatReportValue, columns } from './select'

export default function Index({ sort, setSort, htmlDb, filteredData, loaded, year, month, serviceArea, leadSource, reportType }) {
  if(!filteredData) {
    return (
      <div className='w-full h-full flex'>
        <div className='flex h-24 w-24 relative m-auto'>
          <span className='animate-ping z-0 bg-black-750 h-full w-full absolute top-0 left-0 rounded-full m-auto flex' />
          <DocumentTextIcon className='relative z-10 text-primary h-6 w-6 m-auto' />
        </div>
      </div>
    )
  }
  if(filteredData) {
    return (
      <>
        <div className='self-start fade-in overflow-visible border-b sticky top-0 bg-black-875 z-20'>
          <ul className='text-xs'>
            <li className='flex flex-nowrap items-stretch'>
              {columns.map((column, i) =>
                <button
                  key={'heading-'+column.key+'-'+i}
                  onClick={() => {
                    if(sort && sort.key === column.key) {
                      if(sort.direction === 'asc') {
                        setSort({
                          key: column.key,
                          direction: 'desc'
                        })
                      } else if(sort.direction === 'desc') {
                        setSort(false)
                      } else {
                        setSort({
                          key: column.key,
                          direction: 'asc'
                        })
                      }
                    } else {
                      setSort({
                        key: column.key,
                        direction: 'asc'
                      })
                    }
                  }} className={'flex justify-between text-left border-r px-2 py-2 w-44 shrink-0 ' + (i === 0 ? 'sticky left-0 z-20 ' : '') + (sort && sort.key === column.key ? 'bg-black-825 text-black-300' : 'text-black-500 bg-black-875')}>
                  <span>
                    <span className='block text-purple-500 pb-1'>{filteredData.heading[column.key] ? formatReportValue({ data: filteredData.heading[column.key], key: column.key }) : formatReportValue({ data: 0, key: column.key })}</span>
                    <span className='block leading-tight'>{column.label}</span>
                  </span>
                  <span className='h-full flex flex-col justify-center gap-1'>
                    <ArrowUpIcon className={'h-2.5 w-2.5 ' + (sort && sort.key === column.key && sort.direction === 'asc' ? 'text-primary' : 'text-black-700')} strokeWidth='3' />
                    <ArrowDownIcon className={'h-2.5 w-2.5 ' + (sort && sort.key === column.key && sort.direction === 'desc' ? 'text-primary' : 'text-black-700')} strokeWidth='3' />
                  </span>
                </button>
              )}
            </li>
          </ul>
        </div>

        <ul className='self-start overflow-visible divide-y text-sm'>
          {filteredData && filteredData.data && filteredData.data.length === 0 &&
            <p className='p-3 fade-in'>No data.</p>
          }
          {filteredData && filteredData.data && filteredData.data.length > 0 && filteredData.data.map((d,i) =>
            <li key={d.zip + '-' + i} className='fade-in flex flex-nowrap group hover:bg-black-850 hover:text-blue-500'>
              {columns.map((column, i) =>
                <div key={'column-'+i} className={'border-r px-3 py-2 w-44 shrink-0 ' + (i === 0 ? 'sticky left-0 z-10 bg-black-825 group-hover:bg-black-850 group-hover:text-yellow-500' : '')}>
                  {d[column.key] ? formatReportValue({ data: d[column.key], key: column.key }) : 0}
                </div>
              )}
            </li>
          )}
        </ul>
      </>
    )
  }
}

import { useContext } from 'react'
import { Context } from 'store/index'
import Color from 'color'

const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

// const red = [255,0,75]
const blue = [75,0,255]
const green = [0,255,75]

export const firebaseLists = [
  // {
  //   name: 'Running',
  //   listId: 'running',
  //   value: 'NEW',
  //   type: 'firebase',
  //   color: Color.rgb(green).mix(Color.rgb(blue), .2).rgb().string()
  // },
  {
    label: 'New Leads',
    value: 'NEW',
    // color: Color.rgb(red).mix(Color.rgb(blue), 0).rgb().string()
    color: Color.rgb(green).mix(Color.rgb(blue), .2).rgb().string()
  },
  {
    label: 'In Progress',
    value: 'Warm',
    color: Color.rgb(green).mix(Color.rgb(blue), .4).rgb().string()
  },
  {
    label: 'Follow Up',
    value: 'canCall',
    color: Color.rgb(green).mix(Color.rgb(blue), .6).rgb().string()
  },
  {
    label: 'Reconnect',
    value: 'canCall',
    color: Color.rgb(green).mix(Color.rgb(blue), .8).rgb().string()
  }
]

export const defaults = {
  wrapUpTimeStep: 30,
  wrapUpTimeMax: 210
}

export const lifeOptions = [
  {
    value: '1d',
    label: '1 day'
  },
  {
    value: '1w',
    label: '1 week'
  },
  {
    value: '2w',
    label: '2 weeks'
  },
  {
    value: '1m',
    label: '1 month'
  },
  {
    value: '3m',
    label: '3 months'
  },
  {
    value: '6m',
    label: '6 months'
  },
  {
    value: '1y',
    label: '1 year'
  }
]

export const contactProperties = [
  {
    value: 'firstname',
    label: 'First Name'

  },
  {
    value: 'lastname',
    label: 'Last Name'
  },
  {
    value: 'formatted_address',
    label: 'Address',
  },
  {
    value: 'hubspot_owner_id',
    label: 'Contact Owner',
    edit: true,
    options: true,
    format: (id) => {
      return GetOwner(id)
    }
  },
  {
    value: 'service_area',
    label: 'Service Area',
    edit: true,
    options: true,
    format: (value) => {
      return GetProperty('service_area', value)
    }
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phone',
    label: 'Phone',
    format: (number) => {
      return getPhoneNumber(number)
    }
  },
  {
    value: 'hs_lead_status',
    label: 'Lead Status',
    edit: true,
    options: true,
    format: (value) => {
      return GetProperty('hs_lead_status', value)
    }
  },
  {
    value: 'lifecyclestage',
    label: 'Lifecycle Stage',
    edit: true,
    options: true,
    format: (value) => {
      return GetProperty('lifecyclestage', value)
    }
  },
  {
    value: 'last_call_date',
    label: 'Last Call Date'
  },
  {
    value: 'outbound_call_number',
    label: 'Outbound Call Count',
  },
  {
    value: 'call_center_owner',
    label: 'Call Center Owner',
    options: true,
    format: (id) => {
      return GetOwner(id)
    }
  }
]

export const personalizationTokens = [{
  id: 'schedule_link',
  display: 'My scheduler',
  type: 'u-token'
},{
  id: 'name',
  display: 'My name',
  type: 'u-token'
},{
  id: 'email',
  display: 'My email',
  type: 'u-token'
},{
  id: 'firstname',
  display: 'First name',
  type: 'p-token'
},{
  id: 'lastname',
  display: 'Last name',
  type: 'p-token'
},{
  id: 'full_address',
  display: 'Address',
  type: 'p-token'
},{
  id: 'utility',
  display: 'Provider',
  type: 'p-token'
},{
  id: 'average_monthly_electric_bill',
  display: 'Average bill',
  type: 'p-token'
}]

export const yearsReports = [...Array(new Date().getFullYear() - 2020 + 1)].map((_, i) => ({
  label: (2020 + i).toString(),
  value: 2020 + i
}))

export const colorsReports = [
  'rgb(0 188 112)',
  'rgb(168,85,247)',
  'rgb(234 179 8)',
  'rgb(59 130 246)',
  'rgb(16 185 129)',
  'rgb(239 68 68)'
]

export const quarters = [...Array(4)].map((a,i) => ({
  label: 'Quarter — ' + (i + 1),
  value: i,
  months: [i+(2*i)+0,i+(2*i)+1,i+(2*i)+2]
}))

export const months = [
  {
    value: 0,
    label: 'Jan',
    long: 'January'
  },
  {
    value: 1,
    label: 'Feb',
    long: 'February'
  },
  {
    value: 2,
    label: 'Mar',
    long: 'March'
  },
  {
    value: 3,
    label: 'Apr',
    long: 'April'
  },
  {
    value: 4,
    label: 'May',
    long: 'May'
  },
  {
    value: 5,
    label: 'Jun',
    long: 'June'
  },
  {
    value: 6,
    label: 'Jul',
    long: 'July'
  },
  {
    value: 7,
    label: 'Aug',
    long: 'August'
  },
  {
    value: 8,
    label: 'Sep',
    long: 'September'
  },
  {
    value: 9,
    label: 'Oct',
    long: 'October'
  },
  {
    value: 10,
    label: 'Nov',
    long: 'November'
  },
  {
    value: 11,
    label: 'Dec',
    long: 'December'
  }
]

export function getDaysInMonth(month, year) {
  console.log(month, year)
  return new Date(year, month, 0).getDate()
}

export function getSanitized(val) {
  return val.toLowerCase().replace(/[^a-zA-Z0-9\s!?]+/g, '').replace(/   /g,'_').replace(/  /g,'_').replace(/ /g,'_')
}

export function passwordStale(date) {
  const duration = 180 // 6 months
  const pastTime = new Date(date)
  const now = new Date()

  const thirtyDaysInMs = duration * 24 * 60 * 60 * 1000
  const timeDiffInMs = now.getTime() - pastTime.getTime()

  if(timeDiffInMs >= thirtyDaysInMs){
    // console.log('Password is stale')
    return true
  } else {
    // console.log('Password is fresh')
    return false
  }
}

export function getBrowser() {
  var sBrowser, sUsrAg = navigator.userAgent
  if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'firefox'
    // 'Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0'
  } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
    sBrowser = 'samsung'
    // 'Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
  } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'opera'
    // 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106'
  } else if (sUsrAg.indexOf('Trident') > -1) {
    sBrowser = 'ie'
    // 'Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko'
  } else if (sUsrAg.indexOf('Edge') > -1) {
    sBrowser = 'edge'
    // 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299'
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'chrome'
    // 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36'
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'safari'
    // 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306'
  } else {
    sBrowser = 'unknown'
  }
  return sBrowser
}
export function getFormattedTime(duration) {
  let hours = Math.floor(duration / 3600)
  let minutes = Math.floor((duration - (hours * 3600)) / 60)
  let seconds = Math.floor(duration - (hours * 3600) - (minutes * 60))

  if(hours < 10) { hours = '0' + hours }
  if(minutes < 10) { minutes = '0' + minutes }
  if(seconds < 10) { seconds = '0' + seconds }

  return minutes + ':' + seconds
}

export function getHMSTime(duration) {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - (hours * 3600)) / 60)
  const seconds = Math.floor(duration - (hours * 3600) - (minutes * 60))

  const hDisplay = hours > 0 ? hours + (hours === 1 ? ' hour, ' : ' hours, ') : ''
  const mDisplay = minutes > 0 ? minutes + (minutes === 1 ? ' minute, ' : ' minutes, ') : ''
  const sDisplay = seconds > 0 ? seconds + (seconds === 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export function getName(contact) {
  let name = ''
  if(contact.firstname || contact.lastname) {
    name = (contact.firstname ? contact.firstname + ' ' : '') + (contact.lastname ? contact.lastname : '')
  } else if(contact.email) {
    name = contact.email
  } else if(contact.to) {
    name = contact.to
  }
  return name
}

export const states = [
  { label: 'Alaska', value: 'alaska' },
  { label: 'Alabama', value: 'alabama' },
  { label: 'Arkansas', value: 'arkansas' },
  { label: 'Arizona', value: 'arizona' },
  { label: 'California', value: 'california' },
  { label: 'Colorado', value: 'colorado' },
  { label: 'Connecticut', value: 'connecticut' },
  { label: 'District of Columbia', value: 'district of Columbia' },
  { label: 'Delaware', value: 'delaware' },
  { label: 'Florida', value: 'florida' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Hawaii', value: 'hawaii' },
  { label: 'Iowa', value: 'iowa' },
  { label: 'Idaho', value: 'idaho' },
  { label: 'Illinois', value: 'illinois' },
  { label: 'Indiana', value: 'indiana' },
  { label: 'Kansas', value: 'iansas' },
  { label: 'Kentucky', value: 'kentucky' },
  { label: 'Louisiana', value: 'louisiana' },
  { label: 'Massachusetts', value: 'massachusetts' },
  { label: 'Maryland', value: 'maryland' },
  { label: 'Maine', value: 'maine' },
  { label: 'Michigan', value: 'michigan' },
  { label: 'Minnesota', value: 'minnesota' },
  { label: 'Missouri', value: 'missouri' },
  { label: 'Mississippi', value: 'mississippi' },
  { label: 'Montana', value: 'montana' },
  { label: 'North Carolina', value: 'north-carolina' },
  { label: 'North Dakota', value: 'north-dakota' },
  { label: 'Nebraska', value: 'nebraska' },
  { label: 'New Hampshire', value: 'new-hampshire' },
  { label: 'New Jersey', value: 'new-jersey' },
  { label: 'New Mexico', value: 'new-mexico' },
  { label: 'Nevada', value: 'nevada' },
  { label: 'New York', value: 'new-york' },
  { label: 'Ohio', value: 'ohio' },
  { label: 'Oklahoma', value: 'oklahoma' },
  { label: 'Oregon', value: 'oregon' },
  { label: 'Pennsylvania', value: 'pennsylvania' },
  { label: 'Rhode Island', value: 'rhode-island' },
  { label: 'South Carolina', value: 'south-carolina' },
  { label: 'South Dakota', value: 'south-dakota' },
  { label: 'Tennessee', value: 'tennessee' },
  { label: 'Texas', value: 'texas' },
  { label: 'Utah', value: 'utah' },
  { label: 'Virginia', value: 'virginia' },
  { label: 'Vermont', value: 'vermont' },
  { label: 'Washington', value: 'washington' },
  { label: 'Wisconsin', value: 'wisconsin' },
  { label: 'West Virginia', value: 'west-virginia' },
  { label: 'Wyoming', value: 'wyoming' },
];

export function getAddress(contact, value) {
  let address = contact.formatted_address ? contact.formatted_address : false
  if(!address) {
    address = contact.address ? toTitleCase(contact.address) : false
    if(address) {
      if(contact.city) address += ', ' + toTitleCase(contact.city)
      if(contact.state) address += ', ' + (contact.state.length === 2 ? contact.state : toTitleCase(contact.state))
      if(contact.zip) address += ', ' + toTitleCase(contact.zip)
    }
  }
  return address ? address : value
}

export function getOwnerName(contact) {
  if(contact) {
    let name = ''
    if(contact.firstName || contact.lastName) {
      name = (contact.firstName ? contact.firstName + ' ' : '') + (contact.lastName ? contact.lastName : '')
    } else {
      name = contact.email
    }
    return name
  } else {
    return '...'
  }
}

export function getPhoneNumber(number) {
  // if(number) return phoneUtil.format(phoneUtil.parseAndKeepRawInput(number, 'US'), PNF.INTERNATIONAL)
  if(number) return phoneUtil.format(phoneUtil.parseAndKeepRawInput(number, 'US'), PNF.E164)
  else return 'error'
}

export function GetOwner(id) {
  const [state] = useContext(Context)
  if(state.owners) {
    return getOwnerName(state.owners.filter(owner => parseInt(owner.id) === parseInt(id))[0])
  } else {
    return '...'
  }
}

export function GetProperty(name, value) {
  const [state] = useContext(Context)
  if(state.properties) {
    const property = state.properties.filter(property => property.name === name)[0]
    return property.options.filter(option => option.value === value)[0].label
  } else {
    return '...'
  }
}

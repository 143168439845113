import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, doc, getDocs, setDoc } from 'firebase/firestore'

import { UserIcon, EyeIcon } from '@heroicons/react/24/solid'
import ReactTooltip from 'react-tooltip'

import { Context } from 'store/index'

import moment from 'moment'

export default function Index({ setCurrentContact, setShowModal, contacts, page, isPaging, loadMore, search }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [state, dispatch] = useContext(Context)

  return (
    <div className='h-full overflow-scroll'>
      {contacts === false &&
        <div className='w-full h-full flex'>
          <div className='flex h-32 w-32 relative m-auto'>
            <span className='z-0 bg-black-825 h-full w-full animate-ping absolute top-0 left-0 rounded-full m-auto flex' />
            <UserIcon className='relative z-10 text-primary h-6 w-6 m-auto' />
          </div>
        </div>
      }
      {contacts === null &&
        <div data-aos='fade-in' className='w-full h-full relative'>
          <div className='w-full h-full flex'>
            <p className='bg-black-825 rounded-2xl text-center flex-1 max-w-xs p-3 m-auto'>
              No contacts found.
            </p>
          </div>
        </div>
      }
      {contacts &&
        <div data-aos='fade-in'>
          <ul className='divide-y py-10vh'>
            {contacts.map(contact => {
              const name = (contact.firstname || contact.lastname) ? (contact.firstname ? contact.firstname + ' ' : '') + (contact.lastname ? contact.lastname : '') : false
              const callDate = contact.last_call_date ? parseInt(contact.last_call_date) + (new Date().getTimezoneOffset() * 60000) : false

              const filterServiceArea = state.contactProperties.service_area && contact.service_area ? state.contactProperties.service_area.filter(option => option.value === contact.service_area) : []
              const serviceArea = filterServiceArea.length > 0 ? filterServiceArea[0].label : false

              return (
                <li key={'list-'+contact.vid} className='fade-in text-sm relative w-full flex px-3 py-2.5 space-x-6 items-center'>
                  <button
                    data-tip='View contact'
                    data-for={contact.id}
                    data-delay-show={1000}
                    onClick={() => {
                      setCurrentContact(contact)
                      setShowModal(true)
                    }}
                    className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                    <EyeIcon className='h-4 w-4 m-auto' />
                    <ReactTooltip
                      className='text-sm'
                      id={contact.id}
                      place='right'
                      type='dark'
                      effect='solid'
                      multiline={true}
                    />
                  </button>
                  <span className='w-1/5 truncate'>
                    {name ?
                      <span>{name}</span> :
                      <span className='text-black-400'>&middot;&middot;&middot;</span>
                    }
                  </span>
                  <span className='flex-1 truncate text-black-400'>
                    <span>{contact.email}</span>
                  </span>
                  <span className='w-44 truncate text-black-400 text-right'>
                    {serviceArea && <span className='fade-in'>{serviceArea}</span>}
                    {!serviceArea && <span className='fade-in'>&middot;&middot;&middot;</span>}
                  </span>
                </li>
              )
            })}
          </ul>
          <div className='py-6'>
            {search === '' && page.hasMore &&
              <>
                {!isPaging &&
                  <button
                    onClick={() => loadMore()}
                    className='block m-auto bg-black-825 px-6 py-1.5 text-sm rounded-full text-black-200 hover:bg-black-800 hover:text-white active:bg-black-775'>
                    Load More
                  </button>
                }
                {isPaging &&
                  <span className='py-2 text-sm block text-center text-black-300'>
                    Loading more contacts...
                  </span>
                }
              </>
            }
            {!page.hasMore &&
              <span className='block px-3 py-16 text-black-400 font-mono uppercase text-xs'>
                There are no more contacts in this list.
              </span>
            }
          </div>
        </div>
      }
    </div>
  )
}

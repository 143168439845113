import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, orderBy, setDoc, doc } from 'firebase/firestore'

import { MapPinIcon, PencilIcon } from '@heroicons/react/24/solid'
import ReactTooltip from 'react-tooltip'

import EditModal from './edit-modal'
import AddModal from './add-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const teamUnsub = useRef(null)
  const [team, setTeam] = useState(false)
  const [currentMember, setCurrentMember] = useState(false)

  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(teamUnsub.current) teamUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getTeam() {
      const q = query(collection(db, 'team'), orderBy('name', 'asc'))
      teamUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            const result = querySnapshot.docs.reduce(function (r, a) {
              r[a.data().service_area.label] = r[a.data().service_area.label] || []
              r[a.data().service_area.label].push(a)
              return r
            }, Object.create(null))
            const sorted = Object.keys(result).sort().reduce(
              (obj, key) => {
                obj[key] = result[key]
                return obj
              },
              {}
            )
            setTeam(sorted)
          } else setTeam(null)
        }
      })
    }
    getTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-aos='fade-in' className='relative h-full'>
      <div className='p-3 fixed top-0 right-0 '>
        <button
          className='bg-black-800 hover:bg-black-750 rounded-full px-4 text-primary py-1 text-sm font-bold'
          onClick={() => {
            setShowAddModal(true)
          }}>
          Add Member
        </button>
      </div>
      <div className='h-full overflow-scroll py-16 space-y-8'>
        {team && Object.entries(team).map(([key, entries]) =>
          <div key={key} className='fade-in'>
            <h2 className='p-3 flex items-center gap-3 text-sm'>
              <MapPinIcon className='-mb-px text-primary h-5 w-5' />
              {key}
            </h2>
            <ul className='divide-y'>
              {entries.sort((a,b) => a.data().name.localeCompare(b.data().name)).map(member =>
                <li key={member.id} className='text-sm px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
                  <button
                    data-tip='Edit member'
                    data-for={member.id + '-edit'}
                    data-delay-show={1000}
                    onClick={() => {
                      setShowEditModal(true)
                      setCurrentMember({
                        id: member.id,
                        ...member.data()
                      })
                    }}
                    className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                    <PencilIcon className='h-4 w-4 m-auto' />
                    <ReactTooltip
                      className='text-sm'
                      id={member.id + '-edit'}
                      place='right'
                      type='dark'
                      effect='solid'
                      multiline={true}
                    />
                  </button>
                  <span>
                    <div className='flex items-center gap-3 pr-6'>
                      <label htmlFor={member.id + '-published'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                        <input
                          type='checkbox'
                          checked={member.data().isPublished ? false : true}
                          id={member.id + '-published'}
                          onChange={(e) => {
                            const isPublished = member.data().isPublished ? false : true
                            setDoc(doc(db, 'team', member.id), {
                              isPublished: isPublished,
                              updatedAt: new Date()
                            }, { merge: true })
                          }}
                          className='cursor-pointer h-full w-full sr-only peer' />
                        <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (member.data().isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                      </label>
                      <span className={'text-xs ' + (member.data().isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                    </div>
                  </span>
                  <span>
                    <span className='h-10 w-10 bg-black-775 rounded-full flex'>
                       {member.data().image ?
                         <img className='h-full w-full rounded-full overflow-hidden' src={member.data().image.url} alt='Profile avatar.' /> :
                         <svg xmlns='http://www.w3.org/2000/svg' className='m-auto text-primary h-4 w-4' viewBox='0 0 20 20' fill='currentColor'>
                           <path fillRule='evenodd' d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z' clipRule='evenodd' />
                        </svg>
                       }
                     </span>
                  </span>
                  <span className='flex-1 truncate'>{member.data().name}</span>
                  <span className='flex-1 text-black-400 truncate'>{member.data().title}</span>
                  <span>
                    <button onClick={() => {
                      setDoc(doc(db, 'team', member.id), {
                        isFranchise: member.data().isFranchise ? false : true,
                        updatedAt: new Date()
                      }, { merge: true })
                    }} className={'transition duration-200 text-xs rounded-full px-4 py-1 ' + (member.data().isFranchise ? 'ring-1 ring-primary text-primary' : 'text-black-400 bg-black-800')}>
                      Franchise
                    </button>
                  </span>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
      <EditModal member={currentMember} setCurrentMember={setCurrentMember} showModal={showEditModal} setShowModal={setShowEditModal} />
      <AddModal member={currentMember} setCurrentMember={setCurrentMember} showModal={showAddModal} setShowModal={setShowAddModal} setShowEditModal={setShowEditModal} />
    </div>
  )
}

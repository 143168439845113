import Logo from 'components/logo'

import MenuMain from 'components/menu-main'
import MenuTools from 'components/menu-tools'
import MenuHelp from 'components/menu-help'

export default function Index({ children }) {
  return (
    <div className='flex'>
      <Logo />
      <div className='flex-1 min-w-0'>
        {children}
      </div>
      <div className='fixed top-0 right-0 z-40'>
        <MenuMain />
      </div>
      <div className='fixed bottom-0 left-0 z-40'>
        <MenuTools />
      </div>
      <div className='hidden fixed bottom-0 right-0 z-40'>
        <MenuHelp />
      </div>
    </div>
  )
}

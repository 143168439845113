import { useEffect, useRef, useContext } from 'react'

import { Context } from 'store/index'

export default function Index({ contact }) {
  const script = useRef(null)
  const mounted = useRef(null)

  const [state] = useContext(Context)

  useEffect(() => {
    mounted.current = true
    return () => {
      if(script.current) removeScheduler()
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(script.current) removeScheduler()
    loadScheduler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.service_area])

  function loadScheduler() {
    const el = document.createElement('script')
    el.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    document.body.appendChild(el)
    el.onload = () => {
      if(mounted.current) script.current = el
    }
  }

  function removeScheduler() {
    try {
      document.body.removeChild(script.current)
    } catch(e) {
      console.log(e)
    }
    script.current = false
  }

  function getPropertyOption(value) {
    const fields = '&firstname=' + (contact.firstname || '') + '&lastname=' + (contact.lastname || '') + '&email=' + (contact.email || '') + '&phone=' + (contact.phone || '') + '&formatted_address=' + (contact.formatted_address || '')
    const props = [
      {
        value: 'KC Metro',
        scheduler: 'risingsun/kc-in-home?embed=true' + fields
      },
      {
        value: 'Wichita',
        scheduler: 'live-calendar/wichita-call-ops?embed=true' + fields
      },
      {
        value: 'St. Louis',
        scheduler: 'live-calendar/st-louis-call-ops?embed=true' + fields
      },
      {
        value: 'Houston',
        scheduler: 'risingsun/tx-in-home?embed=true' + fields
      },
      {
        value: 'Dallas Fort Worth',
        scheduler: 'risingsun/tx-in-home?embed=true' + fields
      },
      {
        value: 'Oklahoma City',
        scheduler: 'live-calendar/okc-call-ops?embed=true' + fields
      }
    ]
    return props.find(option => option.value === value).scheduler
  }

  return (
    <div data-aos='fade-in' className='h-full overflow-scroll'>
      {!contact.service_area &&
        <div className='w-full h-full flex'>
          <div className='m-auto p-6 bg-black-750 rounded-xl'>
            Service Area Not Selected
          </div>
        </div>
      }
      {contact.service_area && state.contactProperties.service_area &&
         <div className='w-full max-w-md mx-auto'>
           <div>
             <div
               className='w-full meetings-iframe-container'
               data-src={'https://app.hubspot.com/meetings/' + getPropertyOption(contact.service_area)} />
           </div>
         </div>
       }
     </div>
  )
}

import { useState, useEffect, useRef, useContext } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, updateDoc, doc } from 'firebase/firestore'

import useHubspot from 'lib/useHubspot'
import { getBrowser } from 'lib/utils'
import { Context } from 'store/index'

import { PencilIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

import moment from 'moment'

import Modal from 'components/modal'

export default function Index({ setCurrentContact, setShow, scrollEl, contact }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const leadReturnModal = useRef(null)
  const avgBillModal = useRef(null)
  const propValModal = useRef(null)
  const yearBuiltModal = useRef(null)
  const sqFtModal = useRef(null)

  const [browser, setBrowser] = useState('')
  const [editField, setEditField] = useState('')

  const [state, dispatch] = useContext(Context)

  const hubspot = useHubspot()

  useEffect(() => {
    setBrowser(getBrowser())
  }, [])

  async function updateContact(property, value) {
    await updateDoc(doc(db, 'contacts', contact.vid.toString()), {
      [property]: value,
      updatedAt: new Date()
    })
    setCurrentContact(prev => ({
      ...prev,
      [property]: value
    }))
    dispatch({ type: 'toast-set', payload: 'Updating contact in Hubspot.' })
    await hubspot.updateContact(contact.vid, [{ property: property, value: value }])
    dispatch({ type: 'toast-set', payload: false })
    setTimeout(() => {
      dispatch({ type: 'toast-set', payload: 'Contact updated in Hubspot!' })
    }, 400)
  }

  return (
    <div data-aos='fade-in' className='h-full overflow-scroll'>
      <div className='flex gap-2 px-2 pt-4'>
        <span className='flex-1 bg-black-800 border border-black-775 rounded px-1 py-2 text-xs'>
          <span className='text-black-300 block'>Last Call</span>
          {contact.last_call_date ?
            moment(parseInt(contact.last_call_date) + (new Date().getTimezoneOffset() * 60000)).calendar(null, {
              lastDay:  '[Yesterday]',
              nextDay:  '[Today]',
              sameDay:  '[Today]',
              lastWeek: () => ('[' + moment(parseInt(contact.last_call_date) + (new Date().getTimezoneOffset() * 60000)).fromNow() + ']'),
              sameElse: () => ('[' + moment(parseInt(contact.last_call_date) + (new Date().getTimezoneOffset() * 60000)).fromNow() + ']')
            }) :
            <span className='text-black-500'>&middot;&middot;&middot;</span>
          }
        </span>
        <span className='flex-1 bg-black-800 border border-black-775 rounded px-1 py-2 text-xs'>
          <span className='text-black-300 block'>Call Count</span>
          {contact.outbound_call_number && contact.outbound_call_number ? contact.outbound_call_number : 0}
        </span>
      </div>
      <ul className='text-sm py-3 divide-y divide-black-775'>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactOwners &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('hubspot_owner_id', e.target.value)}
                value={contact.hubspot_owner_id && contact.hubspot_owner_id ? contact.hubspot_owner_id : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactOwners.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
           <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.hubspot_owner_id) {
                navigator.clipboard.writeText(state.contactOwners.find(el => {
                  return contact.hubspot_owner_id.toString() === el.value.toString()
                })?.label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Contact owner copied to clipboard!' })
                })
              }
            }}>Contact Owner</span>
            <span className='flex-1 truncate relative'>
             {(state.contactOwners && contact.hubspot_owner_id) ?
               state.contactOwners.find(el => {
                 return contact.hubspot_owner_id.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactOwners &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('hubspot_owner_id', e.target.value)}
                value={contact.call_center_owner && contact.call_center_owner ? contact.call_center_owner : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactOwners.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
           <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.call_center_owner) {
                navigator.clipboard.writeText(state.contactOwners.find(el => {
                  return contact.call_center_owner.toString() === el.value.toString()
                })?.label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Contact owner copied to clipboard!' })
                })
              }
            }}>Call Center Owner</span>
            <span className='flex-1 truncate relative'>
             {(state.contactOwners && contact.call_center_owner) ?
               state.contactOwners.find(el => {
                 return contact.call_center_owner.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactProperties.service_area &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('service_area', e.target.value)}
                value={contact.service_area && contact.service_area ? contact.service_area : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactProperties.service_area.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.service_area) {
                navigator.clipboard.writeText(state.contactProperties.service_area.find(el => {
                  return contact.service_area.toString() === el.value.toString()
                })?.label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Service area copied to clipboard!' })
                })
              }
            }}>Service Area</span>
            <span className='flex-1 truncate relative'>
             {(state.contactProperties.service_area && contact.service_area) ?
               state.contactProperties.service_area.find(el => {
                 return contact.service_area.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactProperties.hs_lead_status &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('hs_lead_status', e.target.value)}
                value={contact.hs_lead_status && contact.hs_lead_status ? contact.hs_lead_status : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactProperties.hs_lead_status.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.hs_lead_status) {
                navigator.clipboard.writeText(state.contactProperties.hs_lead_status.find(el => {
                  return contact.hs_lead_status.toString() === el.value.toString()
                })?.label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Lead status copied to clipboard!' })
                })
              }
            }}>Lead Status</span>
            <span className='flex-1 truncate relative'>
             {(state.contactProperties.hs_lead_status && contact.hs_lead_status) ?
               state.contactProperties.hs_lead_status.find(el => {
                 return contact.hs_lead_status.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactProperties.lifecyclestage &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('lifecyclestage', e.target.value)}
                value={contact.lifecyclestage && contact.lifecyclestage ? contact.lifecyclestage : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactProperties.lifecyclestage.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.lifecyclestage) {
                navigator.clipboard.writeText(state.contactProperties.lifecyclestage.find(el => {
                  return contact.lifecyclestage.toString() === el.value.toString()
                })?.label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Lifecycle stage copied to clipboard!' })
                })
              }
            }}>Lifecycle Stage</span>
            <span className='flex-1 truncate relative'>
             {(state.contactProperties.lifecyclestage && contact.lifecyclestage) ?
               state.contactProperties.lifecyclestage.find(el => {
                 return contact.lifecyclestage.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactProperties.lead_return_reason &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('lead_return_reason', e.target.value)}
                value={contact.lead_return_reason && contact.lead_return_reason ? contact.lead_return_reason : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactProperties.lead_return_reason.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.lead_return_reason) {
                navigator.clipboard.writeText(state.contactProperties.lead_return_reason.find(el => {
                  return contact.lead_return_reason.toString() === el.value.toString()
                }).label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Lead return reason copied to clipboard!' })
                })
              }
            }}>Return Reason</span>
            <span className='flex-1 truncate relative'>
             {(state.contactProperties.lead_return_reason && contact.lead_return_reason) ?
               state.contactProperties.lead_return_reason.find(el => {
                 return contact.lead_return_reason.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          <span>
            <Modal
              ref={leadReturnModal}
              center={true}
              onClose={() => setEditField('')}>
              <button
                trigger='true'
                className='h-5 w-5 text-black-300 rounded-full bg-black-750 hover:bg-black-725  active:bg-black-700 hover:text-white flex'
                onClick={() => {
                  leadReturnModal.current.open()
                  setEditField(contact.lead_return_details)
                }}>
                <PencilIcon className='h-3 w-3 m-auto' />
              </button>
              <div content='true' className='max-w-full w-96 space-y-6 p-3'>
                <p className='text-3xl text-center'>Lead Return Details</p>
                <textarea
                  value={editField ? editField : ''}
                  className='placeholder-black-300 h-44 focus:ring-2 focus:ring-primary transition duration-200 block rounded-lg resize-none bg-black-800 w-full focus:outline-none p-4'
                  onChange={(e) => setEditField(e.target.value)}
                  placeholder='Edit field'
                />
                <button
                  disabled={editField ? false : true}
                  className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' +(editField ? 'text-black bg-primary hover:bg-primary-700' : 'bg-black-800 text-black-300')}
                  onClick={async () => {
                    updateContact('lead_return_details', editField)
                    setEditField('')
                    leadReturnModal.current.close()
                  }}>
                  Update
                </button>
              </div>
            </Modal>
          </span>
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.lead_return_details) {
                navigator.clipboard.writeText(contact.lead_return_details).then(() => {
                dispatch({ type: 'toast-set', payload: 'Lead return details copied to clipboard!' })
                })
              }
            }}>Return Details</span>
            <span className='flex-1 truncate'>
             {contact.lead_return_details ? contact.lead_return_details :
               <span className='text-black-500'><span>&middot;&middot;&middot;</span></span>
             }
           </span>
         </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          <span>
            <Modal
              ref={leadReturnModal}
              center={true}
              onClose={() => setEditField('')}>
              <button
                trigger='true'
                className='h-5 w-5 text-black-300 rounded-full bg-black-750 hover:bg-black-725  active:bg-black-700 hover:text-white flex'
                onClick={() => {
                  leadReturnModal.current.open()
                  setEditField(contact.lead_return_details)
                }}>
                <PencilIcon className='h-3 w-3 m-auto' />
              </button>
              <div content='true' className='max-w-full w-96 space-y-6 p-3'>
                <p className='text-3xl text-center'>Lead Return Details</p>
                <textarea
                  value={editField ? editField : ''}
                  className='placeholder-black-300 h-44 focus:ring-2 focus:ring-primary transition duration-200 block rounded-lg resize-none bg-black-800 w-full focus:outline-none p-4'
                  onChange={(e) => setEditField(e.target.value)}
                  placeholder='Edit field'
                />
                <button
                  disabled={editField ? false : true}
                  className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' +(editField ? 'text-black bg-primary hover:bg-primary-700' : 'bg-black-800 text-black-300')}
                  onClick={async () => {
                    updateContact('lead_return_details', editField)
                    setEditField('')
                    leadReturnModal.current.close()
                  }}>
                  Update
                </button>
              </div>
            </Modal>
          </span>
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.lead_return_details) {
                navigator.clipboard.writeText(contact.lead_return_details).then(() => {
                dispatch({ type: 'toast-set', payload: 'Lead return details copied to clipboard!' })
                })
              }
            }}>Return Details</span>
            <span className='flex-1 truncate'>
             {contact.lead_return_details ? contact.lead_return_details :
               <span className='text-black-500'><span>&middot;&middot;&middot;</span></span>
             }
           </span>
         </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          <span>
            <Modal
              ref={avgBillModal}
              center={true}
              onClose={() => setEditField('')}>
              <button
                trigger='true'
                className='h-5 w-5 text-black-300 rounded-full bg-black-750 hover:bg-black-725  active:bg-black-700 hover:text-white flex'
                onClick={() => {
                  avgBillModal.current.open()
                  setEditField(contact.average_monthly_electric_bill)
                }}>
                <PencilIcon className='h-3 w-3 m-auto' />
              </button>
              <div content='true' className='max-w-full w-96 space-y-6 p-3'>
                <p className='text-3xl text-center'>Avg. Bill</p>
                <input
                  value={editField ? editField : ''}
                  className='placeholder-black-300 focus:ring-2 focus:ring-primary transition duration-200 block rounded-lg resize-none bg-black-800 w-full focus:outline-none p-4'
                  onChange={(e) => setEditField(e.target.value)}
                  placeholder='Edit field'
                />
                <button
                  disabled={editField ? false : true}
                  className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' +(editField ? 'text-black bg-primary hover:bg-primary-700' : 'bg-black-800 text-black-300')}
                  onClick={async () => {
                    updateContact('average_monthly_electric_bill', editField)
                    setEditField('')
                    avgBillModal.current.close()
                  }}>
                  Update
                </button>
              </div>
            </Modal>
          </span>
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.average_monthly_electric_bill) {
                navigator.clipboard.writeText(contact.average_monthly_electric_bill).then(() => {
                dispatch({ type: 'toast-set', payload: 'Average bill copied to clipboard!' })
                })
              }
            }}>Average Bill</span>
            <span className='flex-1 truncate'>
             {contact.average_monthly_electric_bill ? contact.average_monthly_electric_bill :
               <span className='text-black-500'><span>&middot;&middot;&middot;</span></span>
             }
           </span>
         </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactProperties.qualified_roof_shading &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('qualified_roof_shading', e.target.value)}
                value={contact.qualified_roof_shading && contact.qualified_roof_shading ? contact.qualified_roof_shading : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactProperties.qualified_roof_shading.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.qualified_roof_shading) {
                navigator.clipboard.writeText(state.contactProperties.qualified_roof_shading.find(el => {
                  return contact.qualified_roof_shading.toString() === el.value.toString()
                })?.label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Roof shading copied to clipboard!' })
                })
              }
            }}>Roof Shading</span>
            <span className='flex-1 truncate relative'>
             {(state.contactProperties.qualified_roof_shading && contact.qualified_roof_shading) ?
               state.contactProperties.qualified_roof_shading.find(el => {
                 return contact.qualified_roof_shading.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          {state.contactProperties.roof_complexity &&
            <span className='relative block h-5 w-5 rounded-full text-black-300 bg-black-750 rounded-full overflow-hidden hover:bg-black-725 active:bg-black-700 hover:text-white active:bg-black-775 flex'>
              <span className='relative z-10 ring-black-750 ring-2 pointer-events-none h-full w-full flex items-center bg-black-750 rounded-full'>
                <ChevronDownIcon className='h-4 w-4 m-auto' />
              </span>
              <select
                onChange={(e) => updateContact('roof_complexity', e.target.value)}
                value={contact.roof_complexity && contact.roof_complexity ? contact.roof_complexity : ''}
                className={'cursor-pointer z-0 absolute h-full w-full ' + (browser === 'edge' || browser === 'ie' ? 'text-black' : '')}>
                <option disabled value=''>Select an option</option>
                {state.contactProperties.roof_complexity.map((option) =>
                  <option key={option.value} value={option.value}>{option.label}</option>
                )}
              </select>
            </span>
          }
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.roof_complexity) {
                navigator.clipboard.writeText(state.contactProperties.roof_complexity.find(el => {
                  return contact.roof_complexity.toString() === el.value.toString()
                })?.label).then(() => {
                dispatch({ type: 'toast-set', payload: 'Roof complexity copied to clipboard!' })
                })
              }
            }}>Roof Complexity</span>
            <span className='flex-1 truncate relative'>
             {(state.contactProperties.roof_complexity && contact.roof_complexity) ?
               state.contactProperties.roof_complexity.find(el => {
                 return contact.roof_complexity.toString() === el.value.toString()
               })?.label : <span className='text-black-500'>&middot;&middot;&middot;</span>
             }
             </span>
           </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          <span>
            <Modal
              ref={propValModal}
              center={true}
              onClose={() => setEditField('')}>
              <button
                trigger='true'
                className='h-5 w-5 text-black-300 rounded-full bg-black-750 hover:bg-black-725  active:bg-black-700 hover:text-white flex'
                onClick={() => {
                  propValModal.current.open()
                  setEditField(contact.home_value)
                }}>
                <PencilIcon className='h-3 w-3 m-auto' />
              </button>
              <div content='true' className='max-w-full w-96 space-y-6 p-3'>
                <p className='text-3xl text-center'>Property Value</p>
                <input
                  value={editField ? editField : ''}
                  className='placeholder-black-300 focus:ring-2 focus:ring-primary transition duration-200 block rounded-lg resize-none bg-black-800 w-full focus:outline-none p-4'
                  onChange={(e) => setEditField(e.target.value)}
                  placeholder='Edit field'
                />
                <button
                  disabled={editField ? false : true}
                  className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' +(editField ? 'text-black bg-primary hover:bg-primary-700' : 'bg-black-800 text-black-300')}
                  onClick={async () => {
                    updateContact('home_value', editField)
                    setEditField('')
                    propValModal.current.close()
                  }}>
                  Update
                </button>
              </div>
            </Modal>
          </span>
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.home_value) {
                navigator.clipboard.writeText(contact.home_value).then(() => {
                dispatch({ type: 'toast-set', payload: 'Property value copied to clipboard!' })
                })
              }
            }}>Property Value</span>
            <span className='flex-1 truncate'>
             {contact.home_value ? contact.home_value :
               <span className='text-black-500'><span>&middot;&middot;&middot;</span></span>
             }
           </span>
         </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          <span>
            <Modal
              ref={sqFtModal}
              center={true}
              onClose={() => setEditField('')}>
              <button
                trigger='true'
                className='h-5 w-5 text-black-300 rounded-full bg-black-750 hover:bg-black-725  active:bg-black-700 hover:text-white flex'
                onClick={() => {
                  sqFtModal.current.open()
                  setEditField(contact.property_square_footage)
                }}>
                <PencilIcon className='h-3 w-3 m-auto' />
              </button>
              <div content='true' className='max-w-full w-96 space-y-6 p-3'>
                <p className='text-3xl text-center'>Square Footage</p>
                <input
                  value={editField ? editField : ''}
                  className='placeholder-black-300 focus:ring-2 focus:ring-primary transition duration-200 block rounded-lg resize-none bg-black-800 w-full focus:outline-none p-4'
                  onChange={(e) => setEditField(e.target.value)}
                  placeholder='Edit field'
                />
                <button
                  disabled={editField ? false : true}
                  className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' +(editField ? 'text-black bg-primary hover:bg-primary-700' : 'bg-black-800 text-black-300')}
                  onClick={async () => {
                    updateContact('property_square_footage', editField)
                    setEditField('')
                    sqFtModal.current.close()
                  }}>
                  Update
                </button>
              </div>
            </Modal>
          </span>
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.property_square_footage) {
                navigator.clipboard.writeText(contact.property_square_footage).then(() => {
                dispatch({ type: 'toast-set', payload: 'Square footage copied to clipboard!' })
                })
              }
            }}>Square Footage</span>
            <span className='flex-1 truncate'>
             {contact.property_square_footage ? contact.property_square_footage :
               <span className='text-black-500'><span>&middot;&middot;&middot;</span></span>
             }
           </span>
         </span>
        </li>
        <li className='px-2 py-1.5 flex items-center gap-3 group hover:bg-black-850'>
          <span>
            <Modal
              ref={yearBuiltModal}
              center={true}
              onClose={() => setEditField('')}>
              <button
                trigger='true'
                className='h-5 w-5 text-black-300 rounded-full bg-black-750 hover:bg-black-725  active:bg-black-700 hover:text-white flex'
                onClick={() => {
                  yearBuiltModal.current.open()
                  setEditField(contact.year_built)
                }}>
                <PencilIcon className='h-3 w-3 m-auto' />
              </button>
              <div content='true' className='max-w-full w-96 space-y-6 p-3'>
                <p className='text-3xl text-center'>Year Built</p>
                <input
                  value={editField ? editField : ''}
                  className='placeholder-black-300 focus:ring-2 focus:ring-primary transition duration-200 block rounded-lg resize-none bg-black-800 w-full focus:outline-none p-4'
                  onChange={(e) => setEditField(e.target.value)}
                  placeholder='Edit field'
                />
                <button
                  disabled={editField ? false : true}
                  className={'mx-auto block m-auto py-2 pb-2.5 px-8 transition duration-200 rounded-full font-bold ' +(editField ? 'text-black bg-primary hover:bg-primary-700' : 'bg-black-800 text-black-300')}
                  onClick={async () => {
                    updateContact('year_built', editField)
                    setEditField('')
                    yearBuiltModal.current.close()
                  }}>
                  Update
                </button>
              </div>
            </Modal>
          </span>
          <span className='flex-1 flex flex-col items-start truncate'>
            <span className='text-sm block text-black-500 cursor-pointer -mb-1' onClick={() => {
              if(contact.year_built) {
                navigator.clipboard.writeText(contact.year_built).then(() => {
                dispatch({ type: 'toast-set', payload: 'Year built copied to clipboard!' })
                })
              }
            }}>Year Built</span>
            <span className='flex-1 truncate'>
             {contact.year_built ? contact.year_built :
               <span className='text-black-500'><span>&middot;&middot;&middot;</span></span>
             }
           </span>
         </span>
        </li>
      </ul>
   </div>
  )
}

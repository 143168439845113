import { Link } from 'react-router-dom'

import { EyeIcon, CommandLineIcon, QrCodeIcon, BookOpenIcon, CubeIcon, GlobeAmericasIcon, ArrowRightIcon, MapIcon, LinkIcon, HomeModernIcon, ComputerDesktopIcon, RocketLaunchIcon, ChartBarIcon, MapPinIcon, UserIcon, UserGroupIcon, EllipsisVerticalIcon } from '@heroicons/react/24/solid'

const nodes = [
  {
    name: 'Insights',
    href: '/insights',
    description: 'View reports on organization data.',
    icon: <EyeIcon className='h-5 w-5' />,
    active: 'text-purple-500'
  },
  {
    name: 'Reports',
    href: '/reports',
    description: 'View reports on organization data.',
    icon: <ChartBarIcon className='h-5 w-5' />,
    active: 'text-purple-500'
  },
  {
    name: 'Customers',
    href: '/customers',
    description: 'View reports on organization data.',
    icon: <GlobeAmericasIcon className='h-5 w-5' />,
    active: 'text-purple-500'
  },
  {
    name: 'Website',
    href: '/website',
    description: 'View reports on organization data.',
    icon: <ComputerDesktopIcon className='h-5 w-5' />,
    active: 'text-purple-500'
  },
  {
    name: 'AI Proposals',
    href: '/proposals',
    description: 'View reports on organization data.',
    icon: <CommandLineIcon className='h-5 w-5' />,
    active: 'text-purple-500'
  },
  {
    name: 'Short links',
    href: '/short-links',
    description: 'View reports on organization data.',
    icon: <QrCodeIcon className='h-5 w-5' />,
    active: 'text-purple-500'
  },
  // {
  //   name: 'Models',
  //   href: '/models',
  //   description: 'View reports on organization data.',
  //   icon: <CubeIcon className='h-5 w-5' />,
  //   active: 'text-purple-500'
  // },
  {
    name: 'Contacts',
    href: '/contacts',
    description: 'View reports on organization data.',
    icon: <BookOpenIcon className='h-5 w-5' />,
    active: 'text-primary'
  },
  {
    name: 'Users',
    href: '/users',
    description: 'View reports on organization data.',
    icon: <UserGroupIcon className='h-5 w-5' />,
    active: 'text-purple-500'
  },
  // {
  //   name: 'Data',
  //   href: '/data',
  //   description: 'View reports on organization data.',
  //   icon: <UserGroupIcon className='h-5 w-5' />,
  //   active: 'text-purple-500'
  // }
]

export default function Index() {
  return (
    <div className='fade-in w-full px-3 py-32'>
      <ul className='w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-3 gap-y-8 m-auto'>
        {nodes.map(node => {
          return (
            <li key={node.name}>
              <Link to={node.href} className={'leading-none group rounded-lg p-4 gap-2 w-full flex flex-col text-left relative bg-black-875 hover:bg-black-850'}>
                <span className='text-black-400'>{node.icon}</span>
                <span className='text-lg leading-none pt-3'>{node.name}</span>
                <span className='text-sm leading-tight text-black-400 mb-20'>{node.description}</span>
                <ArrowRightIcon className='text-primary h-6 w-6 self-end' />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

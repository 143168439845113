import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'

function Index({ children, onClose }, ref) {
  const buttonRef = useRef(null)
  const contentRef = useRef(null)

  const [show, _setShow] = useState(false)
  const showRef = useRef(show)
  const setShow = data => {
    showRef.current = data
    _setShow(data)
  }

  useImperativeHandle(ref, () => ({
    open: () => setShow(true),
    close: () => setShow(false)
  }))

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleClickOutside(event) {
    if(!buttonRef.current.contains(event.target) && showRef.current && !contentRef.current.contains(event.target)) {
      setShow(false)
    }
  }

  const trigger = children.find(child => child.props.trigger)
  const content = children.find(child => child.props.content)

  return (
    <span className='block relative z-20'>
      <button ref={buttonRef} onClick={() => setShow(!show)}>
        {trigger}
      </button>
      {show &&
        <span
          ref={contentRef}
          className='fade-down absolute left-0 top-full'>
          {content}
        </span>
      }
    </span>
  )
}

export default forwardRef(Index)

import { useState, useContext } from 'react'

import Careers from './components/careers'
import Education from './components/education'
import Team from './components/team'
import Locations from './components/locations'
import Timeline from './components/timeline'
import BrandAssets from './components/brand-assets'
import Reviews from './components/reviews'

import { Context } from 'store/index'

const sections = [
  {
    label: 'Locations',
    value: 'locations',
    hidden: true
  },
  {
    label: 'Team',
    value: 'team'
  },
  {
    label: 'Careers',
    value: 'careers'
  },
  {
    label: 'Education',
    value: 'education',
    hidden: true
  },
  {
    label: 'Timeline',
    value: 'timeline'
  },
  ,
  {
    label: 'Brand Assets',
    value: 'brand-assets'
  },
  // {
  //   label: 'Reviews',
  //   value: 'reviews'
  // }
]

export default function Index() {
  const [state,] = useContext(Context)
  const [show, setShow] = useState(sections[0])

  return (
    <div data-aos='fade-in' className='h-screen w-full px-3 py-16'>
      <div className='bg-black-875 rounded-2xl overflow-hidden flex h-full'>
        <div className='w-1/4 h-full bg-black-825 rounded-2xl overflow-scroll'>
          <div className='p-3 space-y-3 h-full flex flex-col items-start'>
            <p className='mb-12 font-bold text-primary'>Website</p>
            <ul className='space-y-1 w-full pb-3 divide-y divide-black-775'>
              {sections.map(section => {
                let shouldShow = section.hidden ? false : true

                if(section.hidden && state.profile && state.profile.access && state.profile.access && state.profile.access.global && state.profile.access.global.find(a => a === 'Super Admin')) {
                  shouldShow = true
                }

                if(shouldShow) {
                  return (
                    <li key={section.value}>
                      <button
                        className={'mt-1 w-full flex gap-3 items-center text-left px-3 pr-6 py-3 text-sm rounded-full hover:bg-black-775'}
                        onClick={() => setShow(section)}>
                        <span className={'h-3 w-3 rounded-full ' + (show.value === section.value ? 'bg-primary' : 'bg-black-600')} />
                        <span>{section.label}</span>
                      </button>
                    </li>
                  )
                } else {
                  return null
                }
              })}
            </ul>
          </div>
        </div>
        <div className='flex-1 overflow-scroll'>
          {show.value === 'careers' && <Careers />}
          {show.value === 'education' && <Education />}
          {show.value === 'team' && <Team />}
          {show.value === 'locations' && <Locations />}
          {show.value === 'timeline' && <Timeline />}
          {show.value === 'reviews' && <Reviews />}
          {show.value === 'brand-assets' && <BrandAssets />}
        </div>
      </div>
    </div>
  )
}

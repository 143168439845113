import { useRef, useState, useEffect, useContext } from 'react'
import { Context } from 'store/index'

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const timer = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if(state.toast) {
      if(timer.current) clearTimeout(timer.current)
      setShow(true)
      timer.current = setTimeout(() => {
        setShow(false)
        dispatch({ type: 'toast-set', payload: false })
      }, 3000)
    }
    else {
      if(timer.current) clearTimeout(timer.current)
      setShow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.toast])

  if(!show) return false
  return (
    <div style={{ zIndex: '100' }} className='fixed bottom-6 left-0 w-full flex justify-center pointer-events-none'>
      <div data-aos='fade-up' className='filter drop-shadow-lg bg-emerald-500 rounded-lg text-black py-3 px-8 min-w-sm pointer-events-auto'>
        {state.toast}
      </div>
    </div>
  )
}

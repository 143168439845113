import { useContext } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, doc, updateDoc, setDoc } from 'firebase/firestore'

import { defaults, getFormattedTime } from 'lib/utils'

import { Context } from 'store/index'
import { PencilIcon, ChevronDownIcon } from '@heroicons/react/24/solid'

import ProfileImage from './profile-image'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [state] = useContext(Context)

  return (
    <div data-aos='fade-in' className='p-6'>
      <div className='max-w-sm m-auto space-y-6'>
        <ProfileImage />
        <ul className='flex-1 divide-y bg-black-825 p-2 rounded-2xl'>
          <li className='flex items-center space-x-3 py-1.5'>
            <div className='flex-1'>
              <p className='text-black-500 text-sm'>Name</p>
              <p className='truncate text-sm'>{state.profile && state.profile.name}</p>
            </div>
            <span>
              <button className='h-7 w-7 flex rounded-full text-black-300 hover:text-white bg-black-750 hover:bg-black-725 active:bg-black-700'>
                <PencilIcon className='h-4 w-4 m-auto' />
              </button>
            </span>
          </li>
          <li className='flex items-center space-x-3 py-1.5'>
            <div className='flex-1'>
              <p className='text-black-500 text-sm'>Email</p>
              <p className='truncate text-sm'>{state.profile && state.profile.email}</p>
            </div>
            <span>
              <button className='h-7 w-7 flex rounded-full text-black-300 hover:text-white bg-black-750 hover:bg-black-725 active:bg-black-700'>
                <PencilIcon className='h-4 w-4 m-auto' />
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  )
}

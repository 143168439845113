import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, where, limit, Timestamp, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'

import { Context } from 'store/index'

import { UserIcon, EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

import ProfileImage from './profile-image'

import slugify from 'slugify'

import { getAuth } from 'firebase/auth'

export default function Index() {
  const [state, dispatch] = useContext(Context)

  const [show, setShow] = useState(false)
  const [profile, setProfile] = useState(false)

  useEffect(() => {
    setProfile(state.profile)
  }, [state.profile])

  return (
    <div className='relative overflow-visible'>
      <button
        onClick={() => setShow(!show)}
        className='group bg-black-825 flex h-10 w-10 transition duration-200 rounded-full hover:bg-black-800'>
        <UserIcon className='transition text-primary duration-200 group-hover:scale-125 h-4 w-4 m-auto' />
      </button>

      <Modal profile={profile} showModal={show} setShowModal={setShow} />
    </div>
  )
}

function Modal({ profile, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const auth = getAuth(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-xs transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='flex-1 overflow-scroll'>
          <div className='stickytop-0 z-10 bg-black-850 bg-opacity-40 backdrop-filter backdrop-blur-md'>
            <div className='p-3'>
              <button
                onClick={() => {
                  setShowModal(false)
                }}
                className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
                <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
              </button>
            </div>
            <div className='relative p-2 space-y-6 py-6'>
              <ProfileImage />
              <ul className='flex-1 divide-y bg-black-825 p-2 rounded-2xl'>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-black-500 text-sm'>First Name</p>
                    <p className='truncate text-sm'>{profile && profile.firstName}</p>
                  </div>
                </li>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-black-500 text-sm'>Last Name</p>
                    <p className='truncate text-sm'>{profile && profile.lastName}</p>
                  </div>
                </li>
                <li className='flex items-center space-x-3 py-1.5'>
                  <div className='flex-1'>
                    <p className='text-black-500 text-sm'>Email</p>
                    <p className='truncate text-sm'>{profile && profile.email}</p>
                  </div>
                </li>
              </ul>
              <button className='font-bold text-black bg-primary hover:brightness-110 transition duration-200 py-1.5 text-sm rounded-full px-8' onClick={() => auth.signOut()}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}

import { useRef, useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { XMarkIcon } from '@heroicons/react/24/solid'

import slugify from 'slugify'

import Contact from './contact'

export default function Index({ contact, setCurrentContact, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
        setData(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-40 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-5xl transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='p-3'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 overflow-hidden p-3 pt-0'>
          <Contact contact={contact} setCurrentContact={setCurrentContact} />
        </div>
      </div>
    </div>
  , document.body) : null
}

import { useRef, useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, getDoc, addDoc, setDoc, onSnapshot, orderBy, deleteField, updateDoc, doc } from 'firebase/firestore'

import { XMarkIcon } from '@heroicons/react/24/solid'

import slugify from 'slugify'

export default function Index({ location, setLocation, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)

  function isComplete(){
    return data && data.position && data.position !== '' && data.service_area && data.service_area !== ''
  }

  useEffect(() => {
    if(showModal === 'add') {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
        setData(false)
      }, 500)
    }
  }, [animate])

  return display ? createPortal(
    <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-sm transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='p-3'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 overflow-hidden flex'>
          <div className='flex-1 h-full overflow-hidden overflow-scroll'>
            <div className='space-y-12 min-h-full'>
              <div className='p-3 pr-6'>
                <h2 className='text-xl'>Add location</h2>
              </div>
              <div className='space-y-2 p-3 pr-6'>
                <TextInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    position: e.target.value
                  }))
                }} data={data} property='position' label='Position' placeholder='Enter position' />
                <TextInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    service_area: e.target.value
                  }))
                }} data={data} property='service_area' label='Service Area' placeholder='Enter service area' />
              </div>
            </div>
            <div className='border-t border-black-750 text-sm sticky bottom-0 bg-black-850 p-3 flex z-50 justify-between'>
              <div className='flex gap-1'>
                <button
                  onClick={async () => {
                    console.log('add')
                    const docRef = await addDoc(collection(db, 'locations'), {
                      ...data,
                      slug: slugify(data.service_area + '-' + data.position, {remove: /[#$%^&=*+~.,/;?—()''!@]/g}).toLowerCase(),
                      updatedAt: new Date(),
                      createdAt: new Date()
                    }, { merge: true })
                    const docSnap = await getDoc(doc(db, 'locations', docRef.id))
                    setShowModal(false)
                    if(docSnap.exists()) {
                      setTimeout(() => {
                        setLocation({
                          id: docSnap.id,
                          ...docSnap.data()
                        })
                        setShowModal('edit')
                      }, 400)
                    } else {
                      console.log('Error creating doc')
                    }
                  }}
                  disabled={isComplete() ? false : true}
                  className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (isComplete() ? 'bg-primary text-black hover:bg-purple-600' : 'text-black-400 bg-black-775')}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}

function TextInput({ data, property, label, onChange, placeholder }) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <input
        id={property}
        className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
        type='text'
        onChange={onChange}
        placeholder={placeholder}
        value={data[property] ? data[property] : ''} />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}

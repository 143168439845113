import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, orderBy, setDoc, doc } from 'firebase/firestore'

import { PencilIcon } from '@heroicons/react/24/solid'
import ReactTooltip from 'react-tooltip'

import EditModal from './edit-modal'
import AddModal from './add-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const timelineUnsub = useRef(null)
  const [timeline, setTimeline] = useState(false)
  const [currentEvent, setCurrentEvent] = useState(false)

  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(timelineUnsub.current) timelineUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getTimeline() {
      const q = query(collection(db, 'timeline'), orderBy('date', 'desc'))
      timelineUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setTimeline(querySnapshot.docs.map(doc => {
              const update = {
                id: doc.id,
                ...doc.data()
              }
              if(doc.data().date) update.date = doc.data().date.toDate()
              return update
            }))
          } else setTimeline(null)
        }
      })
    }
    getTimeline()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-aos='fade-in' className='relative h-full'>
      <div className='p-3 fixed top-0 right-0 '>
        <button
          className='bg-black-800 hover:bg-black-750 rounded-full px-4 text-primary py-1 text-sm font-bold'
          onClick={() => {
            setShowAddModal(true)
          }}>
          Add Event
        </button>
      </div>
      <div className='h-full overflow-scroll py-16 space-y-8'>
        {timeline &&
          <ul className='fade-in divide-y'>
            {timeline.map(tevent =>
              <li key={tevent.id} className='text-sm px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
                <button
                  data-tip='Edit event'
                  data-for={tevent.id + '-edit'}
                  data-delay-show={1000}
                  onClick={() => {
                    setShowEditModal(true)
                    setCurrentEvent(tevent)
                  }}
                  className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                  <PencilIcon className='h-4 w-4 m-auto' />
                  <ReactTooltip
                    className='text-sm'
                    id={tevent.id + '-edit'}
                    place='right'
                    type='dark'
                    effect='solid'
                    multiline={true}
                  />
                </button>
                <span>
                  <div className='flex items-center gap-3 pr-6'>
                    <label htmlFor={tevent.id + '-published'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                      <input
                        type='checkbox'
                        checked={tevent.isPublished ? false : true}
                        id={tevent.id + '-published'}
                        onChange={(e) => {
                          const isPublished = tevent.isPublished ? false : true
                          setDoc(doc(db, 'timeline', tevent.id), {
                            isPublished: isPublished,
                            updatedAt: new Date()
                          }, { merge: true })
                        }}
                        className='cursor-pointer h-full w-full sr-only peer' />
                      <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (tevent.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                    </label>
                    <span className={'text-xs ' + (tevent.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                  </div>
                </span>
                <span className='flex-1 truncate'>{tevent.title}</span>
                <span className='flex-1 text-black-400 truncate'>{tevent.description}</span>
                <span className='flex-1 text-black-400 truncate text-right'>{tevent.date.toLocaleDateString('en-us', { weekday:'short', year:'numeric', month:'short', day:'numeric'})}</span>
              </li>
            )}
          </ul>
        }
      </div>
      <EditModal currentEvent={currentEvent} setCurrentEvent={setCurrentEvent} showModal={showEditModal} setShowModal={setShowEditModal} />
      <AddModal currentEvent={currentEvent} setCurrentEvent={setCurrentEvent} showModal={showAddModal} setShowModal={setShowAddModal} setShowEditModal={setShowEditModal} />
    </div>
  )
}

export default function useHubspot() {
    const incrementContactProperty = (contactId, property) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/hubspotIncrementContactProperty', {
          method: 'POST',
          body: JSON.stringify({
            contactId: contactId,
            property: property
          })
        })
        const responseJSON = await response.json()
        resolve(responseJSON)
      } catch(e) {
        console.log(e)
      }
    })
  }

  const updateContact = (vid, properties) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/hubspotUpdateContact', {
          method: 'POST',
          body: JSON.stringify({
            vid: vid,
            properties: properties
          })
        })
        const responseJSON = await response.json()
        resolve(responseJSON)
      } catch(e) {
        console.log(e)
      }
    })
  }

  return {
    incrementContactProperty,
    updateContact
  }
}

import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, deleteDoc, setDoc, doc } from 'firebase/firestore'

import { XMarkIcon } from '@heroicons/react/24/solid'

import UploadImage from './upload-image'
import Select from 'react-select'

import { brandAssets as categories } from 'lib/categories'

export default function Index({ asset, setCurrentAsset, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)
  const [original, setOriginal] = useState(false)

  function compareObjs(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setCurrentAsset(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  useEffect(() => {
    if(asset) {
      setData(asset)
      if(!original) {
        setOriginal(asset)
      }
    } else {
      setData(false)
      setOriginal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset])

  return display ? createPortal(
    <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-screen bg-black-850 w-full max-w-md transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='p-3'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 h-full overflow-hidden overflow-scroll'>
          {data &&
            <div className='space-y-12'>
              <div className='p-3 pr-6'>
                <h2 className='text-xl'>{data.name}</h2>
                <p className='font-bold mb-6'>{data.title}</p>
                <div className='flex gap-6'>
                  <div className='flex items-center gap-3'>
                    <label htmlFor={data.id + '-published-edit'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                      <input
                        type='checkbox'
                        checked={data.isPublished ? true : false}
                        id={data.id + '-published-edit'}
                        onChange={(e) => {
                          const isPublished = data.isPublished ? false : true
                          setData(prev => ({
                            ...prev,
                            isPublished: isPublished
                          }))
                          // setDoc(doc(db, 'careers', data.id), {
                          //   isPublished: isPublished,
                          //   updatedAt: new Date()
                          // }, { merge: true })
                        }}
                        className='cursor-pointer h-full w-full sr-only peer' />
                      <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (data.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                    </label>
                    <span className={'text-xs ' + (data.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                  </div>
                </div>
              </div>

              <UploadImage
                data={data}
                collection='brand-assets'
                folder='brand-assets'
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    image: e
                  }))
                }}
              />

              <div className='space-y-2 p-3 pr-6'>
                <TextInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    title: e.target.value
                  }))
                }} data={data} property='title' label='Title' />
                <SelectCategories
                  data={data.categories}
                  property='categories'
                  label='Category'
                  onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      categories: e
                    }))
                  }} />
                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      packageUrl: e.target.value
                    }))
                  }} data={data} property='packageUrl' label='Package URL' />
              </div>

              <div className='p-3 pb-12'>
                <button
                  onClick={async () => {
                    await deleteDoc(doc(db, 'brand-assets', data.id))
                    setShowModal(false)
                  }}
                  className='font-bold px-6 py-1.5 text-sm rounded-lg text-red-500 bg-black-775'>
                  Delete
                </button>
              </div>
            </div>
          }
        </div>
        <div className='border-t border-black-750 text-sm bg-black-850 p-3 flex z-50 justify-between'>
          <button
            onClick={() => {
              setData(original)
            }}
            className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
            Discard
          </button>
          <div className='flex gap-1'>
            <button
              onClick={async () => {
                const update = {}
                Object.entries(data).forEach(([k, v]) => {
                  update[k] = v
                })
                delete update.id
                delete update.createdAt
                delete update.updatedAt
                await setDoc(doc(db, 'brand-assets', data.id), {
                  ...update,
                  updatedAt: new Date()
                }, { merge: true })
                setOriginal(data)
              }}
              className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
              Save
            </button>
            <button
              onClick={async () => {
                const update = {}
                Object.entries(data).forEach(([k, v]) => {
                  update[k] = v
                })
                delete update.id
                delete update.createdAt
                delete update.updatedAt
                await setDoc(doc(db, 'brand-assets', data.id), {
                  ...update,
                  updatedAt: new Date()
                }, { merge: true })
                setShowModal(false)
              }}
              className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-black bg-primary hover:bg-purple-600')}>
              Save & Close
            </button>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}

function TextInput({ data, property, label, onChange}) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <input
        id={property}
        className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
        type='text'
        onChange={onChange}
        value={data[property] ? data[property] : ''} />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}
function SelectCategories({ property, label, data, onChange }) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <Select
        menuPlacement='top'
        className='react-select-multi-select relative z-10'
        classNamePrefix='react-select'
        isMulti={true}
        value={data ? data : []}
        onChange={onChange}
        options={categories}
      />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}

import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, setDoc, orderBy, doc } from 'firebase/firestore'

import { ComputerDesktopIcon, MapPinIcon, PencilIcon, ChevronRightIcon, FolderIcon, PhotoIcon, ListBulletIcon, DocumentTextIcon } from '@heroicons/react/24/solid'

import ReactTooltip from 'react-tooltip'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import EditPageModal from './edit-page-modal'
import EditModal from './edit-modal'
import AddModal from './add-modal'

import {
  Tree,
  MultiBackend,
  getBackendOptions
} from "@minoru/react-dnd-treeview"

export default function Index() {
  const [treeData, setTreeData] = useState(SampleData)
  const handleDrop = (newTree) => {
    setTreeData(newTree)
  }

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const locationsUnsub = useRef(null)
  const placesUnsub = useRef(null)
  const ownersUnsub = useRef(null)

  const [locations, setLocations] = useState(false)
  const [places, setPlaces] = useState(false)
  const [owners, setOwners] = useState(false)

  const [currentLocation, setCurrentLocation] = useState(false)
  const [currentPage, setCurrentPage] = useState(false)

  const [showEditPageModal, setShowEditPageModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(locationsUnsub.current) locationsUnsub.current()
      if(placesUnsub.current) placesUnsub.current()
      if(ownersUnsub.current) ownersUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getLocations() {
      const q = query(collection(db, 'locations'), orderBy('createdAt', 'asc'))
      locationsUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setLocations(querySnapshot.docs.map(d => {
              return {
                id: d.id,
                ...d.data()
              }
            }))
          } else setLocations(null)
        }
      })
    }
    async function getPlaces() {
      const q = query(collection(db, 'google-places'), orderBy('createdAt', 'asc'))
      placesUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setPlaces(querySnapshot.docs.map(d => {
              return {
                id: d.id,
                ...d.data()
              }
            }))
          } else setPlaces(null)
        }
      })
    }

    async function getOwners() {
      const q = query(collection(db, 'contacts-owners'), orderBy('createdAt', 'asc'))
      ownersUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setOwners(querySnapshot.docs.map(d => {
              return {
                id: d.id,
                ...d.data()
              }
            }))
          } else setOwners(null)
        }
      })
    }
    getLocations()
    getPlaces()
    getOwners()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log(places)

  return (
    <div data-aos='fade-in' className='relative h-full'>
      <div className='p-3 fixed top-0 right-0 '>
        <button
          className='bg-black-800 hover:bg-black-750 rounded-full px-4 text-primary py-1 text-sm font-bold'
          onClick={() => {
            setShowAddModal(true)
          }}>
          Add Location
        </button>
      </div>
      <div className='h-full overflow-scroll py-16 space-y-8 divide-y'>
        {/* <TreeList treeData={treeData} handleDrop={handleDrop} /> */}
        {locations &&
          <ul className='divide-y fade-in'>
            {locations.sort((a,b) => a.name.localeCompare(b.name)).map(location =>
              <li key={location.id}>
                <div className='border-b px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-4'>
                  <button
                    data-tip='Edit location'
                    data-for={location.id + '-edit'}
                    data-delay-show={1000}
                    onClick={() => {
                      setShowEditModal(true)
                      setCurrentLocation({
                        id: location.id,
                        ...location
                      })
                    }}
                    className='h-9 w-9 text-primary rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                    <PencilIcon className='h-4 w-4 m-auto' />
                    <ReactTooltip
                      className='text-sm'
                      id={location.id + '-edit'}
                      place='right'
                      type='dark'
                      effect='solid'
                      multiline={true}
                    />
                  </button>
                  <span className='flex-1 text-black-500 truncate font-bold'>
                    <span className='text-white'>{location.name}</span>
                    <span className='text-sm'> &mdash; {location.address ? location.address : 'No address'}</span>
                  </span>
                  <span className='flex-1 text-sm text-right pr-6 text-black-500'>
                    {location.salesReps ? location.salesReps.length + (location.salesReps.length > 1 ? ' sales reps' : ' sales rep' ) : 'No sales reps'}
                  </span>
                  <span>
                    <div className='flex items-center gap-4'>
                      <span className={'text-xs ' + (location.isPublished ? 'text-white' : 'text-black-400')}>{location.isPublished ? 'Published' : 'Draft'}</span>
                      <label htmlFor={location.id + '-published'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                        <input
                          type='checkbox'
                          checked={location.isPublished ? false : true}
                          id={location.id + '-published'}
                          onChange={(e) => {
                            const isPublished = location.isPublished ? false : true
                            setDoc(doc(db, 'locations', location.id), {
                              isPublished: isPublished,
                              updatedAt: new Date()
                            }, { merge: true })
                          }}
                          className='cursor-pointer h-full w-full sr-only peer' />
                        <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (location.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                      </label>
                    </div>
                  </span>
                </div>
                <ul className='ml-16 text-xs divide-y'>
                  <li className='py-2 flex gap-3 items-center'>
                    <button
                      data-tip='Edit'
                      data-for={location.id + '-edit'}
                      data-delay-show={1000}
                      onClick={() => {
                        setCurrentLocation({
                          id: location.id,
                          ...location
                        })
                        setCurrentPage({
                          id: 'landing',
                          title: 'Landing',
                          slug: 'landing'
                        })
                        setShowEditPageModal(true)
                      }}
                      className='h-6 w-6 text-primary disabled:text-black-400 rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                      <PencilIcon className='h-3 w-3 m-auto' />
                      <ReactTooltip
                        className='text-sm'
                        id={location.id + '-edit'}
                        place='right'
                        type='dark'
                        effect='solid'
                        multiline={true}
                      />
                    </button>
                    <ComputerDesktopIcon className='h-3 w-3' />
                    <span className='block'>Landing</span>
                  </li>
                  <li className='py-2 flex gap-3 items-center text-black-500'>
                    <button
                      data-tip='Edit'
                      data-for={location.id + '-edit'}
                      data-delay-show={1000}
                      disabled={true}
                      onClick={() => {
                        setCurrentLocation({
                          id: location.id,
                          ...location
                        })
                        setCurrentPage({
                          id: 'quote',
                          title: 'Get a Quote',
                          slug: 'quote'
                        })
                        setShowEditPageModal(true)
                      }}
                      className='h-6 w-6 text-primary disabled:text-black-400 rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                      <PencilIcon className='h-3 w-3 m-auto' />
                      <ReactTooltip
                        className='text-sm'
                        id={location.id + '-edit'}
                        place='right'
                        type='dark'
                        effect='solid'
                        multiline={true}
                      />
                    </button>
                    <ComputerDesktopIcon className='h-3 w-3' />
                    <span className='block'>Get a Quote</span>
                  </li>
                  <li className='py-2 flex gap-3 items-center text-black-500'>
                    <button
                      data-tip='Edit'
                      data-for={location.id + '-edit'}
                      data-delay-show={1000}
                      disabled={true}
                      onClick={() => {
                        setCurrentLocation({
                          id: location.id,
                          ...location
                        })
                        setCurrentPage({
                          id: 'about',
                          title: 'About',
                          slug: 'about'
                        })
                        setShowEditPageModal(true)
                      }}
                      className='h-6 w-6 text-primary disabled:text-black-400 rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                      <PencilIcon className='h-3 w-3 m-auto' />
                      <ReactTooltip
                        className='text-sm'
                        id={location.id + '-edit'}
                        place='right'
                        type='dark'
                        effect='solid'
                        multiline={true}
                      />
                    </button>
                    <ComputerDesktopIcon className='h-3 w-3' />
                    <span className='block'>About</span>
                  </li>
                  <li className='py-2 flex gap-3 items-center text-black-500'>
                    <button
                      data-tip='Edit'
                      data-for={location.id + '-edit'}
                      data-delay-show={1000}
                      disabled={true}
                      onClick={() => {
                        setCurrentLocation({
                          id: location.id,
                          ...location
                        })
                        setCurrentPage({
                          id: 'contact',
                          title: 'Contact Us',
                          slug: 'contact'
                        })
                        setShowEditPageModal(true)
                      }}
                      className='h-6 w-6 text-primary disabled:text-black-400 rounded-full transition duration-200 bg-black-800 hover:bg-black-750 hover:text-white flex'>
                      <PencilIcon className='h-3 w-3 m-auto' />
                      <ReactTooltip
                        className='text-sm'
                        id={location.id + '-edit'}
                        place='right'
                        type='dark'
                        effect='solid'
                        multiline={true}
                      />
                    </button>
                    <ComputerDesktopIcon className='h-3 w-3' />
                    <span className='block'>Contact</span>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        }
      </div>
      <EditPageModal location={currentLocation} setCurrentLocation={setCurrentLocation} currentPage={currentPage} setCurrentPage={setCurrentPage} showModal={showEditPageModal} setShowModal={setShowEditPageModal} />
      <EditModal owners={owners} places={places} location={currentLocation} setCurrentLocation={setCurrentLocation} showModal={showEditModal} setShowModal={setShowEditModal} />
      <AddModal owners={owners} places={places} location={currentLocation} setCurrentLocation={setCurrentLocation} showModal={showAddModal} setShowModal={setShowAddModal} setShowEditModal={setShowEditModal} />
    </div>
  )
}

function TreeList({ treeData, handleDrop }) {
  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <div className='relative'>
        <Tree
          tree={treeData}
          rootId={0}
          render={(node, { depth, isOpen, onToggle }) => (
            <CustomNode
              node={node}
              depth={depth}
              isOpen={isOpen}
              onToggle={onToggle}
            />
          )}
          dragPreviewRender={(monitorProps) => (
            <CustomDragPreview monitorProps={monitorProps} />
          )}
          onDrop={handleDrop}
          classes={{
            root: 'bg-black-800 rounded-lg p-2',
            draggingSource: 'opacity-25',
            placeholder: 'relative'
          }}
          sort={false}
          insertDroppableFirst={false}
          canDrop={(tree, { dragSource, dropTargetId, dropTarget }) => {
            if (dragSource?.parent === dropTargetId) {
              return true
            }
          }}
          dropTargetOffset={5}
          placeholderRender={(node, { depth }) => (
            <Placeholder node={node} depth={depth} />
          )}
        />
      </div>
    </DndProvider>
  )
}

export const CustomNode = (props) => {
  const { droppable, data } = props.node
  const indent = props.depth * 24

  const handleToggle = (e) => {
    e.stopPropagation()
    props.onToggle(props.node.id)
  }

  return (
    <div
      className={'flex items-center gap-2 border-b border-transparent'}
      style={{ paddingInlineStart: indent }}>
        <div className={'transition duration-200 ' + (props.isOpen ? 'transfrom rotate-90' : '')}>
          {props.node.droppable && (
            <button onClick={handleToggle}>
              <ChevronRightIcon className='h-4 w-4' />
            </button>
          )}
        </div>
      <div>
        <TypeIcon droppable={droppable || false} fileType={data?.fileType} />
      </div>
      <div>
        {props.node.text}
      </div>
    </div>
  )
}

export const TypeIcon = (props) => {
  if(props.droppable) {
    return <FolderIcon className='h-4 w-4' />
  }

  switch (props.fileType) {
    case "image":
      return <PhotoIcon className='h-4 w-4' />
    case "csv":
      return <ListBulletIcon className='h-4 w-4' />
    case "text":
      return <DocumentTextIcon className='h-4 w-4' />
    default:
      return null
  }
}

export const CustomDragPreview = (props) => {
  const item = props.monitorProps.item

  return (
    <div className={'flex items-center gap-2 p-2 rounded absolute left-0 bg-gray-700 pointer-events-none shadow-lg'}>
      <div className={''}>
        <TypeIcon
          droppable={item.droppable || false}
          fileType={item?.data?.fileType}
        />
      </div>
      <div>{item.text}</div>
    </div>
  )
}

export const Placeholder = (props) => {
  const left = props.depth * 24
  return <div className='relative border-b border-primary transform -translate-y-1/2' style={{ left }}></div>
}

const SampleData = [
  {
    "id": 1,
    "parent": 0,
    "droppable": true,
    "text": "Folder 1"
  },
  {
    "id": 2,
    "parent": 1,
    "droppable": false,
    "text": "File 1-1",
    "data": {
      "fileType": "csv",
      "fileSize": "0.5MB"
    }
  },
  {
    "id": 3,
    "parent": 1,
    "droppable": false,
    "text": "File 1-2",
    "data": {
      "fileType": "text",
      "fileSize": "4.8MB"
    }
  },
  {
    "id": 4,
    "parent": 0,
    "droppable": true,
    "text": "Folder 2"
  },
  {
    "id": 5,
    "parent": 4,
    "droppable": true,
    "text": "Folder 2-1"
  },
  {
    "id": 6,
    "parent": 5,
    "droppable": false,
    "text": "File 2-1-1",
    "data": {
      "fileType": "image",
      "fileSize": "2.1MB"
    }
  },
  {
    "id": 7,
    "parent": 0,
    "droppable": false,
    "text": "File 3",
    "data": {
      "fileType": "image",
      "fileSize": "0.8MB"
    }
  }
]

import { useRef, useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'

import { getApp } from 'firebase/app'
import { getFirestore, setDoc, deleteDoc, collection, query, where, doc, onSnapshot, limit, orderBy, Timestamp } from 'firebase/firestore'

import { Wrapper } from '@googlemaps/react-wrapper'

import { Context } from 'store/index'

import { getSanitized } from 'lib/utils'

import LayoutAuthenticated from 'components/layout-authenticated'

import Dashboard from 'views/dashboard'
import Contacts from 'views/contacts'
import ContactsID from 'views/contacts-id'
import ShortLinks from 'views/short-links'
import Users from 'views/users'
import Settings from 'views/settings'
import Customers from 'views/customers'
import Website from 'views/website'
import Proposals from 'views/proposals'
import Reports from 'views/reports'
import Insights from 'views/insights'
import Data from 'views/data'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const location = useLocation()
  const navigate = useNavigate()

  const listLimit = 250

  const mounted = useRef(null)

  const propertiesUnsub = useRef(null)
  const ownersUnsub = useRef(null)
  const queueNewLeadsUnsub = useRef(null)
  const queueUnsub = useRef(null)
  const contactUnsub = useRef(null)

  const snoozeLeadsTimer = useRef(null)
  const updateTokenTimer = useRef(null)
  const callTimer = useRef(null)
  const wrapUpTimer = useRef(null)

  const [state, dispatch] = useContext(Context)

  useEffect(() => {
    if(state.user) {
      if(location.pathname === '/login' || location.pathname === '/signup') {
        navigate('/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user])

  useEffect(() => {
    mounted.current = true
    getProperties()
    getOwners()

    return () => {
      mounted.current = false
      clearTimers()
      clearSubscriptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function clearTimers() {
    if(updateTokenTimer.current) clearInterval(updateTokenTimer.current)
    if(snoozeLeadsTimer.current) clearInterval(snoozeLeadsTimer.current)
    if(callTimer.current) clearInterval(callTimer.current)
    if(wrapUpTimer.current) clearInterval(wrapUpTimer.current)
  }

  function clearSubscriptions() {
    if(propertiesUnsub.current) propertiesUnsub.current()
    if(ownersUnsub.current) ownersUnsub.current()
    if(queueNewLeadsUnsub.current) queueNewLeadsUnsub.current()
    if(queueUnsub.current) queueUnsub.current()
    if(contactUnsub.current) contactUnsub.current()
  }

  async function getOwners() {
    try {
      // console.log('get owners')
      const q = query(collection(db, 'contacts-owners'), orderBy('createdAt', 'desc'))
      ownersUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            dispatch({
              type: 'contact-owners-set',
              payload: querySnapshot.docs.sort((a, b) => a.data().firstName.localeCompare(b.data().firstName)).map(doc => ({ value: doc.id, label: doc.data().firstName + ' ' + doc.data().lastName, email: doc.data().email })) })
          }
          else dispatch({ type: 'contact-owners-set', payload: null })
        }
      })
    } catch(e) {
      console.log(e)
    }
  }

  async function getProperties() {
    try {
      // console.log('get properties')
      const q = query(collection(db, 'contacts-properties'), where('name', 'in', ['lead_source', 'hs_lead_status', 'service_area', 'lifecyclestage', 'qualified_roof_shading', 'roof_complexity', 'lead_return_reason', 'call_disposistions']))
      propertiesUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            const properties = {}
            querySnapshot.docs.forEach(doc => {
              properties[doc.id] = doc.data().options
            })
            dispatch({ type: 'contact-properties-set', payload: properties })
          } else dispatch({ type: 'contact-properties-set', payload: null })
        }
      })
    } catch(e) {
      console.log(e)
    }
  }

  return (
    <Wrapper apiKey={process.env.REACT_APP_G_API_KEY} libraries={['places', 'drawing']}>
      <LayoutAuthenticated>
        <Routes>
          <Route path='/' element={<Dashboard />} />
          <Route exact path='/contacts/:id' element={<ContactsID />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/customers' element={<Customers />} />
          <Route path='/website' element={<Website />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/short-links' element={<ShortLinks />} />
          <Route path='/users' element={<Users />} />
          <Route path='/proposals' element={<Proposals />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/insights' element={<Insights />} />
          <Route path='/data' element={<Data />} />
        </Routes>
      </LayoutAuthenticated>
    </Wrapper>
  )
}

import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, setDoc, doc } from 'firebase/firestore'

import { XMarkIcon, StarIcon } from '@heroicons/react/24/solid'

export default function Index({ review, setCurrentReview, showModal, setShowModal }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setCurrentReview(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  useEffect(() => {
    if(review) {
      setData(review)
    } else {
      setData(false)
    }
  }, [review])

  return display ? createPortal(
    <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-md transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='p-3'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 h-full overflow-hidden overflow-scroll'>
          {data &&
            <div className='space-y-12'>
              <div className='p-3 pr-6'>
                <h2 className='text-xl'>{data.author_title}</h2>
                <p className='text-black-400 mb-6'>{data.createdAt.toDate().toLocaleString('en-us', { weekday:'long', year:'numeric', month:'short', day:'numeric',hour: '2-digit', minute:'2-digit'})}</p>
                <div className='flex gap-6'>
                  <div className='flex items-center gap-3'>
                    <label htmlFor={data.id + '-published-edit'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                      <input
                        type='checkbox'
                        checked={data.isPublished ? true : false}
                        id={data.id + '-published-edit'}
                        onChange={(e) => {
                          const isPublished = data.isPublished ? false : true
                          setData(prev => ({
                            ...prev,
                            isPublished: isPublished
                          }))
                          setDoc(doc(db, 'reviews', data.id), {
                            isPublished: isPublished,
                            updatedAt: new Date()
                          }, { merge: true })
                        }}
                        className='cursor-pointer h-full w-full sr-only peer' />
                      <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (data.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                    </label>
                    <span className={'text-xs ' + (data.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                  </div>
                </div>
              </div>

              <div className='space-y-2 p-3 pr-6 pb-16'>
                <div className='flex items-center gap-6 mb-6'>
                  <p className='text-black-400 leading-none'>{data.review_rating} star</p>
                  <div className='flex gap-1 -mb-px'>
                    {[...Array(parseInt(data.review_rating))].map(i =>
                      <StarIcon key={'yellow-' + i} className={'h-3.5 w-3.5 m-auto ' + (parseInt(data.review_rating) !== 5 ? 'text-primary' : 'text-yellow-500')} />
                    )}
                    {[...Array(5 - parseInt(data.review_rating))].map(i =>
                      <StarIcon key={'gray-' + i} className='text-black-600 h-3.5 w-3.5 m-auto' />
                    )}
                  </div>
                </div>
                <p>{data.review_text}</p>
              </div>

            </div>
          }
        </div>
      </div>
    </div>
  , document.body) : null
}

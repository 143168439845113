import { useEffect, useState, useRef, useContext } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, addDoc, doc, increment, updateDoc, setDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import { getAddress, personalizationTokens, getSanitized } from 'lib/utils'

import { Context } from 'store/index'

import Modal from 'components/modal'
import Dropdown from 'components/dropdown'

import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import { convertArea } from '@turf/helpers'
import * as turf from '@turf/turf'

import { v4 as uuidv4 } from 'uuid'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY

const basePanel = {
  value: 'rec-alpha-black',
  label: 'Rec Alpha Black',
  image: '/images/alpha-black.jpg',
  wattage: 370,
  height: {
    inches: 67.75,
    miles: 0.0010692866,
    gutter: 6
  },
  width: {
    inches: 40,
    miles: 0.000631313,
    gutter: 6
  },
  cost: 3.00
}

export default function Index({ contact }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp, 'gs://sundial-mms')

  const [state, dispatch] = useContext(Context)

  const [planes, setPlanes] = useState(turf.featureCollection([]))
  const [baselines, setBaselines] = useState(turf.featureCollection([]))
  // const [panels, setPanels] = useState(turf.featureCollection([]))
  const panels = useRef(turf.featureCollection([]))

  const mounted = useRef(false)
  const mapContainer = useRef(null)
  const map = useRef(null)
  const draw = useRef(null)
  const snapModal = useRef(null)

  const [drawMode, setDrawMode] = useState('select')
  const [snapSrc, setSnapSrc] = useState(false)
  const [zoom, setZoom] = useState(0)
  const [area, setArea] = useState(0)
  const [size, setSize] = useState(0)
  const [numPanels, setNumPanels] = useState(0)
  const [style, setStyle] = useState('mapbox')
  const [bingTiles, setBingTiles] = useState({})
  const [mapLoaded, setMapLoaded] = useState(false)

  const [messageSent, setMessageSent] = useState(false)
  const [smsTemplate, setSmsTemplate] = useState(false)
  const [smsTemplateText, setSmsTemplateText] = useState([{
    type: 'paragraph',
    children: [
      { text: '' }
    ]
  }])

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    mapboxgl: mapboxgl
  })

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    try {
      if(mapLoaded) {
        if(style === 'bing') {
          if(zoom > 20) {
            setZoom(20)
            map.current.jumpTo({ zoom: 20 })
          }
          if(!map.current.getSource('tiles')) {
            // map.addSource('tiles', {
            //   'type': 'raster',
            //   'tiles': ['https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/WMTS/tile/1.0.0/USGSImageryOnly/default/GoogleMapsCompatible/{z}/{y}/{x}']
            // })
            map.current.addSource('tiles', {
              type: 'raster',
              attribution: bingTiles.copyright,
              tiles: bingTiles.tiles
            })
            map.current.addLayer({
              'id': 'tiles',
              'type': 'raster',
              'source': 'tiles',
              'minzoom': 0,
              'maxzoom': 24
            }, 'gl-draw-polygon-fill-inactive.cold')
          }
        } else if(style === 'mapbox') {
          if(map.current.getLayer('tiles')) map.current.removeLayer('tiles')
          if(map.current.getSource('tiles')) map.current.removeSource('tiles')
        }
      }
    } catch(err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [style, mapLoaded])

  useEffect(() => {
    try {
      if(mapLoaded && baselines) {
        map.current.getSource('baselines').setData(baselines)
      }
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaded, baselines])

  useEffect(() => {
    try {
      if(mapLoaded && panels.current) {
        map.current.getSource('panels').setData(panels.current)
        updateSize()
      }
    } catch (e) {
      console.log(e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapLoaded, panels.current])

  useEffect(() => {
    async function initMap() {
      const url = 'https://dev.virtualearth.net/REST/V1/Imagery/Metadata/Aerial?output=json&include=ImageryProviders&key='+process.env.REACT_APP_BING_API
      const tiles = await fetch(url)
      const tilesJSON = await tiles.json()
      const bTiles = {
        copyright: tilesJSON.copyright,
        tiles: []
      }
      tilesJSON.resourceSets[0].resources[0].imageUrlSubdomains.forEach(domain => {
        bTiles.tiles.push(tilesJSON.resourceSets[0].resources[0].imageUrl.replace('{subdomain}', domain))
      })

      if(mounted.current) {
        setBingTiles(bTiles)

        const address = getAddress(contact, false)

        const response = await fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/'+address+'.json?access_token='+mapboxgl.accessToken)
        const responseJSON = await response.json()

        const center = address ? responseJSON.features[0] ? responseJSON.features[0].center : [-94.5056403, 38.9015672] : [-94.5786, 39.0997]
        const mapZoom = address ? 18 : 10
        setZoom(mapZoom)
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: 'mapbox://styles/mapbox/satellite-streets-v11',
          center: center,
          zoom: mapZoom,
          scrollZoom: false,
          maxZoom: 24,
          attributionControl: false,
          preserveDrawingBuffer: true
        })
        map.current.addControl(geocoder, 'top-right')
        // map.current.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right')

        draw.current = new MapboxDraw({
          displayControlsDefault: false
        })

        map.current.addControl(draw.current, 'top-right')

        map.current.on('load', (e) => {
          map.current.addSource('baselines', { type: 'geojson', data: baselines })
          map.current.addLayer({
            'id': 'baselines-stroke',
            'type': 'line',
            'source': 'baselines',
            'paint': {
              'line-color': 'red',
              'line-opacity': 1,
              'line-width': 2
            }
          })
          map.current.addSource('panels', { type: 'geojson', data: panels.current, promoteId: 'id' })
          map.current.addLayer({
            'id': 'panels-fill',
            'type': 'fill',
            'source': 'panels',
            'paint': {
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'hidden'], false],
                'transparent',
                'black'
              ],
              'fill-outline-color': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                'rgb(255, 0, 0)',
                [
                  'case',
                  ['boolean', ['feature-state', 'hidden'], false],
                  'transparent',
                  'rgb(40, 40, 40)'
                ]
              ]
            }
          })

          let hoveredStateId = null

          map.current.on('mousemove', 'panels-fill', (e) => {
            if(e.features.length > 0) {
              if(hoveredStateId !== null) {
                map.current.setFeatureState(
                  { source: 'panels', id: hoveredStateId },
                  { hover: false }
                )
              }
              hoveredStateId = e.features[0].id
              map.current.setFeatureState(
                { source: 'panels', id: hoveredStateId },
                { hover: true }
              )
            }
          })

          map.current.on('mouseleave', 'panels-fill', () => {
            if(hoveredStateId !== null) {
              map.current.setFeatureState(
                { source: 'panels', id: hoveredStateId },
                { hover: false }
              )
            }
            hoveredStateId = null
          })

          map.current.on('click', 'panels-fill', (e) => {
            if(e.features.length > 0) {
              if(hoveredStateId !== null) {
                e.features.forEach(feature => {
                  if(feature.id === hoveredStateId) {
                    if(feature.state.hidden) {
                      map.current.setFeatureState(
                        { source: 'panels', id: hoveredStateId },
                        { hidden: false }
                      )
                    } else {
                      map.current.setFeatureState(
                        { source: 'panels', id: hoveredStateId },
                        { hidden: true }
                      )
                    }
                  }
                })
              }
              updateSize()
            }
          })

          setMapLoaded(true)
        })

        map.current.on('draw.create', (e) => {
          e.features.forEach(feature => {
            const bbox = turf.bbox(feature)
            const bboxPolygon = turf.bboxPolygon(bbox)
            const polygon = turf.intersect(bboxPolygon, feature)
            const point1 = turf.point(polygon.geometry.coordinates[0][0])
            const point2 = turf.point(polygon.geometry.coordinates[0][1])
            const bearing = turf.bearing(point1, point2)
            const numBaselines = polygon.geometry.coordinates[0][0] === polygon.geometry.coordinates[0][polygon.geometry.coordinates[0].length - 1] ?
              polygon.geometry.coordinates[0].length - 2 :
              polygon.geometry.coordinates[0].length - 1
            let longestBaseline = 0
            for(let i = 0; i < numBaselines; i++) {
              const line = turf.lineString([polygon.geometry.coordinates[0][i], polygon.geometry.coordinates[0][i + 1]])
              const length = turf.length(line, { units: 'miles' })
              if(longestBaseline < length) longestBaseline = length
            }
            draw.current.setFeatureProperty(feature.id, 'currentBaseline', 0)
            draw.current.setFeatureProperty(feature.id, 'numBaselines', numBaselines)
            draw.current.setFeatureProperty(feature.id, 'baselineBearing', bearing)
            draw.current.setFeatureProperty(feature.id, 'orientation', 0)
            draw.current.setFeatureProperty(feature.id, 'longestBaseline', longestBaseline)
          })
          drawPanels()
        })
        map.current.on('draw.delete', (e) => {
          drawPanels()
        })
        map.current.on('draw.update', (e) => {
          e.features.forEach(feature => {
            const bbox = turf.bbox(feature)
            const bboxPolygon = turf.bboxPolygon(bbox)
            const polygon = turf.intersect(bboxPolygon, feature)
            const point1 = turf.point(polygon.geometry.coordinates[0][feature.properties.currentBaseline])
            const point2 = turf.point(polygon.geometry.coordinates[0][feature.properties.currentBaseline + 1])
            const bearing = turf.bearing(point1, point2)
            const numBaselines = polygon.geometry.coordinates[0][0] === polygon.geometry.coordinates[0][polygon.geometry.coordinates[0].length - 1] ?
              polygon.geometry.coordinates[0].length - 2 :
              polygon.geometry.coordinates[0].length - 1
            let longestBaseline = 0
            for(let i = 0; i < numBaselines; i++) {
              const line = turf.lineString([polygon.geometry.coordinates[0][i], polygon.geometry.coordinates[0][i + 1]])
              const length = turf.length(line, { units: 'miles' })
              if(longestBaseline < length) longestBaseline = length
            }
            draw.current.setFeatureProperty(feature.id, 'numBaselines', numBaselines)
            draw.current.setFeatureProperty(feature.id, 'baselineBearing', bearing)
            draw.current.setFeatureProperty(feature.id, 'longestBaseline', longestBaseline)
          })
          drawPanels()
        })

        new mapboxgl.Marker({ color: '#ff5440' })
        .setLngLat(center)
        .addTo(map.current)
      }
    }
    initMap()


    return () => {
      // setArea(0)
      // setMapLoaded(false)
      if(map.current) {
        // map.current.remove()
        // map.current = null
        // draw.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function updateSize() {
    setTimeout(() => {
      let num = 0
      setSize(panels.current.features.reduce((prev, current, index) => {
        const state = map.current.getFeatureState({ id: current.properties.id, source: 'panels' })
        if(state && state.hidden) return prev
        else {
          num++
          return prev + basePanel.wattage
        }
      }, 0))
      setNumPanels(num)
    }, 200)
  }

  function drawPanels() {
    const data = draw.current.getAll()

    // update area
    if(data.features.length > 0) {
      const area = convertArea(turf.area(data), 'meters', 'feet')
      const rounded_area = Math.round(area * 100) / 100
      setArea(rounded_area)
    } else {
      setArea(0)
    }

    const newPlanes = []
    const newBaselines = []
    const newPanels = []

    for(let feature of data.features) {
      const bbox = turf.bbox(feature)
      const bboxPolygon = turf.bboxPolygon(bbox)
      const polygon = turf.intersect(bboxPolygon, feature)

      // update planes
      newPlanes.push({
        ...feature,
        properties: {
          id: feature.id,
          ...feature.properties
        }
      })

      // update baselines
      const currentBaseline = feature.properties.currentBaseline
      const baselineCoords = polygon.geometry.coordinates[0]
      newBaselines.push(turf.lineString([baselineCoords[currentBaseline], baselineCoords[currentBaseline + 1]]))

      // update panels
      const width = feature.properties.orientation === 0 ? turf.convertLength(basePanel.width.inches, 'inches', 'miles') : turf.convertLength(basePanel.height.inches, 'inches', 'miles')
      const height = feature.properties.orientation === 0 ? turf.convertLength(basePanel.height.inches, 'inches', 'miles') : turf.convertLength(basePanel.width.inches, 'inches', 'miles')

      const gutterX = feature.properties.orientation === 0 ? turf.convertLength(basePanel.width.gutter, 'inches', 'miles') : turf.convertLength(basePanel.height.gutter, 'inches', 'miles')
      const gutterY = feature.properties.orientation === 0 ? turf.convertLength(basePanel.height.gutter, 'inches', 'miles') : turf.convertLength(basePanel.width.gutter, 'inches', 'miles')

      const bearingX = feature.properties.baselineBearing < 0 ? feature.properties.baselineBearing + 180 : feature.properties.baselineBearing
      const bearingY = (bearingX + 90 > 180) ? (bearingX + 90) - 360 : bearingX + 90

      const bearingXopp = bearingX < 0 ? bearingX + 180 : bearingX - 180
      const bearingYopp = bearingY < 0 ? bearingY + 180 : bearingY - 180

      const startCoords = baselineCoords[currentBaseline]
      let origin = turf.point(turf.destination(startCoords, gutterY, bearingY, { units: 'miles' }).geometry.coordinates)
      let continueX = true
      let continueY = true
      let row = 0
      let col = 0

      while(continueY) {
        const testPoint1 = turf.point(turf.destination(origin, feature.properties.longestBaseline, bearingX, { units: 'miles' }).geometry.coordinates)
        const testPoint2 = turf.point(turf.destination(origin, feature.properties.longestBaseline, bearingXopp, { units: 'miles' }).geometry.coordinates)
        const testPoly = turf.polygon([[
          testPoint1.geometry.coordinates,
          testPoint2.geometry.coordinates,
          turf.destination(testPoint2, height * 2, bearingY, { units: 'miles' }).geometry.coordinates,
          turf.destination(testPoint1, height * 2, bearingY, { units: 'miles' }).geometry.coordinates,
          testPoint1.geometry.coordinates
        ]])
        // newPanels.push(testPoly)

        continueX = true
        continueY = false
        col = 0
        while(continueX) {
          const topLeft = turf.point(turf.destination(origin, (col * width) + ((col + 1) * gutterX), bearingX, { units: 'miles' }).geometry.coordinates)
          const topRight = turf.point(turf.destination(topLeft, width, bearingX, { units: 'miles' }).geometry.coordinates)
          const bottomRight = turf.point(turf.destination(topRight, height, bearingY, { units: 'miles' }).geometry.coordinates)
          const bottomLeft = turf.point(turf.destination(topLeft, height, bearingY, { units: 'miles' }).geometry.coordinates)

          const panel = turf.polygon([[topLeft.geometry.coordinates, topRight.geometry.coordinates, bottomRight.geometry.coordinates, bottomLeft.geometry.coordinates, topLeft.geometry.coordinates]], { plane: 'plane-1-' + feature.id, id: uuidv4() })

          col++
          if(turf.booleanContains(feature, panel)) {
            newPanels.push(panel)
            continueY = true
          }
          if(!turf.booleanIntersects(testPoly, panel)) {
            continueX = false
          }
        }

        continueX = true
        col = 0

        while(continueX) {
          const topLeft = turf.point(turf.destination(origin, (col * width) + ((col + 1) * gutterX), bearingXopp, { units: 'miles' }).geometry.coordinates)
          const topRight = turf.point(turf.destination(topLeft, width, bearingXopp, { units: 'miles' }).geometry.coordinates)
          const bottomRight = turf.point(turf.destination(topRight, height, bearingY, { units: 'miles' }).geometry.coordinates)
          const bottomLeft = turf.point(turf.destination(topLeft, height, bearingY, { units: 'miles' }).geometry.coordinates)

          const panel = turf.polygon([[topLeft.geometry.coordinates, topRight.geometry.coordinates, bottomRight.geometry.coordinates, bottomLeft.geometry.coordinates, topLeft.geometry.coordinates]], { plane: 'plane-1-opp-' + feature.id, id: uuidv4() })

          col++
          if(turf.booleanContains(feature, panel)) {
            newPanels.push(panel)
            continueY = true
          }
          if(!turf.booleanIntersects(testPoly, panel)) {
            continueX = false
          }
        }

        if(continueY) {
          row++
          origin = turf.point(turf.destination(startCoords, (row * height) + ((row + 1) * gutterY), bearingY, { units: 'miles' }).geometry.coordinates)
        }
      }

      origin = turf.point(turf.destination(startCoords, gutterY, bearingYopp, { units: 'miles' }).geometry.coordinates)
      continueY = continueX = true
      row = col = 0

      while(continueY) {
        const testPoint1 = turf.point(turf.destination(origin, feature.properties.longestBaseline, bearingX, { units: 'miles' }).geometry.coordinates)
        const testPoint2 = turf.point(turf.destination(origin, feature.properties.longestBaseline, bearingXopp, { units: 'miles' }).geometry.coordinates)
        const testPoly = turf.polygon([[
          testPoint1.geometry.coordinates,
          testPoint2.geometry.coordinates,
          turf.destination(testPoint2, height * 2, bearingYopp, { units: 'miles' }).geometry.coordinates,
          turf.destination(testPoint1, height * 2, bearingYopp, { units: 'miles' }).geometry.coordinates,
          testPoint1.geometry.coordinates
        ]])
        // newPanels.push(testPoly)

        continueX = true
        continueY = false
        col = 0

        while(continueX) {
          const topLeft = turf.point(turf.destination(origin, (col * width) + ((col + 1) * gutterX), bearingX, { units: 'miles' }).geometry.coordinates)
          const topRight = turf.point(turf.destination(topLeft, width, bearingX, { units: 'miles' }).geometry.coordinates)
          const bottomRight = turf.point(turf.destination(topRight, height, bearingYopp, { units: 'miles' }).geometry.coordinates)
          const bottomLeft = turf.point(turf.destination(topLeft, height, bearingYopp, { units: 'miles' }).geometry.coordinates)

          const panel = turf.polygon([[topLeft.geometry.coordinates, topRight.geometry.coordinates, bottomRight.geometry.coordinates, bottomLeft.geometry.coordinates, topLeft.geometry.coordinates]], { plane: 'plane-2-' + feature.id, id: uuidv4() })

          col++
          if(turf.booleanContains(feature, panel)) {
            newPanels.push(panel)
            continueY = true
          }
          if(!turf.booleanIntersects(testPoly, panel)) {
            continueX = false
          }
        }

        continueX = true
        col = 0

        while(continueX) {
          const topLeft = turf.point(turf.destination(origin, (col * width) + ((col + 1) * gutterX), bearingXopp, { units: 'miles' }).geometry.coordinates)
          const topRight = turf.point(turf.destination(topLeft, width, bearingXopp, { units: 'miles' }).geometry.coordinates)
          const bottomRight = turf.point(turf.destination(topRight, height, bearingYopp, { units: 'miles' }).geometry.coordinates)
          const bottomLeft = turf.point(turf.destination(topLeft, height, bearingYopp, { units: 'miles' }).geometry.coordinates)

          const panel = turf.polygon([[topLeft.geometry.coordinates, topRight.geometry.coordinates, bottomRight.geometry.coordinates, bottomLeft.geometry.coordinates, topLeft.geometry.coordinates]], { plane: 'plane-2-opp-' + feature.id, id: uuidv4() })

          col++
          if(turf.booleanContains(feature, panel)) {
            newPanels.push(panel)
            continueY = true
          }
          if(!turf.booleanIntersects(testPoly, panel)) {
            continueX = false
          }
        }

        if(continueY) {
          row++
          origin = turf.point(turf.destination(startCoords, (row * height) + ((row + 1) * gutterY), bearingYopp, { units: 'miles' }).geometry.coordinates)
        }
      }
    }

    setPlanes(turf.featureCollection(newPlanes))
    // setPanels(turf.featureCollection(newPanels))
    panels.current = turf.featureCollection(newPanels)
    setBaselines(turf.featureCollection(newBaselines))
  }

  return (
    <div className='h-full w-full flex flex-col'>
      <div className='flex justify-between'>
        <div className='flex space-x-2 ml-32 h-6 flex items-center'>
          {planes.features.length < 1 &&
            <p className='h-full text-black-500 text-xs flex items-center'>There are no planes.</p>
          }
          {planes.features.length > 0 && planes.features.map((plane, index) =>
            <div key={'plane-edit-' + index}>
              <Dropdown>
                <span trigger='true' className='text-black bg-primary h-6 w-6 rounded-full flex hover:bg-primary hover:text-black transition duration-200'>
                  <span className='m-auto block text-xs'>{index + 1}</span>
                </span>
                <ul content='true' className='p-4 space-y-2 mt-2 bg-black-850 rounded-lg filter drop-shadow-lg'>
                  <li>
                    <button
                      className='whitespace-nowrap w-full font-bold px-6 pb-2 pt-1.5 bg-black-800 text-primary rounded-full hover:bg-black-750 transition duration-200'
                      onClick={() => {
                        planes.features.forEach(p => {
                          if(p.properties.id === plane.properties.id) {
                            const bbox = turf.bbox(p)
                            const bboxPolygon = turf.bboxPolygon(bbox)
                            const polygon = turf.intersect(bboxPolygon, p)
                            const newBaseline = p.properties.currentBaseline < p.properties.numBaselines - 1 ? p.properties.currentBaseline + 1 : 0
                            const point1 = turf.point(polygon.geometry.coordinates[0][newBaseline])
                            const point2 = turf.point(polygon.geometry.coordinates[0][newBaseline + 1])
                            const bearing = turf.bearing(point1, point2)
                            draw.current.setFeatureProperty(p.id, 'currentBaseline', newBaseline)
                            draw.current.setFeatureProperty(p.id, 'baselineBearing', bearing)
                            return {
                              ...p,
                              properties: {
                                ...p.properties,
                                currentBaseline: newBaseline,
                                baselineBearing: bearing
                              }
                            }
                          } else {
                            return p
                          }
                        })
                        drawPanels()
                      }}>
                      Change Base
                    </button>
                  </li>
                  <li>
                    <button
                      className='whitespace-nowrap w-full font-semibold px-6 pb-2 pt-1.5 bg-black-800 text-primary rounded-full hover:bg-black-750 transition duration-200'
                      onClick={() => {
                        planes.features.forEach(p => {
                          if(p.properties.id === plane.properties.id) {
                            const newOrientation = p.properties.orientation === 0 ? 1 : 0
                            draw.current.setFeatureProperty(p.id, 'orientation', newOrientation)
                            return {
                              ...p,
                              properties: {
                                ...p.properties,
                                orientation: newOrientation
                              }
                            }
                          } else {
                            return p
                          }
                        })
                        drawPanels()
                      }}>
                      Rotate Panels
                    </button>
                  </li>
                </ul>
              </Dropdown>
            </div>
          )}
        </div>
        <div className='flex gap-2 items-center pb-3'>
          <div className='flex rounded-full overflow-hidden'>
            <button
              className={'text-xs px-4 h-6 ' + (style === 'bing' ? 'bg-primary text-black' : 'bg-black-775 hover:bg-black-750 text-black-100 hover:text-white')}
              onClick={() => { if(style !== 'bing') setStyle('bing') }}>
              Bing
            </button>
            <button
              className={'text-xs px-4 h-6 ' + (style === 'mapbox' ? 'bg-primary text-black' : 'bg-black-775 hover:bg-black-750 text-black-100 hover:text-white')}
              onClick={() => { if(style !== 'mapbox') setStyle('mapbox') }}>
              Mapbox
            </button>
          </div>
        </div>
      </div>

      <div className='flex-1 relative' id='mapShot'>
        <ul className='absolute bottom-0 right-0 m-2 z-10 space-y-1'>
          <li>
            <button
              className='h-7 w-7 rounded-full flex items-center justify-center transition duration-200 bg-black-850 hover:bg-black-800'
              onClick={() => {
                const newZoom = map.current.getZoom() + .5
                if(newZoom < (style === 'bing' ? 20 : 24)) {
                  map.current.flyTo({ zoom: newZoom })
                  setZoom(newZoom)
                }
              }}>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2.5} d='M12 6v6m0 0v6m0-6h6m-6 0H6' />
              </svg>
            </button>
          </li>
          <li>
            <button
              className='mb-4 h-7 w-7 rounded-full flex items-center justify-center bg-black-850 hover:bg-black-800'
              onClick={() => {
                const newZoom = map.current.getZoom() - .5
                if(newZoom > 0) {
                  map.current.flyTo({ zoom: newZoom })
                  setZoom(newZoom)
                }
                if(map.current.getZoom() <= 16) {
                  draw.current.changeMode('simple_select')
                  setDrawMode('select')
                }
              }}>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2.5} d='M18 12H6' />
              </svg>
            </button>
          </li>
          <li>
            <button
              className={'h-7 w-7 rounded-full flex items-center justify-center ' + (zoom < 16 ? 'bg-black-850 text-black-500' : drawMode === 'polygon' ? 'bg-primary text-black' : 'bg-black-850 hover:bg-black-800')}
              onClick={() => {
                if(map.current.getZoom() >= 16) {
                  draw.current.changeMode('draw_polygon')
                  setDrawMode('polygon')
                }
              }}>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4' viewBox='0 0 20 20' fill='currentColor'>
                <path d='M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z' />
              </svg>
            </button>
          </li>
          <li>
            <button
              className={'h-7 w-7 rounded-full flex items-center justify-center ' + (drawMode === 'select' ? 'bg-primary text-black' : 'bg-black-850 hover:bg-black-800')}
              onClick={() => {
                draw.current.changeMode('simple_select')
                setDrawMode('select')
              }}>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4' viewBox='0 0 20 20' fill='currentColor'>
                <path fillRule='evenodd' d='M9 3a1 1 0 012 0v5.5a.5.5 0 001 0V4a1 1 0 112 0v4.5a.5.5 0 001 0V6a1 1 0 112 0v5a7 7 0 11-14 0V9a1 1 0 012 0v2.5a.5.5 0 001 0V4a1 1 0 012 0v4.5a.5.5 0 001 0V3z' clipRule='evenodd' />
              </svg>
            </button>
          </li>
          <li>
            <button
              className={'h-7 w-7 rounded-full flex items-center justify-center ' + (drawMode === 'trash' ? 'bg-primary text-black' : 'bg-black-850 hover:bg-black-800')}
              onClick={() => {
                draw.current.trash()
              }}>
              <svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4' viewBox='0 0 20 20' fill='currentColor'>
                <path fillRule='evenodd' d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z' clipRule='evenodd' />
              </svg>
            </button>
          </li>
        </ul>
        <div ref={mapContainer} id='map' className='h-full w-full rounded-xl' />
      </div>

      <div className='flex items-center text-xs py-3 leading-none'>
        <p className='flex-1 text-center'><span className='text-black-400'>Area &mdash; </span>{(+(Math.round(+(area + 'e' + 2)) + 'e' + -2)).toFixed(2)} ft<sup>2</sup></p>
        <p className='flex-1 text-center'><span className='text-black-400'>Panels &mdash; </span>{numPanels}</p>
        <p className='flex-1 text-center'><span className='text-black-400'>Size &mdash; </span>{(+(Math.round(+(size/1000 + 'e' + 2)) + 'e' + -2)).toFixed(2)} kWh</p>
      </div>
    </div>
  )
}

import { useState, useRef, useEffect } from 'react'

export default function Index({ users, user, setUser, metric }) {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(false)

  useEffect(() => {
    if(users) {
      setData(users.sort((a,b) => a.label.localeCompare(b.label)))
    }
  }, [users])

  useEffect(() => {
    if(users) {
      if(search && search !== '') {
        console.log(search)
        setData(users.filter(d => d.label.toLowerCase().includes(search.toLowerCase())).sort((a,b) => a.label.localeCompare(b.label)))
      } else {
        setData(users.sort((a,b) => a.label.localeCompare(b.label)))
      }
    }
  }, [search])

  return (
    <div className='h-full w-full overflow-scroll relative'>
      {/*<p className='text-black-400 p-2'>Users &mdash; {users && users.length.toLocaleString()}</p>*/}
      <div className='z-10 p-2 pb-0 backdrop-filter backdrop-blur-md sticky top-0'>
        <input
          className='font-mono p-2 placeholder-black-500 rounded-lg bg-black-825 w-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search users'
        />
      </div>
      <ul className={'pb-6 pt-6 px-1 z-0 transition duration-200 ' + (metric && (metric.dataType === 'contacts' || metric.dataType === 'deals' || metric.dataType === 'proposals') ? ' opacity-20 pointer-events-none' : ' ')}>
        {data && data.map((metric, index) =>
          <li className='fade-in' key={metric.label + '-' + index}>
            <button
              onClick={() => {
                let hasMetric = user && user.find(s => s.label === metric.label)
                if(user) {
                  if(hasMetric) {
                    setUser(prev => prev.filter(s => s.label !== metric.label))
                  } else {
                    setUser(prev => ([
                      ...prev,
                      {
                        label: metric.label,
                        value: metric.value
                      }
                    ]))
                  }
                } else {
                  setUser([{
                    label: metric.label,
                    value: metric.value
                  }])
                }
              }}
              className='truncate overflow-hidden flex gap-3 items-center w-full px-2 py-2 text-left hover:bg-black-825 rounded-full'>
              <span className={'h-1.5 w-1.5 rounded-full ' + (user && user.find(s => s.label === metric.label) ? 'bg-primary' : 'bg-black-725') } />
              <span className='-mt-px block flex-1 truncate overflow-hidden'>{metric.label}</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  )
}

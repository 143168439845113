import { useState, useEffect, useRef } from 'react'

import { Link, useLocation } from 'react-router-dom'

import { EyeIcon, CubeIcon, GlobeAmericasIcon, QrCodeIcon, CommandLineIcon, ComputerDesktopIcon, RocketLaunchIcon, ChartBarIcon, MapPinIcon, BookOpenIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'

const nodes = [
  {
    name: 'Insights',
    href: '/insights',
    icon: <EyeIcon className='h-5 w-5 m-auto' />,
    active: 'text-purple-500'
  },
  {
    name: 'Reports',
    href: '/reports',
    icon: <ChartBarIcon className='h-5 w-5 m-auto' />,
    active: 'text-purple-500'
  },
  {
    name: 'Customers',
    href: '/customers',
    icon: <GlobeAmericasIcon className='h-5 w-5 m-auto' />,
    active: 'text-purple-500'
  },
  {
    name: 'Website',
    href: '/website',
    icon: <ComputerDesktopIcon className='h-5 w-5 m-auto' />,
    active: 'text-purple-500'
  },
  {
    name: 'AI Proposals',
    href: '/proposals',
    icon: <CommandLineIcon className='h-5 w-5 m-auto' />,
    active: 'text-purple-500'
  },
  {
    name: 'Short links',
    href: '/short-links',
    icon: <QrCodeIcon className='h-5 w-5 m-auto' />,
    active: 'text-purple-500'
  },
  {
    name: 'Contacts',
    href: '/contacts',
    icon: <BookOpenIcon className='h-5 w-5 m-auto' />,
    active: 'text-primary'
  },
  {
    name: 'Users',
    href: '/users',
    icon: <UserGroupIcon className='h-5 w-5 m-auto' />,
    active: 'text-purple-500'
  }
]

export default function Index() {
  const location = useLocation()

  const [show, setShow] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref} className='relative overflow-visible'>
      <button
        onClick={() => setShow(!show)}
        className='group bg-black-825 flex h-10 w-10 transition duration-200 rounded-full hover:bg-black-800'>
        <EllipsisVerticalIcon className='transition stroke-primary fill-primary duration-200 group-hover:scale-125 h-5 w-5 m-auto' strokeWidth={2.5} />
      </button>

      {show &&
        <div className='fade-up text-center absolute w-[18em] px-2 py-4 z-50 bg-black-825 drop-shadow-lg right-0 mt-2 top-full rounded-lg'>
          <Link to='/' className={'mb-4 inline-block px-4 py-1.5 bg-primary text-black-900 transition duration-200 hover:brightness-110 rounded-full text-sm font-bold'} onClick={() => setShow(false)}>
            <span>Dashboard</span>
          </Link>
          <ul className='grid grid-cols-3 gap-2'>
            {nodes.map(node => {
              // const base = matchPath(location.pathname, {
              //   path: node.href
              // })
              const base = false
              // const match = matchPath(location.pathname, {
              //   path: '/contacts/:id'
              // })
              const match = false
              return (
                <li key={node.name}>
                  <Link to={node.href} className={'whitespace-nowrap leading-none group w-full aspect-1-1 rounded-lg text-xs flex flex-col gap-3 text-left items-center justify-center relative border ' + ((location.pathname === node.href || (base && match && match.params.id)) ? 'text-primary border-primary bg-black-775' : 'hover:bg-black-775 border-transparent')} onClick={() => setShow(false)}>
                    <span className='text-primary'>{node.icon}</span>
                    <span>{node.name}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      }
    </div>
  )
}

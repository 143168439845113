import { useState, useRef, useContext } from 'react'
import { createPortal } from 'react-dom'

import { Context } from 'store/index'

export default function Index({ setShowMenu }) {
  const [state, dispatch] = useContext(Context)

  const [show, setShow] = useState(false)

  return (
    <div className='h-full w-full'>
      <p className='text-xs font-mono uppercase text-black-400 p-2'>
        Help
      </p>
      <ul className='py-8'>
        <li>
          <button
            onClick={() => {
              setShow('support')
            }}
            className='text-left px-2 py-3 space-x-4 flex items-center w-full hover:bg-black-750'>
            <svg xmlns='http://www.w3.org/2000/svg' className='text-primary h-5 w-5 mt-1' viewBox='0 0 20 20' fill='currentColor'>
              <path d='M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z' />
              <path d='M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z' />
            </svg>
            <span className='flex-1 text-sm'>Message support</span>
          </button>
        </li>
        {/*
        <li>
          <Link to={'/'} className='px-2 py-3 space-x-4 flex items-center w-full hover:bg-black-825'>
            <svg xmlns='http://www.w3.org/2000/svg' className='text-black-500 h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
              <path fillRule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z' clipRule='evenodd' />
            </svg>
            <span className='flex-1 text-sm'>Tutorials</span>
          </Link>
        </li>
        */}
      </ul>
      {show === 'support' && <SupportModal setShow={setShow} setShowMenu={setShowMenu} />}
    </div>
  )
}

function SupportModal({ setShow, setShowMenu }) {
  const [state, dispatch] = useContext(Context)

  const [messageBody, setMessageBody] = useState('')

  async function sendMail(to, from, text) {
    dispatch({ type: 'toast-set', payload: 'Sending your message to support.' })
    await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/firebaseSendMail', {
      method: 'POST',
      body: JSON.stringify({
        to: to,
        from: from,
        subject: 'Sundial — Support Request',
        text: text
      })
    })
    // const data = await response.json()
    dispatch({ type: 'toast-set', payload: false })
    setTimeout(() => {
      dispatch({ type: 'toast-set', payload: 'Your message has been sent!' })
    }, 400)
  }

  return createPortal(
    <div className='fade-in inset-0 flex fixed bg-black z-50 py-10vh overflow-scroll'>
      <div
        onClick={() => {
          setShow(false)
          setShowMenu(false)
        }}
        className='w-full h-full absolute top-0 left-0 z-10' />
      <div className='py-3 m-auto relative z-20 bg-black-850 rounded-2xl w-full max-w-sm'>
        <h2 className='px-2 pb-8 font-mono uppercase text-sm'>Message Support</h2>
        <textarea
          value={messageBody}
          className='text-sm placeholder-black-300 my-6 focus:ring-2 focus:ring-primary block h-44 rounded-2xl bg-black-800 resize-none appearane-none w-full focus:outline-none p-3'
          onChange={(e) => setMessageBody(e.target.value)}
          placeholder='Write a message here.'
        />
        <button
          onClick={() => {
            setShow(false)
            setShowMenu(false)
            sendMail('mdreier@risingsun.solar', state.profile.name + '<' + state.profile.email + '>', messageBody)
          }}
          disabled={messageBody === ''}
          className={'block py-1.5 font-bold px-10 rounded-full mx-auto ' + (messageBody === '' ? 'bg-black-800 text-black-300' : 'bg-primary hover:bg-primary text-black')}>
          Send
        </button>
      </div>
    </div>, document.body
  )
}

import { useState } from 'react'

import Profile from './components/profile'

const sections = [
  {
    name: 'Profile',
    component: <Profile />
  }
]

export default function Index() {
  const [show, setShow] = useState(sections[0])

  return (
    <div data-aos='fade-in' className='px-3 py-16 w-full h-screen'>
      <div className='bg-black-875 rounded-2xl w-full h-full flex'>
        <div className='w-1/4 bg-black-825 rounded-2xl h-full px-2 py-3 flex flex-col items-start justify-between space-y-6'>
          <p className='mb-10vh font-mono uppercase text-sm'>Settings</p>
          <ul className='space-y-1.5'>
            {sections.map(section =>
              <li key={section.name}>
                <button
                  className={'flex gap-3 items-center text-left px-3 pr-6 py-1 text-sm rounded-full bg-black-775 hover:bg-black-750'}
                  onClick={() => setShow(section)}>
                  <span className={'h-2 w-2 rounded-full ' + (show.name === section.name ? 'bg-primary' : 'bg-black-600')} />
                  <span>{section.name}</span>
                </button>
              </li>
            )}
          </ul>
          </div>
        <div className='relative flex-1 min-w-0 h-full overflow-scroll'>
          {show.component}
       </div>
     </div>
    </div>
  )
}

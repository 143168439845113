import { useState, useRef, useEffect } from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'

import { months } from 'lib/utils'

export default function Index({ month, setMonth, year, quarter, setQuarter }) {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(months)

  useEffect(() => {
    if(search && search !== '') {
      setData(months.filter(d => d.label.toLowerCase().includes(search.toLowerCase())).sort((a,b) => a.value - b.value))
    } else {
      setData(months.sort((a,b) => a.value - b.value))
    }
  }, [search])

  return (
    <div className='h-full w-full overflow-scroll relative'>
      {/*<p className='text-black-400 p-2'>Months &mdash; {months && months.length.toLocaleString()}</p>*/}
      <div className='z-10 p-2 pb-0 backdrop-filter backdrop-blur-md sticky top-0'>
        <input
          className='font-mono p-2 placeholder-black-500 rounded-lg bg-black-825 w-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search months'
        />
      </div>
      <ul className={'z-0 pb-6 pt-3 px-1 transition duration-200 ' + (quarter && quarter.length > 0 ? 'opacity-20 pointer-events-none' : '')}>
        {data.map((metric, index) =>
          <li className='fade-in' key={metric.label + '-' + index}>
            <button
              onClick={() => {
                // let hasMetric = month && month.find(s => s.label === metric.label)
                // if(hasMetric) {
                //   setMonth(false)
                // } else {
                //   setMonth([{
                //     label: metric.label,
                //     value: metric.value
                //   }])
                // }
                let hasMetric = month && month.find(s => s.label === metric.label)
                if(month) {
                  if(hasMetric) {
                    setMonth(prev => prev.filter(s => s.label !== metric.label))
                  } else {
                    setMonth(prev => ([
                      ...prev,
                      {
                        label: metric.label,
                        value: metric.value
                      }
                    ]))
                  }
                } else {
                  setMonth([{
                    label: metric.label,
                    value: metric.value
                  }])
                }
                if(quarter && quarter.length > 0) {
                  setQuarter([])
                }
              }}
              className='truncate overflow-hidden flex gap-3 items-center w-full px-2 py-2 text-left hover:bg-black-825 rounded-full'>
              <span className={'h-1.5 w-1.5 rounded-full ' + (month && month.find(s => s.label === metric.label) ? 'bg-primary' : 'bg-black-725') } />
              <span className='-mt-px block flex-1 truncate overflow-hidden'>{metric.long}</span>
            </button>
          </li>
        )}
      </ul>
      {month && month.length > 0 &&
        <div className='-mx-px fade-in bg-black-850 sticky bottom-0 p-2 flex flex-wrap items-end gap-1 overflow-hidden'>
          {month.map(o => <button key={o.label} onClick={() => {
            setMonth(prev => prev.filter(p => p.label !== o.label))
            if(quarter && quarter.length > 0) {
              setQuarter([])
            }
          }} className='max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>)}
        </div>
      }
    </div>
  )
}

import { useEffect, useState, useRef, useContext } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, orderBy, onSnapshot } from 'firebase/firestore'

import { getStorage, ref, getBlob } from 'firebase/storage'

import { Context } from 'store/index'

import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  XAxis,
  YAxis,
  Tooltip
} from 'recharts'

import FilterMetric from './components/filter-metric'
import FilterYear from './components/filter-year'
import FilterMonth from './components/filter-month'
import FilterServiceArea from './components/filter-service-area'
import FilterLeadSource from './components/filter-lead-source'
import FilterUser from './components/filter-user'
import FilterZip from './components/filter-zip'

import { ArrowDownCircleIcon, PresentationChartBarIcon, PresentationChartLineIcon, XMarkIcon } from '@heroicons/react/24/solid'

// import myActivity from './data/activity.json'
// import myCallLog from './data/call-log.json'
// import myContacts from './data/contacts.json'
// import myDeals from './data/deals.json'
// import myProposals from './data/proposals.json'

import { getDaysInMonth, months, yearsReports, colorsReports } from 'lib/utils'

export default function Index() {
  const [state] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp)

  const mounted = useRef(null)
  const usersUnsub = useRef(null)
  const currentSource = useRef(null)

  const [anchor, setAnchor] = useState(false)

  const [htmlDb, setHtmlDb] = useState(false)
  const [data, setData] = useState(false)
  const [selectData, setSelectData] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const [serviceArea, setServiceArea] = useState(false)
  const [leadSource, setLeadSource] = useState(false)
  const [user, setUser] = useState(false)

  const [zips, setZips] = useState(false)
  const [zip, setZip] = useState(false)

  const [year, setYear] = useState(false)
  const [month, setMonth] = useState(false)
  const [users, setUsers] = useState(false)

  const [filteredData, setFilteredData] = useState(false)
  const [graphType, setGraphType] = useState('line')

  const metrics = [
    {
      menuType: 'Leads',
      type: 'total-leads',
      label: 'Lead Acquisition',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalLeads(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: ' leads',
      // testData: myContacts.data,
      tipLabel: 'Total leads',
      longDescription: 'Total number of leads that have been acquired.'
    },
    {
      menuType: 'Leads',
      type: 'total-lead-cost',
      label: 'Lead Cost',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalLeadCost(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: '$',
      // testData: myContacts.data,
      tipLabel: 'Lead cost',
      longDescription: 'Total cost of leads that have been acquired.'
    },
    {
      menuType: 'Leads',
      type: 'average-lead-cost',
      label: 'Lead Avg Cost',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectAverageLeadCost(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: '$',
      // testData: myContacts.data,
      tipLabel: 'Average lead cost',
      longDescription: 'Average cost of leads that have been acquired.'
    },
    {
      menuType: 'Leads',
      type: 'total-conversions',
      label: 'Lead Conversions',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalConversions(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: ' conversions',
      // testData: myContacts.data,
      tipLabel: 'Conversions',
      longDescription: 'Total number of leads that scheduled an appointment.'
    },
    {
      menuType: 'Leads',
      type: 'conversion-rate',
      label: 'Conversion Rate',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectConversionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: '%',
      // testData: myContacts.data,
      tipLabel: 'Conversion rate',
      longDescription: 'Ratio of leads that scheduled an appointment.'
    },
    {
      menuType: 'Leads',
      type: 'average-cost-conversion',
      label: 'Conversion Avg Cost',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectAverageCostConversion(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: '$',
      // testData: myContacts.data,
      tipLabel: 'Average cost per conversion',
      longDescription: 'Average cost per lead to to set an appointment.'
    },
    {
      menuType: 'Leads',
      type: 'first-call-conversions',
      label: 'First Call Conversions',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectFirstCallConversions(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: ' conversions',
      // testData: myContacts.data,
      tipLabel: 'First call conversions',
      longDescription: 'Total leads that scheduled an appointment on first call.'
    },
    {
      menuType: 'Leads',
      type: 'first-call-conversion-rate',
      label: 'First Call Conversion Rate',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectFirstCallConversionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: '%',
      // testData: myContacts.data,
      tipLabel: 'First call conversion rate',
      longDescription: 'Ratio of leads that scheduled an appointment on first call.'
    },
    {
      menuType: 'Leads',
      type: 'average-calls-conversion',
      label: 'Conversion Avg Calls',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectAverageCallsConversion(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/contacts.json',
      dataType: 'contacts',
      dataLabel: ' calls',
      // testData: myContacts.data,
      tipLabel: 'Average calls per conversion',
      longDescription: 'Average number of calls to schedule an appointment.'
    },
    {
      menuType: 'Deals',
      type: 'total-deals',
      label: 'Deals Created',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalDeals(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/deals.json',
      dataType: 'deals',
      dataLabel: ' deals',
      // testData: myDeals.data,
      tipLabel: 'Total deals',
      longDescription: 'Total number of deals that have been created.'
    },
    {
      menuType: 'Deals',
      type: 'total-deals-won',
      label: 'Deals Won',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalDealsWon(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/deals.json',
      dataType: 'deals',
      dataLabel: ' deals',
      // testData: myDeals.data,
      tipLabel: 'Total deals won',
      longDescription: 'Total number of deals that have been closed won.'
    },
    {
      menuType: 'Deals',
      type: 'deals-conversion-rate',
      label: 'Deal Conversion Rate',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectDealConversionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/deals.json',
      dataType: 'deals',
      dataLabel: '%',
      // testData: myDeals.data,
      tipLabel: 'Ratio of deals won',
      longDescription: 'Ratio of all deals that have been closed won.'
    },
    {
      menuType: 'Deals',
      type: 'total-deal-revenue',
      label: 'Deal Revenue',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalDealRevenue(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/deals.json',
      dataType: 'deals',
      dataLabel: '$',
      // testData: myDeals.data,
      tipLabel: 'Total deal revenue',
      longDescription: 'Total revenue earned from all deals closed and won.'
    },
    {
      menuType: 'Deals',
      type: 'average-deal-revenue',
      label: 'Deal Avg Revenue',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectAverageDealRevenue(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/deals.json',
      dataType: 'deals',
      dataLabel: '$',
      // testData: myDeals.data,
      tipLabel: 'Average deal revenue',
      longDescription: 'Average revenue earned from all deals closed and won.'
    },
    {
      menuType: 'Deals',
      type: 'total-deal-system-size',
      label: 'Deal System Size',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalDealSystemSize(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/deals.json',
      dataType: 'deals',
      dataLabel: 'kW',
      // testData: myDeals.data,
      tipLabel: 'Total deal system size',
      longDescription: 'Total size of all systems from deals that have been closed won.'
    },
    {
      menuType: 'Deals',
      type: 'average-deal-system-size',
      label: 'Deal Avg System Size',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectAverageDealSystemSize(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/deals.json',
      dataType: 'deals',
      dataLabel: 'kW',
      // testData: myDeals.data,
      tipLabel: 'Average deal system size',
      longDescription: 'Average size of all systems from deals that have been closed won.'
    },
    {
      menuType: 'AI Tool',
      type: 'proposal-succeess-rate',
      label: 'AI Proposal Success Rate',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectProposalSuccessRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/proposals.json',
      dataType: 'proposals',
      dataLabel: '%',
      // testData: myProposals.data,
      tipLabel: 'Success rate',
      longDescription: 'Ratio of proposals that were successfully created.'
    },
    {
      menuType: 'AI Tool',
      type: 'total-proposals',
      label: 'AI Proposals Submitted',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalProposals(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/proposals.json',
      dataType: 'proposals',
      dataLabel: ' proposals',
      // testData: myProposals.data,
      tipLabel: 'Total proposals',
      longDescription: 'Total number of proposals submitted by the web AI Tool.'
    },
    {
      menuType: 'AI Tool',
      type: 'total-proposal-errors',
      label: 'AI Proposal Errors',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalProposalErrors(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/proposals.json',
      dataType: 'proposals',
      dataLabel: ' proposals',
      // testData: myProposals.data,
      tipLabel: 'Total errors',
      longDescription: 'Total number of proposals that had an error on the web AI Tool.'
    },
    {
      menuType: 'Calls',
      type: 'total-calls',
      label: 'Calls Outbound',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalCalls(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/call-log.json',
      dataType: 'call-log',
      dataLabel: ' calls',
      // testData: myCallLog.data,
      tipLabel: 'Calls',
      longDescription: 'Total number of outbound calls that have been made.'
    },
    {
      menuType: 'Calls',
      type: 'connection-rate',
      label: 'Call Connection Rate',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectConnectionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/activity.json',
      dataType: 'activity',
      dataLabel: '%',
      // testData: myActivity.data,
      tipLabel: 'Connection rate',
      longDescription: 'Ratio of outbound calls that were answered.'
    },
    {
      menuType: 'Calls',
      type: 'total-talk-time',
      label: 'Call Talk Time',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectTotalTalkTime(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/call-log.json',
      dataType: 'call-log',
      dataLabel: ' seconds',
      // testData: myCallLog.data,
      tipLabel: 'Talk time',
      longDescription: 'Total amount of time that has been spent on calls.'
    },
    {
      menuType: 'Calls',
      type: 'average-talk-time',
      label: 'Call Avg Talk Time',
      selectData: (htmlDb, data, zip, serviceArea, leadSource, year, month, user) => selectAverageTalkTime(htmlDb, data, zip, serviceArea, leadSource, year, month, user),
      dataPath: 'reports/call-log.json',
      dataType: 'call-log',
      dataLabel: ' seconds',
      // testData: myCallLog.data,
      tipLabel: 'Average talk time',
      longDescription: 'Average amount of time that is spent on a call.'
    }
  ]

  const [metric, setMetric] = useState(metrics[0])

  useEffect(() => {
    mounted.current = true
    return () => mounted.current = false
  }, [])

  // useEffect(() => {
  //   async function getData() {
  //     const q = query(collection(db, 'users'), orderBy('roles.sundial', 'asc'))
  //     usersUnsub.current = onSnapshot(q, (querySnapshot) => {
  //       if(mounted.current) {
  //         if(!querySnapshot.empty) {
  //           setUsers(querySnapshot.docs.map(doc => ({
  //             value: doc.id,
  //             label: doc.data().name
  //           })))
  //         }
  //       } else {
  //         setUsers(null)
  //       }
  //     })
  //   }
  //
  //   getData()
  //
  //   return () => {
  //     if(usersUnsub.current) usersUnsub.current()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    console.log('set db')
    setHtmlDb(openDatabase('mydb', '1.0', 'SunDial', 5 * 1024 * 1024))

    mounted.current = true

    async function getUsers() {
      const q = query(collection(db, 'users'), orderBy('firstName', 'asc'))
      usersUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) {
            setUsers(querySnapshot.docs.map(doc => ({
              value: doc.id,
              label: doc.data().firstName + ' ' + doc.data().lastName
            })))
          }
        } else {
          setUsers(null)
        }
      })
    }

    getUsers()

    return () => {
      if(usersUnsub.current) usersUnsub.current()
    }
  }, [])

  console.log(users)
  useEffect(() => {
    if(metric && htmlDb) {
      // htmlDb.transaction(tx => {
      //   tx.executeSql('DROP TABLE IF EXISTS DATA')
      // })
      getData()
    }
  }, [metric, htmlDb])

  useEffect(() => {
    if(selectData) {
      console.log('load data')
      console.log(data)

      let keys = []
      if(metric.dataType === 'contacts') {
        console.log('load contacts')
        keys = [
          'id',
          'year',
          'month',
          'day',
          'lead_status',
          'lead_source',
          'lifecycle',
          'call_number',
          'service_area',
          'is_lead',
          'lead_cost',
          'meeting_set',
          'zip'
        ]
      } else if(metric.dataType === 'activity') {
        console.log('load activity')
        keys = [
          'id',
          'year',
          'month',
          'day',
          'lead_source',
          'service_area',
          'uid',
          'disposition',
          'zip'
        ]
      } else if(metric.dataType === 'call-log') {
        console.log('load call logs')
        keys = [
          'id',
          'year',
          'month',
          'day',
          'lead_source',
          'service_area',
          'uid',
          'connect_duration',
          'duration',
          'zip'
        ]
      } else if(metric.dataType === 'deals') {
        console.log('load deals')
        keys = [
          'id',
          'year',
          'month',
          'day',
          'lead_source',
          'service_area',
          'close_year',
          'close_month',
          'close_day',
          'status',
          'revenue',
          'system_size',
          'zip'
        ]
      } else if(metric.dataType === 'proposals') {
        console.log('load proposals')
        keys = [
          'id',
          'year',
          'month',
          'day',
          'error',
          'zip'
        ]
      }

      htmlDb.transaction(tx => {
        tx.executeSql('DROP TABLE IF EXISTS DATA')
      })

      htmlDb.transaction(tx => {
        tx.executeSql('CREATE TABLE IF NOT EXISTS DATA (' + keys.join(', ') + ')')
        data[metric.dataType].forEach((d, i) => {
          const values = []
          keys.forEach(key => {
            values.push(d[key])
          })
          tx.executeSql('INSERT INTO DATA (' + keys.join(', ') + ') VALUES (' + [...Array(keys.length).fill('?')].join(',') + ')', values)
        })
      })

      console.log('data loaded')

      metric.selectData(htmlDb, data, zip, serviceArea, leadSource, year, month, user)
      selectZips()
    }
  }, [selectData])

  function selectZips() {
    console.log('select zips')
    let query = 'SELECT DISTINCT zip FROM DATA'

    if(serviceArea && serviceArea.length > 0) {
      query += serviceArea.map((p, i) => {
        return ' WHERE service_area = "'+p.value+'"'
      }).join(' OR')
    }
    query += ' ORDER BY zip'

    console.log(query)

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('zips')
        console.log('Found rows: ' + len)
        console.log(results.rows.item(0))

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i).zip)
        }
        setZips(update)
      }, null)
    })
  }

  useEffect(() => {
    if(data) {
      metric.selectData(htmlDb, data, zip, serviceArea, leadSource, year, month, user)
      selectZips()
    }
    console.log('serviceArea', serviceArea)
  }, [zip, year, month, serviceArea, leadSource, user])

  async function getData() {
    console.log('get data')
    console.log(metric)


    if(!data) {
      const update = {}

      const sources = [
        'activity',
        'call-log',
        'contacts',
        'deals',
        'proposals'
      ]

      for(let source of sources) {
        const b = await getBlob(ref(storage, 'reports/' + source + '.json'))
        const fr = new FileReader()

        fr.onload = (e) => {
          const data = JSON.parse(e.target.result)
          if(data) {
            console.log('has data')

            update[source] = []

            if(source === 'contacts') {
              console.log('convert contacts')
              data.data.forEach(m => {
                update[source].push({
                  'id': m.id,
                  'year': m.ye,
                  'month': m.mo,
                  'day': m.da,
                  'lead_status': m.st,
                  'lead_source': m.so,
                  'lifecycle': m.cy,
                  'call_number': m.cn,
                  'service_area': m.sa,
                  'is_lead': m.il,
                  'zip': !m.zi || m.zi === undefined || m.zi === '' ? 'undefined' : m.zi,
                  'lead_cost': m.lc,
                  'meeting_set': m.ms
                })
              })
            } else if(source === 'activity') {
              console.log('convert activity')
              data.data.forEach(m => {
                update[source].push({
                  'id': m.id,
                  'year': m.ye,
                  'month': m.mo,
                  'day': m.da,
                  'lead_source': m.so,
                  'service_area': m.sa,
                  'uid': m.ui,
                  'disposition': m.di,
                  'zip': !m.zi || m.zi === undefined || m.zi === '' ? 'undefined' : m.zi,
                })
              })
            } else if(source === 'call-log') {
              console.log('convert call logs')
              data.data.forEach(m => {
                update[source].push({
                  'id': m.id,
                  'year': m.ye,
                  'month': m.mo,
                  'day': m.da,
                  'lead_source': m.so,
                  'service_area': m.sa,
                  'uid': m.ui,
                  'connect_duration': m.cd,
                  'duration': m.du,
                  'zip': !m.zi || m.zi === undefined || m.zi === '' ? 'undefined' : m.zi,
                })
              })
            } else if(source === 'deals') {
              console.log('convert deals')
              data.data.forEach(m => {
                update[source].push({
                  'id': m.id,
                  'year': m.ye,
                  'month': m.mo,
                  'day': m.da,
                  'lead_source': m.so,
                  'service_area': m.sa,
                  'close_year': m.cy,
                  'close_month': m.cm,
                  'close_day': m.cd,
                  'status': m.ds,
                  'revenue': m.re,
                  'system_size': m.ss,
                  'zip': !m.zi || m.zi === undefined || m.zi === '' ? 'undefined' : m.zi,
                })
              })
            } else if(source === 'proposals') {
              console.log('convert proposals')
              data.data.forEach(m => {
                update[source].push({
                  'id': m.id,
                  'year': m.ye,
                  'month': m.mo,
                  'day': m.da,
                  'error': m.er,
                  'zip': !m.zi || m.zi === undefined || m.zi === '' ? 'undefined' : m.zi,
                })
              })
            }
          }
        }

        fr.readAsText(b)
      }


      // if(metric.dataType === 'contacts') {
      //   console.log('convert contacts')
      //   metric.testData.forEach(m => {
      //     update.push({
      //       'id': m.id,
      //       'year': m.ye,
      //       'month': m.mo,
      //       'day': m.da,
      //       'lead_status': m.st,
      //       'lead_source': m.so,
      //       'lifecycle': m.cy,
      //       'call_number': m.cn,
      //       'service_area': m.sa,
      //       'is_lead': m.il,
      //       'lead_cost': m.lc,
      //       'meeting_set': m.ms
      //     })
      //   })
      // } else if(metric.dataType === 'activity') {
      //   console.log('convert activity')
      //   metric.testData.forEach(m => {
      //     update.push({
      //       'id': m.id,
      //       'year': m.ye,
      //       'month': m.mo,
      //       'day': m.da,
      //       'lead_source': m.so,
      //       'service_area': m.sa,
      //       'uid': m.ui,
      //       'disposition': m.di
      //     })
      //   })
      // } else if(metric.dataType === 'call-log') {
      //   console.log('convert call logs')
      //   metric.testData.forEach(m => {
      //     update.push({
      //       'id': m.id,
      //       'year': m.ye,
      //       'month': m.mo,
      //       'day': m.da,
      //       'lead_source': m.so,
      //       'service_area': m.sa,
      //       'uid': m.ui,
      //       'connect_duration': m.cd,
      //       'duration': m.du
      //     })
      //   })
      // } else if(metric.dataType === 'deals') {
      //   console.log('convert deals')
      //   metric.testData.forEach(m => {
      //     update.push({
      //       'id': m.id,
      //       'year': m.ye,
      //       'month': m.mo,
      //       'day': m.da,
      //       'lead_source': m.so,
      //       'service_area': m.sa,
      //       'close_year': m.cy,
      //       'close_month': m.cm,
      //       'close_day': m.cd,
      //       'status': m.ds,
      //       'revenue': m.re,
      //       'system_size': m.ss
      //     })
      //   })
      // } else if(metric.dataType === 'proposals') {
      //   console.log('convert proposals')
      //   metric.testData.forEach(m => {
      //     update.push({
      //       'id': m.id,
      //       'year': m.ye,
      //       'month': m.mo,
      //       'day': m.da,
      //       'error': m.er
      //     })
      //   })
      // }

      setData(update)
    }

    setSelectData(new Date())
  }

  function cleanData(data, dataKeys, year, month) {
    const update = []
    if(!year || year.length === 0) {
      console.log('clean years')
      yearsReports.sort((a,b) => a.value - b.value).forEach(year => {
        const find = data.find(doc => doc.label === year.value)
        if(!find) {
          const data = {}
          dataKeys.forEach(d => {
            data[d.key] = 0
          })
          update.push({
            ...data,
            label: year.label
          })
        } else {
          update.push(find)
        }
      })
    }
    if(year && year.length > 0 && (!month || month.length === 0)) {
      console.log('clean months')
      const today = new Date()
      months.forEach(m => {
        const find = data.find(doc => doc.label === m.value)
        if(!find) {
          const data = {}
          dataKeys.forEach(d => {
            data[d.key] = 0
          })
          update.push({
            ...data,
            label: m.label
          })
        } else {
          update.push({
            ...find,
            label: m.label
          })
        }
      })
    }
    if(year && year.length > 0 && month && month.length > 0) {
      console.log('clean days')
      const today = new Date()
      const days = getDaysInMonth(parseInt(month[0].value), parseInt(year[0].label))
      Array(days).fill().forEach((d, index) => {
        const day = index + 1
        const find = data.find(doc => doc.label === day)
        if(!find) {
          const data = {}
          dataKeys.forEach(d => {
            data[d.key] = 0
          })
          update.push({
            ...data,
            label: day
          })
        } else {
          update.push({
            ...find,
            label: day
          })
        }
      })
    }
    setFilteredData({
      data: update,
      dataKeys: dataKeys
    })
  }

  function selectTotalLeads(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total leads')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE is_lead = "true"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0) && (!zip || zip.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  function selectTotalLeadCost(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total lead cost')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(SUM(CASE WHEN zip = "'+p+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN service_area = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN lead_source = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN uid = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(SUM(CASE WHEN lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as total FROM DATA WHERE is_lead = "true"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectAverageLeadCost(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data average lead cost')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(SUM(CASE WHEN zip = "'+p+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN lead_cost NOT NULL THEN lead_cost ELSE 0 END), 0) as total FROM DATA WHERE is_lead = "true"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectConversionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data conversion rate')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND meeting_set = "true" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND meeting_set = "true" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND meeting_set = "true" THEN 1 ELSE 0 END) * 100, 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND meeting_set = "true" THEN 1 ELSE 0 END) * 100, 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN meeting_set = "true" THEN 1 ELSE 0 END) * 100, 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectAverageCostConversion(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data average cost conversion')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END) / SUM(CASE WHEN meeting_set = "true" THEN 1 ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END) / SUM(CASE WHEN meeting_set = "true" THEN 1 ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END) / SUM(CASE WHEN meeting_set = "true" THEN 1 ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND lead_cost NOT NULL THEN lead_cost ELSE 0 END) / SUM(CASE WHEN meeting_set = "true" THEN 1 ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(SUM(CASE WHEN lead_cost NOT NULL THEN lead_cost ELSE 0 END) / SUM(CASE WHEN meeting_set = "true" THEN 1 ELSE 0 END), 0) as total FROM DATA WHERE is_lead = "true"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectTotalCalls(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total calls')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  function selectConnectionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data connection rate')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND disposition = "Connected" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND disposition = "Connected" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND disposition = "Connected" THEN 1 ELSE 0 END) * 100, 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND disposition = "Connected" THEN 1 ELSE 0 END) * 100, 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN disposition = "Connected" THEN 1 ELSE 0 END) * 100, 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectTotalTalkTime(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total talk time')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(SUM(CASE WHEN zip = "'+p+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN service_area = "'+p.value+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN lead_source = "'+p.value+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN uid = "'+p.value+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(SUM(CASE WHEN connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectAverageTalkTime(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data average talk time')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN connect_duration NOT NULL THEN connect_duration ELSE 0 END), 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectTotalConversions(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total conversions')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE meeting_set = "true"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  function selectFirstCallConversions(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total conversions')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE meeting_set = "true" AND call_number = 1'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  function selectFirstCallConversionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data first call conversion rate')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND meeting_set = "true" AND call_number = 1 THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND meeting_set = "true" AND call_number = 1 THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND meeting_set = "true" AND call_number = 1 THEN 1 ELSE 0 END) * 100, 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND meeting_set = "true" AND call_number = 1 THEN 1 ELSE 0 END) * 100, 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN meeting_set = "true" AND call_number = 1 THEN 1 ELSE 0 END) * 100, 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectAverageCallsConversion(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data average calls conversion')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(SUM(CASE WHEN zip = "'+p+'" AND call_number NOT NULL THEN call_number ELSE 0 END) / SUM(CASE WHEN zip = "'+p+'" AND meeting_set = "true" THEN 1 ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN service_area = "'+p.value+'" AND call_number NOT NULL THEN call_number ELSE 0 END) / SUM(CASE WHEN service_area = "'+p.value+'" AND meeting_set = "true" THEN 1 ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN lead_source = "'+p.value+'" AND call_number NOT NULL THEN call_number ELSE 0 END) / SUM(CASE WHEN lead_source = "'+p.value+'" AND meeting_set = "true" THEN 1 ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN uid = "'+p.value+'" AND call_number NOT NULL THEN call_number ELSE 0 END) / SUM(CASE WHEN uid = "'+p.value+'" AND meeting_set = "true" THEN 1 ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(SUM(CASE WHEN call_number NOT NULL THEN call_number ELSE 0 END) / SUM(CASE WHEN meeting_set = "true" THEN 1 ELSE 0 END), 0) as total FROM DATA WHERE is_lead = "true"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectTotalDeals(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total deals')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  function selectTotalDealsWon(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total deals won')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE status = "Closed Won"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  function selectTotalDealRevenue(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total deal revenu')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(SUM(CASE WHEN zip = "'+p+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN service_area = "'+p.value+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN lead_source = "'+p.value+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN uid = "'+p.value+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(SUM(CASE WHEN revenue NOT NULL THEN revenue ELSE 0 END), 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectAverageDealRevenue(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data average deal revenue')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND revenue NOT NULL THEN revenue ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN revenue NOT NULL THEN revenue ELSE 0 END), 0) as total FROM DATA WHERE status = "Closed Won"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectDealConversionRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select deal conversion rate')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND status = "Closed Won" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND status = "Closed Won" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND status = "Closed Won" THEN 1 ELSE 0 END) * 100, 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND status = "Closed Won" THEN 1 ELSE 0 END) * 100, 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN status = "Closed Won" THEN 1 ELSE 0 END) * 100, 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectTotalDealSystemSize(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total deal system size')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(SUM(CASE WHEN zip = "'+p+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN service_area = "'+p.value+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN lead_source = "'+p.value+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(SUM(CASE WHEN uid = "'+p.value+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(SUM(CASE WHEN system_size NOT NULL THEN system_size ELSE 0 END), 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectAverageDealSystemSize(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data average deal system size')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND system_size NOT NULL THEN system_size ELSE 0 END), 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN system_size NOT NULL THEN system_size ELSE 0 END), 0) as total FROM DATA WHERE status = "Closed Won"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectProposalSuccessRate(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select proposal success rate')

    const dataKeys = []

    let query = 'SELECT'
    console.log(data)

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'COALESCE(AVG(CASE WHEN zip = "'+p+'" AND error != "true" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN service_area = "'+p.value+'" AND error != "true" THEN 1 ELSE 0 END) * 100, 0) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN lead_source = "'+p.value+'" AND error != "true" THEN 1 ELSE 0 END) * 100, 0) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'COALESCE(AVG(CASE WHEN uid = "'+p.value+'" AND error != "true" THEN 1 ELSE 0 END) * 100, 0) as vu' + i
      }).join(',')
    }
    query += ',COALESCE(AVG(CASE WHEN error != "true" THEN 1 ELSE 0 END) * 100, 0) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })
    console.log('data selected')
  }

  function selectTotalProposals(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total proposals')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE id NOT NULL'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  function selectTotalProposalErrors(htmlDb, data, zip, serviceArea, leadSource, year, month, user) {
    console.log('select data total proposal errors')

    const dataKeys = []

    let query = 'SELECT'

    if(!year || year.length === 0) query += ' year as label'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' month as label'
    else if(year && year.length > 0 && month && month.length > 0) query += ' day as label'

    if(zip && zip.length > 0) {
      query += ','
      query += zip.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p
        })
        return 'SUM(CASE WHEN zip = "'+p+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    } else if(serviceArea && serviceArea.length > 0) {
      query += ','
      query += serviceArea.map((p, i) => {
        dataKeys.push({
          key: 'vsa' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN service_area = "'+p.value+'" THEN 1 ELSE 0 END) as vsa' + i
      }).join(',')
    }

    if(leadSource && leadSource.length > 0) {
      query += ','
      query += leadSource.map((p, i) => {
        dataKeys.push({
          key: 'vls' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN lead_source = "'+p.value+'" THEN 1 ELSE 0 END) as vls' + i
      }).join(',')
    }
    if(user && user.length > 0) {
      query += ','
      query += user.map((p, i) => {
        dataKeys.push({
          key: 'vu' + i,
          color: colorsReports[i % colorsReports.length],
          label: p.label
        })
        return 'SUM(CASE WHEN uid = "'+p.value+'" THEN 1 ELSE 0 END) as vu' + i
      }).join(',')
    }
    query += ',COUNT(*) as total FROM DATA WHERE error = "true"'

    if(year && year.length > 0) query += ' AND year = ' + year[0].value
    if(year && year.length > 0 && month && month.length > 0) query += ' AND month = ' + month[0].value

    if(!year || year.length === 0) query += ' GROUP BY year ORDER BY year'
    else if(year && year.length > 0 && (!month || month.length === 0)) query += ' GROUP BY month ORDER BY month'
    else if(year && year.length > 0 && month && month.length > 0) query += ' GROUP BY day ORDER BY day'

    console.log(query)

    if((!leadSource || leadSource.length === 0) && (!serviceArea || serviceArea.length == 0) && (!user || user.length === 0)) {
      dataKeys.push({
        key: 'total',
        color: colorsReports[0 % colorsReports.length],
        label: 'Total'
      })
    }

    htmlDb.transaction(tx => {
      tx.executeSql(query, [], (tx, results) => {
        var len = results.rows.length, i
        console.log('Found rows: ' + len)
        console.log(results.rows)

        const update = []
        for(i = 0; i < len; i++) {
          update.push(results.rows.item(i))
        }
        cleanData(update, dataKeys, year, month)
        // setFilteredData(update)
      }, null)
    })

    console.log('data selected')
  }

  console.log(user)

  return (
    <div data-aos='fade-in' className='h-screen px-3 py-16 w-full h-screen'>
      <div className='bg-black-875 rounded-2xl overflow-hidden h-full w-full flex'>
        <div className='flex-1 flex flex-col w-full overflow-hidden'>
          <div className='flex-1 overflow-hidden shrink-0 flex flex-col'>
            <div className='text-xs divide-x flex-1 flex h-full overflow-hidden'>
              <div className='w-1/6 overflow-hidden'>
                <FilterMetric metrics={metrics} metric={metric} setMetric={setMetric} />
              </div>
              <div className='flex-1 overflow-hidden'>
                <FilterYear year={year} setYear={setYear} />
              </div>
              <div className='flex-1 overflow-hidden'>
                <FilterMonth year={year} month={month} setMonth={setMonth} />
              </div>
              <div className='w-1/6 overflow-hidden'>
                <FilterServiceArea serviceArea={serviceArea} setServiceArea={setServiceArea} />
              </div>
              <div className='flex-1 overflow-hidden'>
                <FilterZip zips={zips} zip={zip} setZip={setZip} serviceArea={serviceArea} />
              </div>
              <div className='w-1/6 overflow-hidden'>
                <FilterLeadSource leadSource={leadSource} setLeadSource={setLeadSource} />
              </div>
              <div className='w-1/6 overflow-hidden'>
                <FilterUser users={users} metric={metric} user={user} setUser={setUser} />
              </div>
            </div>
            <ul className='border-t flex divide-x text-xs bg-black-850'>
              <li className='w-1/6'>
                <p className='p-2'>
                  <span className='text-black-400'>Metric</span><br />
                  <span className='text-primary'>{metric.label}</span>
                </p>
              </li>
              <li className='flex-1 flex items-end'>
                <div>
                  <button
                    onClick={() => {
                      if(anchor === 'year') {
                        setAnchor(false)
                      } else {
                        setAnchor('year')
                      }
                    }}
                    className={'block p-2 ' + (anchor === 'year' ? 'text-emerald-500' : 'text-black-600 hover:text-black-550')}>
                    {anchor === 'year' ?
                      <ArrowDownCircleIcon className='h-4 w-4' /> :
                      <ArrowDownCircleIcon className='h-4 w-4' />
                    }
                  </button>
                </div>
                <div className='h-full p-2 flex flex-wrap items-end gap-1 overflow-hidden'>
                  {year && year.length > 0 ? year.map(o => <button onClick={() => {
                    setYear(prev => prev.filter(p => p.label !== o.label))
                  }} className='max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>) : ''}
                </div>
              </li>
              <li className='flex-1 flex items-end'>
                <div>
                  <button
                    onClick={() => {
                      if(anchor === 'month') {
                        setAnchor(false)
                      } else {
                        setAnchor('month')
                      }
                    }}
                    className={'block p-2 ' + (anchor === 'month' ? 'text-emerald-500' : 'text-black-600 hover:text-black-550')}>
                    {anchor === 'month' ?
                      <ArrowDownCircleIcon className='h-4 w-4' /> :
                      <ArrowDownCircleIcon className='h-4 w-4' />
                    }
                  </button>
                </div>
                <div className='h-full p-2 flex flex-wrap items-end gap-1 overflow-hidden'>
                  {month && month.length > 0 ? month.map(o => <button onClick={() => {
                    setMonth(prev => prev.filter(p => p.label !== o.label))
                  }} className='overflow-hidden max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>) : ''}
                </div>
              </li>
              <li className='w-1/6 flex items-end'>
                <div>
                  <button
                    onClick={() => {
                      if(anchor === 'serviceArea') {
                        setAnchor(false)
                      } else {
                        setAnchor('serviceArea')
                      }
                    }}
                    className={'block p-2 ' + (anchor === 'serviceArea' ? 'text-emerald-500' : 'text-black-600 hover:text-black-550')}>
                    {anchor === 'serviceArea' ?
                      <ArrowDownCircleIcon className='h-4 w-4' /> :
                      <ArrowDownCircleIcon className='h-4 w-4' />
                    }
                  </button>
                </div>
                <div className='h-full flex-1 p-2 flex flex-wrap items-end justify-end gap-1 overflow-hidden'>
                  {serviceArea && serviceArea.length > 0 ? serviceArea.map(o => <button onClick={() => {
                    setServiceArea(prev => prev.filter(p => p.label !== o.label))
                  }} className='overflow-hidden max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>) : ''}
                </div>
              </li>
              <li className='flex-1 flex items-end'>
                <div>
                  <button
                    onClick={() => {
                      if(anchor === 'zip') {
                        setAnchor(false)
                      } else {
                        setAnchor('zip')
                      }
                    }}
                    className={'block p-2 ' + (anchor === 'zip' ? 'text-emerald-500' : 'text-black-600 hover:text-black-550')}>
                    {anchor === 'zip' ?
                      <ArrowDownCircleIcon className='h-4 w-4' /> :
                      <ArrowDownCircleIcon className='h-4 w-4' />
                    }
                  </button>
                </div>
                <div className='h-full flex-1 p-2 flex flex-wrap items-end justify-end gap-1 overflow-hidden'>
                  {zip && zip.length > 0 ? zip.map(o => <button onClick={() => {
                    setZip(prev => prev.filter(p => p !== o))
                  }} className='overflow-hidden max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o}<XMarkIcon className='h-2.5 w-2.5' /></button>) : ''}
                </div>
              </li>
              <li className='w-1/6 flex items-end'>
                <div>
                  <button
                    onClick={() => {
                      if(anchor === 'leadSource') {
                        setAnchor(false)
                      } else {
                        setAnchor('leadSource')
                      }
                    }}
                    className={'block p-2 ' + (anchor === 'leadSource' ? 'text-emerald-500' : 'text-black-600 hover:text-black-550')}>
                    {anchor === 'leadSource' ?
                      <ArrowDownCircleIcon className='h-4 w-4' /> :
                      <ArrowDownCircleIcon className='h-4 w-4' />
                    }
                  </button>
                </div>
                <div className='h-full flex-1 p-2 flex flex-wrap items-end justify-end gap-1 overflow-hidden'>
                  {leadSource && leadSource.length > 0 ? leadSource.map(o => <button onClick={() => {
                    setLeadSource(prev => prev.filter(p => p.label !== o.label))
                  }} className='overflow-hidden max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>) : ''}
                </div>
              </li>
              <li className='w-1/6 flex items-end'>
                <div>
                  <button
                    onClick={() => {
                      if(anchor === 'user') {
                        setAnchor(false)
                      } else {
                        setAnchor('user')
                      }
                    }}
                    className={'block p-2 ' + (anchor === 'user' ? 'text-emerald-500' : 'text-black-600 hover:text-black-550')}>
                    {anchor === 'user' ?
                      <ArrowDownCircleIcon className='h-4 w-4' /> :
                      <ArrowDownCircleIcon className='h-4 w-4' />
                    }
                  </button>
                </div>
                <div className='h-full flex-1 p-2 flex flex-wrap items-end justify-end gap-1 overflow-hidden'>
                  {user && user.length > 0 ? user.map(o => <button onClick={() => {
                    setUser(prev => prev.filter(p => p.label !== o.label))
                  }} className='overflow-hidden max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>) : ''}
                </div>
              </li>
            </ul>
          </div>
          <div className='h-2/5 w-full overflow-hidden'>
            <div className='rounded-bl-2xl h-full w-full overflow-hidden text-xs leading-tight p-2 pb-2 flex flex-col'>
              {!filteredData &&
                <div className='w-full h-full flex'>
                  <div className='flex h-24 w-24 relative m-auto'>
                    <span className='animate-ping z-0 bg-black-750 h-full w-full absolute top-0 left-0 rounded-full m-auto flex' />
                    <PresentationChartBarIcon className='relative z-10 text-primary h-7 w-7 m-auto' />
                  </div>
                </div>
              }
              {filteredData &&
                <>
                  <ul className='flex gap-1 pb-3 text-xs'>
                    <li><button className={'w-16 px-3 py-1 leading-none rounded-full ' + (graphType === 'line' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-675')} onClick={() => setGraphType('line')}>Line</button></li>
                    <li><button className={'w-16 px-3 py-1 leading-none rounded-full ' + (graphType === 'bar' ? 'bg-primary text-black' : 'bg-black-725 hover:bg-black-675')} onClick={() => setGraphType('bar')}>Bar</button></li>
                  </ul>
                  {graphType === 'line' &&
                    <div className='flex-1 overflow-hidden'>
                      <ResponsiveContainer height='100%' width='100%'>
                        <LineChart
                          margin={{
                            top: 0,
                            right: 10,
                            left: 0,
                            bottom: 0,
                          }}
                          data={filteredData.data}>
                          <CartesianGrid strokeDasharray='3 3' stroke='rgb(80,80,80)'/>
                          <XAxis dy={10} tickLine={false} axisLine={false} dataKey='label' />
                          <YAxis
                            dx={-5}
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={tickFormatter}
                            />
                          <Tooltip content={<CustomTooltip metric={metric} dataKeys={filteredData.dataKeys} />} />
                          {filteredData.dataKeys.map(d =>
                            <Line key={d.key} name='Count' type='monotone' dataKey={d.key} stroke={d.color} strokeWidth={2} activeDot={{ r: 8 }} />
                          )}
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  }
                  {graphType === 'bar' &&
                    <div className='flex-1 overflow-hidden'>
                      <ResponsiveContainer height='100%' width='100%'>
                        <BarChart
                          margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                          }}
                          data={filteredData.data}>
                          <CartesianGrid strokeDasharray='3 3' stroke='rgb(80,80,80)'/>
                          <XAxis dy={10} tickLine={false} axisLine={false} dataKey='label' />
                          <YAxis
                            dx={-5}
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={tickFormatter}
                            />
                          <Tooltip cursor={{fill: 'rgb(51,51,51)'}} content={<CustomTooltip metric={metric} dataKeys={filteredData.dataKeys} cursor={{fill: 'rgb(51,51,51)'}}/>} />
                          {filteredData.dataKeys.map(d =>
                            <Bar barSize={5} name='Count' type='monotone' dataKey={d.key} fill={d.color} />
                          )}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function tickFormatter(tickItem) {
  function nFormatter(num) {
    if(num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
    }
    if(num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
    if(num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    }
    return num
  }
  return nFormatter(tickItem)
}

const CustomTooltip = ({ active, payload, label, metric, dataKeys }) => {
  if(active && payload && payload.length) {
    return (
      <div className='filter drop-shadow-lg w-full max-w-md ring-2 ring-yellow-500 bg-black-800 p-2 rounded-xl'>
        {/*<p className='text-sm text-black-200 pb-2'>{label}</p>*/}
        <div className='grid grid-cols-3 gap-2'>
        {payload.sort((a,b) => b.value - a.value).map((p, index) =>
          <div key={'payload-' + index} className='flex items-center gap-2'>
            <div className='block h-1.5 w-1.5 rounded-full' style={{ backgroundColor: p.color }} />
            <div>
              <p className='text-base leading-tight'>{metric.dataLabel && metric.dataLabel === '$' && <span>{metric.dataLabel}</span>}{p.value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: metric.dataLabel === '$' ? 2 : 0 })}{metric.dataLabel && metric.dataLabel !== '$' && <span>{metric.dataLabel}</span>}</p>
              <p className='text-xxs'>{dataKeys && dataKeys.find(k => k.key === p.dataKey).label}</p>
            </div>
          </div>
        )}
        </div>
        {/* <p className='text-sm pt-2'>{metric.tipLabel}</p> */}
      </div>
    )
  }
  return null
}

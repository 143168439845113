export async function selectReportData({ year, month, serviceArea, leadSource, reportType, htmlDb }) {
  console.log('select report data')

  const filteredData = {}

  const queryGlobal = ['SELECT']
  const valuesGlobal = []

  valuesGlobal.push('COALESCE(SUM(CASE WHEN type = "deal" AND status = "Closed Won" THEN 1 ELSE 0 END), 0) as totalGlobalDeals')
  valuesGlobal.push('COALESCE(SUM(CASE WHEN type = "deal" AND status = "Closed Won" THEN revenue ELSE 0 END), 0) as totalGlobalDealRevenue')
  valuesGlobal.push('COALESCE(SUM(CASE WHEN type = "contact" AND is_lead = "true" THEN 1 ELSE 0 END), 0) as totalGlobalLeads')
  valuesGlobal.push('COALESCE(SUM(CASE WHEN type = "contact" AND is_lead = "true" THEN lead_cost ELSE 0 END), 0) as totalGlobalLeadCost')
  valuesGlobal.push('COALESCE(SUM(CASE WHEN type = "contact" AND is_lead = "true" AND meeting_set = "true" THEN 1 ELSE 0 END), 0) as totalGlobalAppointments')
  queryGlobal.push(valuesGlobal.join(', '))

  queryGlobal.push('FROM DATA WHERE id NOT NULL')

  const query = ['SELECT']
  const values = []

  values.push('zip')
  values.push('COALESCE(SUM(CASE WHEN type = "deal" AND status = "Closed Won" THEN 1 ELSE 0 END), 0) as totalDeals')
  values.push('COALESCE(SUM(CASE WHEN type = "deal" AND status = "Closed Won" THEN revenue ELSE 0 END), 0) as totalDealRevenue')
  values.push('COALESCE(ROUND(AVG(CASE WHEN type = "deal" AND status = "Closed Won" THEN 1 ELSE 0 END)), 0) as percentDeals')
  values.push('COALESCE(SUM(CASE WHEN type = "contact" AND is_lead = "true" THEN 1 ELSE 0 END), 0) as totalLeads')
  values.push('COALESCE(SUM(CASE WHEN type = "contact" AND is_lead = "true" THEN lead_cost ELSE 0 END), 0) as totalLeadCost')
  values.push('COALESCE(SUM(CASE WHEN type = "contact" AND is_lead = "true" AND meeting_set = "true" THEN 1 ELSE 0 END), 0) as totalAppointments')
  query.push(values.join(', '))

  query.push('FROM DATA WHERE id NOT NULL')

  const queryFilters = []

  if(year && year.length > 0) {
    queryFilters.push('AND (')
     queryFilters.push(year.map((p, i) => {
      return 'year = '+p.value
    }).join(' OR '))
    queryFilters.push(')')
  }

  if(month && month.length > 0) {
    queryFilters.push('AND (')
     queryFilters.push(month.map((p, i) => {
      return 'month = '+p.value
    }).join(' OR '))
    queryFilters.push(')')
  }

  if(serviceArea && serviceArea.length > 0) {
    queryFilters.push('AND (')
     queryFilters.push(serviceArea.map((p, i) => {
      return 'service_area = "'+p.value+'"'
    }).join(' OR '))
    queryFilters.push(')')
  }

  if(leadSource && leadSource.length > 0) {
    queryFilters.push('AND (')
     queryFilters.push(leadSource.map((p, i) => {
      return 'lead_source = "'+p.value+'"'
    }).join(' OR '))
    queryFilters.push(')')
  }

  queryGlobal.push(...queryFilters)
  query.push(...queryFilters)

  query.push('GROUP BY zip')

  if(reportType.value === 'no-deals') query.push('HAVING totalDeals = 0')
  else if(reportType.value === 'deals') query.push('HAVING totalDeals != 0')
  else if(reportType.value === 'no-appointments') query.push('HAVING totalAppointments = 0')
  else if(reportType.value === 'appointments') query.push('HAVING totalAppointments != 0')

  query.push('ORDER BY zip')

  const queryString = query.join(' ')
  const queryGlobalString = queryGlobal.join(' ')

  // console.log(queryString)
  // console.log(queryGlobalString)

  await new Promise(resolve => {
    htmlDb.transaction(async tx => {
      await new Promise(resolve => {
        tx.executeSql(queryGlobalString, [], (tx, results) => {
          var len = results.rows.length, i
          // console.log('Found global rows: ' + len)
          // console.log(results.rows)

          const update = []
          for(i = 0; i < len; i++) {
            update.push(results.rows.item(i))
          }

          filteredData.global = update[0]
          resolve()
        }, null)
      })
      resolve()
    })
  })

  await new Promise(resolve => {
    htmlDb.transaction(async tx => {
      await new Promise(resolve => {
        tx.executeSql(queryString, [], (tx, results) => {
          var len = results.rows.length, i
          // console.log('Found data rows: ' + len)
          // console.log(results.rows)

          const updateGlobal = {}
          for(i = 0; i < len; i++) {
            updateGlobal.totalGlobalDeals = (updateGlobal.totalGlobalDeals || 0) + results.rows.item(i).totalDeals
            updateGlobal.totalGlobalDealRevenue = (updateGlobal.totalGlobalDealRevenue || 0) + results.rows.item(i).totalDealRevenue
            updateGlobal.totalGlobalLeads = (updateGlobal.totalGlobalLeads || 0) + results.rows.item(i).totalLeads
            updateGlobal.totalGlobalLeadCost = (updateGlobal.totalGlobalLeadCost || 0) + results.rows.item(i).totalLeadCost
            updateGlobal.totalGlobalAppointments = (updateGlobal.totalGlobalAppointments || 0) + results.rows.item(i).totalAppointments
          }
          filteredData.global.filtered = updateGlobal

          const update = []
          for(i = 0; i < len; i++) {
            const data = results.rows.item(i)
            update.push({
              ...data,
              percentDeals: (data.totalDeals / filteredData.global.totalGlobalDeals * 100) || 0,
              percentDealRevenue: (data.totalDealRevenue / filteredData.global.totalGlobalDealRevenue * 100) || 0,
              percentLeadToAppointment: (data.totalAppointments / filteredData.global.totalGlobalLeads * 100) || 0,
              percentAppointmentToDeal: (data.totalDeals / filteredData.global.totalGlobalDeals * 100) || 0,
              percentLeadToDeal: (data.totalDeals / filteredData.global.totalGlobalLeads * 100) || 0
            })
          }
          filteredData.data = update

          resolve()
        }, null)
      })
      resolve()
    })
  })

  filteredData.heading = {
    zip: filteredData.data.length,
    totalDeals: (filteredData.global.filtered ? filteredData.global.filtered.totalGlobalDeals : filteredData.global.totalGlobalDeals),
    totalDealRevenue: (filteredData.global.filtered ? filteredData.global.filtered.totalGlobalDealRevenue : filteredData.global.totalGlobalDealRevenue),
    percentDeals: ((filteredData.data.reduce((previousValue, currentValue) => previousValue + currentValue.totalDeals, 0) / filteredData.global.totalGlobalDeals * 100) || 0),
    percentDealRevenue: ((filteredData.data.reduce((previousValue, currentValue) => previousValue + currentValue.totalDealRevenue, 0) / filteredData.global.totalGlobalDealRevenue * 100) || 0),
    totalLeads: (filteredData.global.filtered ? filteredData.global.filtered.totalGlobalLeads : filteredData.global.totalGlobalLeads),
    totalLeadCost: (filteredData.global.filtered ? filteredData.global.filtered.totalGlobalLeadCost : filteredData.global.totalGlobalLeadCost),
    totalAppointments: (filteredData.global.filtered ? filteredData.global.filtered.totalGlobalAppointments : filteredData.global.totalGlobalAppointments),
    percentLeadToAppointment: ((filteredData.data.reduce((previousValue, currentValue) => previousValue + currentValue.totalAppointments, 0) / filteredData.global.totalGlobalLeads * 100) || 0),
    percentAppointmentToDeal: ((filteredData.data.reduce((previousValue, currentValue) => previousValue + currentValue.totalDeals, 0) / filteredData.global.totalGlobalDeals * 100) || 0),
    percentLeadToDeal: ((filteredData.data.reduce((previousValue, currentValue) => previousValue + currentValue.totalDeals, 0) / filteredData.global.totalGlobalLeads * 100) || 0),
  }

  console.log('data selected')

  return filteredData
}

export const columns = [
  {
    label: 'Zipcode',
    key: 'zip'
  },
  {
    label: 'Leads',
    key: 'totalLeads'
  },
  {
    label: 'Lead Cost',
    key: 'totalLeadCost'
  },
  {
    label: 'Appointments',
    key: 'totalAppointments'
  },
  {
    label: '% Lead to Appt',
    key: 'percentLeadToAppointment'
  },
  {
    label: 'Deals',
    key: 'totalDeals'
  },
  {
    label: '% Appt to Deal',
    key: 'percentAppointmentToDeal'
  },
  {
    label: '% Lead to Deal',
    key: 'percentLeadToDeal'
  },
  {
    label: 'Deal Revenue',
    key: 'totalDealRevenue'
  },
  {
    label: '% of Deals',
    key: 'percentDeals'
  },
  {
    label: '% of Revenue',
    key: 'percentDealRevenue'
  }
]

export function formatReportDataExport({ data }) {
  // const formattedData = [
  //   {},
  //   {
  //     zip: formatReportValue({ data: data.heading.zip, key: 'zip' }),
  //     totalDeals: formatReportValue({ data: data.heading.totalDeals, key: 'totalDeals' }),
  //     totalDealRevenue: formatReportValue({ data: data.heading.totalDealRevenue, key: 'totalDealRevenue' }),
  //     percentDeals: formatReportValue({ data: data.heading.percentDeals, key: 'percentDeals' }),
  //     percentDealRevenue: formatReportValue({ data: data.heading.percentDealRevenue, key: 'percentDealRevenue' }),
  //     totalLeads: formatReportValue({ data: data.heading.totalLeads, key: 'totalLeads' }),
  //     totalLeadCost: formatReportValue({ data: data.heading.totalLeadCost, key: 'totalLeadCost' }),
  //     totalAppointments: formatReportValue({ data: data.heading.totalAppointments, key: 'totalAppointments' }),
  //     percentLeadToAppointment: formatReportValue({ data: data.heading.percentLeadToAppointment, key: 'percentLeadToAppointment' }),
  //     percentAppointmentToDeal: formatReportValue({ data: data.heading.percentAppointmentToDeal, key: 'percentAppointmentToDeal' }),
  //     percentLeadToDeal: formatReportValue({ data: data.heading.percentLeadToDeal, key: 'percentLeadToDeal' })
  //   },
  //   {},
  //   ...data.data.map(d => {
  //     return {
  //       zip: formatReportValue({ data: d.zip, key: 'zip' }),
  //       totalDeals: formatReportValue({ data: d.totalDeals, key: 'totalDeals' }),
  //       totalDealRevenue: formatReportValue({ data: d.totalDealRevenue, key: 'totalDealRevenue' }),
  //       percentDeals: formatReportValue({ data: d.percentDeals, key: 'percentDeals' }),
  //       percentDealRevenue: formatReportValue({ data: d.percentDealRevenue, key: 'percentDealRevenue' }),
  //       totalLeads: formatReportValue({ data: d.totalLeads, key: 'totalLeads' }),
  //       totalLeadCost: formatReportValue({ data: d.totalLeadCost, key: 'totalLeadCost' }),
  //       totalAppointments: formatReportValue({ data: d.totalAppointments, key: 'totalAppointments' }),
  //       percentLeadToAppointment: formatReportValue({ data: d.percentLeadToAppointment, key: 'percentLeadToAppointment' }),
  //       percentAppointmentToDeal: formatReportValue({ data: d.percentAppointmentToDeal, key: 'percentAppointmentToDeal' }),
  //       percentLeadToDeal: formatReportValue({ data: d.percentLeadToDeal, key: 'percentLeadToDeal' })
  //     }
  //   })
  // ]
  const formattedData = []

  formattedData.push({})

  let update = {}
  columns.forEach(c => {
    update[c.key] = formatReportValue({ data: data.heading[c.key], key: c.key })
  })
  formattedData.push(update)

  formattedData.push({})

  data.data.forEach(d => {
    update = {}
    columns.forEach(c => {
      update[c.key] = formatReportValue({ data: d[c.key], key: c.key })
    })
    formattedData.push(update)
  })
  return formattedData
}

export function formatReportValue({ data, key }) {
  const formatter = {
    zip: (value) => (value ? value : 0).toLocaleString(),
    totalDeals: (value) => (value ? value : 0).toLocaleString(),
    totalDealRevenue: (value) => '$' + (value ? value : 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    percentDeals: (value) => (value ? value : 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
    percentDealRevenue: (value) => (value ? value : 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
    totalLeads: (value) => (value ? value : 0).toLocaleString(),
    totalLeadCost: (value) => '$' + (value ? value : 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
    totalAppointments: (value) => (value ? value : 0).toLocaleString(),
    percentLeadToAppointment: (value) => (value ? value : 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
    percentAppointmentToDeal: (value) => (value ? value : 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
    percentLeadToDeal: (value) => (value ? value : 0).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
  }
  return formatter[key](data)
}

import { useRef, useState, useCallback, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, updateDoc, doc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import { IKImage, IKContext, IKUpload } from 'imagekitio-react'

import ImageKit from 'imagekit-javascript'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import { useDropzone } from 'react-dropzone'

export default function Index({ data, collection, folder, onChange }) {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const storage = getStorage(firebaseApp, 'gs://risingsun-solar-public')

  const pixelRatio = 4

  const [uFile, setUFile] = useState(false)

  const uploadRef = useRef(null)

  const imgModal = useRef(null)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)

  const [upImg, setUpImg] = useState(false)
  const [completedCrop, setCompletedCrop] = useState(null)
  const [crop, setCrop] = useState({ unit: 'px', width: 260, aspect: 1/1 })

  const onSelectFile = files => {
    if(files && files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result))
      reader.readAsDataURL(files[0])
      setUFile(files[0])
    }
  }

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  function getResizedCanvas(canvas, newWidth, newHeight) {
    const tmpCanvas = document.createElement('canvas')
    tmpCanvas.width = newWidth
    tmpCanvas.height = newHeight
    const ctx = tmpCanvas.getContext('2d')
    ctx.drawImage(canvas,0, 0, canvas.width, canvas.height,0, 0, newWidth, newHeight)
    return tmpCanvas
  }

  function generateDownload(previewCanvas, crop, uploadImage) {
    if(!crop || !previewCanvas) return
    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height)
    canvas.toBlob(
      blob => {
        // const previewUrl = window.URL.createObjectURL(blob)
        uploadImage(blob)
        setUpImg(false)
      }, 'image/png', 1
    )
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) return

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingEnabled = false

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)
  }, [completedCrop])

  const uploadImage = async (file) => {
    console.log(data)
    if(file) {
      const storageRef = ref(storage, folder + '/' + data.id + '.jpg')
      await uploadBytes(storageRef, file).then(async image => {
        console.log(image)
        const update = {
          image: {
            path: image.metadata.fullPath,
            bucket: image.metadata.bucket
          }
        }
        await getDownloadURL(storageRef).then((url) => {
          update.image.url = url
        })
        .catch(e => {
          console.log(e)
        })
        console.log(update)
        const docRef = doc(db, collection, data.id)
        updateDoc(docRef, update)
        onChange(update.image)
      })

      // const imagekit = new ImageKit({
      //   publicKey : 'public_yVxXp9KEYASzeBKJlpmq/mNZyQU=',
      //   privateKey : 'private_zlZfuczQOGxZZAlAoe1d1ZNlttQ=',
      //   urlEndpoint : 'https://ik.imagekit.io/ozpoo',
      //   authenticationEndpoint: "https://us-central1-risingsun-solar.cloudfunctions.net/imagekitAuth"
      // })
      //
      // imagekit.upload({
      //   file: uFile,
      //   fileName: "abc1.jpg",
      // }, function(err, result) {
      //   console.log(err)
      //     console.log(result);
      // })

      // console.log(imagekit)

      // const authResponse = await fetch('https://us-central1-risingsun-solar.cloudfunctions.net/imagekitAuth')
      // const authParams = await authResponse.json()
      // console.log(authParams)
      //
      // const formData = new FormData()
      // formData.append('publicKey', 'public_yVxXp9KEYASzeBKJlpmq/mNZyQU=')
      // formData.append('signature', authParams?.signature)
      // formData.append('expire', authParams?.expire)
      // formData.append('token', authParams?.token)
  		// formData.append('file', uFile)
  		// formData.append('fileName', 'test.jpg')


      // const body = {
      //   file:  uFile,
      //   folder: 'test',
      //   publicKey: ,
      //   signature: authParams?.signature,
      //   expire: authParams?.expire,
      //   token: authParams?.token,
      //   fileName: ,
      // }
      // imagekit.upload({
      //   file: file,
      //   fileName: 'abc.jpg',
      // }, function(err, result) {
      //   console.log(arguments)
      //   console.log(imagekit.url({
      //       src: result.url,
      //       transformation : [{ height: 300, width: 400}]
      //   }))
      // })
      // const response = await fetch('https://upload.imagekit.io/api/v1/files/upload',
      //   {
      //     method: 'POST',
      //     body: formData
      //   }
      // ).then(response => {
      //   return response.json()
      // })
      // .then(data => {
      //   console.log(data)
      // })
    }
  }


  const onError = err => {
    console.log('Error', err)
  };

  const onSuccess = res => {
    console.log('Success', res)
  };

  return (
    <>
      {/*<IKContext publicKey={process.env.REACT_APP_IMAGEKIT_PUBLIC} urlEndpoint='https://ik.imagekit.io/ozpoo' authenticationEndpoint='https://us-central1-risingsun-solar.cloudfunctions.net/imagekitAuth'>
        <IKUpload
          fileName='test.jpg'
          isPrivateFile={false}
          folder={'/test'}
          inputRef={uploadRef}
          onError={onError} onSuccess={onSuccess}
        />
      </IKContext>*/}
      <div content='true' className='w-full max-w-lg'>
        {!upImg && <MyDropzone data={data} onSelectFile={onSelectFile} />}
        {upImg &&
          <>
            <div className='flex items-center w-full gap-3 p-3'>
              <div className='flex-1'>
                <ReactCrop
                  className='focus:outline-none relative rounded-lg overflow-hidden block'
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={c => setCrop(c)}
                  onComplete={c => setCompletedCrop(c)}
                />
              </div>
              <div className='min-w-0 flex self-stretch flex-col justify-center items-center'>
                <canvas
                  className='rounded-full overflow-hidden'
                  ref={previewCanvasRef}
                  style={{
                    width: 120,
                    height: 120
                  }}
                />
              </div>
            </div>
            <button
              className='ml-3 bg-primary text-black text-sm font-bold rounded-full px-4 py-1'
              type='button'
              disabled={!completedCrop?.width || !completedCrop?.height}
              onClick={() => {
                generateDownload(previewCanvasRef.current, completedCrop, uploadImage)
                imgModal.current.close()
              }}>
              Set Image
            </button>
          </>
        }
      </div>
    </>
  )
}

function MyDropzone({ onSelectFile, data }) {
  const onDrop = useCallback(acceptedFiles => {
    onSelectFile(acceptedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div>
      <div className='flex justify-center px-3 py-8 max-w-full w-full cursor-pointer bg-black-800 rounded-lg hover:bg-black-775' {...getRootProps()}>
       <input {...getInputProps()} />
       <div className='flex items-center gap-3'>
        <div className='h-20 w-20 bg-black-750 rounded-full flex'>
           {data.image ?
             <img className='h-full w-full rounded-full overflow-hidden' src={data.image.url} alt='Profile avatar.' /> :
             <svg xmlns='http://www.w3.org/2000/svg' className='m-auto text-primary h-5 w-5' viewBox='0 0 20 20' fill='currentColor'>
               <path fillRule='evenodd' d='M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z' clipRule='evenodd' />
            </svg>
           }
         </div>
         <p className='flex-1 text-sm text-black-400 leading-tight'>
           {isDragActive ?
             'Drop the file here.' :
             'Click or drop a file here to update member profile photo.'
           }
         </p>
       </div>
     </div>
   </div>
  )
}

export const brandAssets = [
  {
    label: 'Advertisement',
    value: 'advertisement'
  },
  {
    label: 'Apparel',
    value: 'apparel'
  },
  {
    label: 'Collateral',
    value: 'collateral'
  },
  {
    label: 'Digital',
    value: 'digital'
  },
  {
    label: 'Events',
    value: 'events'
  },
  {
    label: 'Embroidered',
    value: 'embroidered'
  },
  {
    label: 'Office',
    value: 'office'
  },
  {
    label: 'Print',
    value: 'print'
  },
  {
    label: 'Signage',
    value: 'signage'
  },
  {
    label: 'Transportation',
    value: 'transportation'
  },
  {
    label: 'Video',
    value: 'video'
  }
]

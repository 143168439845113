import { useState, useRef, useEffect } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/solid'

export default function Index({ mapType, setMapType }) {
  const [show, setShow] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if(ref.current && !ref.current.contains(event.target)) {
        setShow(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div ref={ref} className='flex min-w-0 justify-between items-center gap-2 relative z-10'>
      <div className='relative flex-1 w-full flex items-center'>
        <button
          onClick={() => setShow(!show)}
          className='w-full bg-black-800 rounded-full px-3 h-6 hover:bg-black-775 active:bg-black-750 flex gap-3 items-center'>
          <span className={'h-2 w-2 rounded-full overflow-hidden' + (mapType === 'Customers' ? ' bg-purple-500' : ' bg-red-500')}>

          </span>
          <span className='flex-1 min-w-0 block text-xs whitespace-nowrap truncate'>{mapType}</span>
          <ChevronDownIcon className='h-4.5 w-4.5 -mb-px' />
        </button>
        {show &&
          <div className='absolute filter drop-shadow-lg w-56 top-full left-0 mt-2 bg-black-825 rounded-xl overflow-hidden py-3'>
            <ul>
              <li>
                <button
                  onClick={() => {
                    setMapType('Property')
                    setShow(false)
                  }}
                  className='flex gap-3 items-center text-sm w-full px-2 py-3 text-left hover:bg-black-800'>
                  <span className={'h-2 w-2 rounded-full overflow-hidden' + (mapType === 'Property' ? ' bg-red-500' : ' bg-red-500')}>

                  </span>
                  <span>Property</span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setMapType('Customers')
                    setShow(false)
                  }}
                  className='flex gap-3 items-center text-sm w-full px-2 py-3 text-left hover:bg-black-800'>
                  <span className={'h-2 w-2 rounded-full overflow-hidden' + (mapType === 'Customers' ? ' bg-purple-500' : ' bg-purple-500')}>

                  </span>
                  <span>Customers</span>
                </button>
              </li>
            </ul>
          </div>
        }
      </div>
    </div>
  )
}

import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { createPortal } from 'react-dom'

import { getApp } from 'firebase/app'
import { getFirestore, deleteDoc, setDoc, doc } from 'firebase/firestore'
import { Context } from 'store/index'
import slugify from 'slugify'
import { ArrowsUpDownIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'

import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'
import Select from 'react-select'

import { states } from 'lib/utils'

import UploadImage from './upload-image'

export default function Index({ owners, places, location, setCurrentLocation, showModal, setShowModal }) {
  const [state] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)
  const [original, setOriginal] = useState(false)

  function compareObjs(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setCurrentLocation(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  useEffect(() => {
    if(location) {
      if(!location.slug) location.slug = slugify(location.name, {remove: /[#$%^&=*+~.,/;?—()''!@]/g}).toLowerCase()
      setData(location)
      if(!original) {
        setOriginal(location)
      }
    } else {
      setData(false)
      setOriginal(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  function formatHours(hours) {
    return (hours + 11) % 12 + 1 + (hours > 12 ? 'pm' : 'am')
  }

  return display ? createPortal(
    <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-4xl transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='p-3'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 h-full overflow-hidden overflow-scroll'>
          {data &&
            <div className='flex'>
              <div className='space-y-6 flex-1'>
                <div className='p-3'>
                  <h2 className='text-xl'>{data.name}</h2>
                  <p className='text-black-400 mb-6'>{data.address ? data.address : 'No address'}</p>
                  <div className='flex gap-6'>
                    <div className='flex items-center gap-3'>
                      <label htmlFor={data.id + '-published-edit'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                        <input
                          type='checkbox'
                          checked={data.isPublished ? true : false}
                          id={data.id + '-published-edit'}
                          onChange={(e) => {
                            const isPublished = data.isPublished ? false : true
                            setData(prev => ({
                              ...prev,
                              isPublished: isPublished
                            }))
                            // setDoc(doc(db, 'careers', data.id), {
                            //   isPublished: isPublished,
                            //   updatedAt: new Date()
                            // }, { merge: true })
                          }}
                          className='cursor-pointer h-full w-full sr-only peer' />
                        <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (data.isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                      </label>
                      <span className={'text-xs ' + (data.isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                    </div>
                  </div>
                </div>

                {/*
                <div className='px-3'>
                  <UploadImage
                    data={data}
                    collection='locations'
                    folder='locations'
                    onChange={e => {
                      setData(prev => ({
                        ...prev,
                        imageFeature: e
                      }))
                    }}
                  />
                </div>
                */}

                <div className='space-y-2 p-3'>
                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      name: e.target.value
                    }))
                  }} data={data} property='name' label='Name' />

                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      slug: e.target.value
                    }))
                  }} data={data} property='slug' label='Location Slug' />
                  <div className='relative group'>
                    <label htmlFor='salesReps' className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>Service Area</label>
                    <Select
                      menuPlacement='top'
                      className='react-select-multi-select relative z-10'
                      classNamePrefix='react-select'
                      value={data && data.service_area ? data.service_area : ''}
                      onChange={(e) => {
                        setData(prev => ({
                          ...prev,
                          service_area: e,
                          hubSpotServiceAreaId: e.value
                        }))
                      }}
                      options={state.contactProperties.service_area}
                    />
                    <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
                  </div>

                  <TextInput onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      hubSpotSchedulerEmbedUrl: e.target.value
                    }))
                  }} data={data} property='hubSpotSchedulerEmbedUrl' label='Hubspot Scheduler URL' />
                  <p className='text-xs text-black-400'>
                    Make sure there is "?embed" at the end of the URL.<br />
                    Example: https://domain.com/calendar/name?embed=true
                  </p>

                  <div className='relative group'>
                    <label htmlFor='salesReps' className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>Sales Reps</label>
                    <Select
                      menuPlacement='top'
                      className='react-select-multi-select relative z-10'
                      classNamePrefix='react-select'
                      isMulti={true}
                      value={data && data.salesReps ? data.salesReps : []}
                      onChange={(e) => {
                        setData(prev => ({
                          ...prev,
                          salesReps: e
                        }))
                      }}
                      options={owners.map(rep => {
                        return {
                          label: rep.firstName + ' ' + rep.lastName,
                          value: rep.id
                        }
                      })}
                    />
                    <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
                  </div>

                  <div className='relative group'>
                    <label htmlFor='states' className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>States Served</label>
                    <Select
                      menuPlacement='top'
                      className='react-select-multi-select relative z-10'
                      classNamePrefix='react-select'
                      isMulti={true}
                      value={data && data.states ? data.states : []}
                      onChange={(e) => {
                        setData(prev => ({
                          ...prev,
                          states: e
                        }))
                      }}
                      options={states.map(state => {
                        return {
                          label: state.label,
                          value: state.value
                        }
                      })}
                    />
                    <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
                  </div>

                  <TextInput isTextArea={true} onChange={(e) => {
                    setData(prev => ({
                      ...prev,
                      territories: e.target.value
                    }))
                  }} data={data} property='territories' label='Territories' />
                  <p className='text-xs text-black-400'>Enter a comma seperated list of all the zipcodes this location services.</p>
                </div>
                <div className='p-3 pb-12'>
                  <button
                    onClick={async () => {
                      if(window.confirm('Are you sure you want to remove this?')) {
                        await deleteDoc(doc(db, 'locations', data.id))
                        setShowModal(false)
                      }
                    }}
                    className='font-bold px-6 py-1.5 text-sm rounded-lg text-red-500 bg-black-775'>
                    Delete
                  </button>
                </div>
              </div>
              <div className='flex-1 px-3 space-y-3'>
                <div className='relative group'>
                  <label htmlFor='googlePlacesId' className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>Google Business Location</label>
                  <Select
                    menuPlacement='top'
                    className='react-select-multi-select relative z-10'
                    classNamePrefix='react-select'
                    value={data && data.place ? data.place : ''}
                    onChange={(e) => {
                      setData(prev => {
                        const update = {
                          ...prev,
                          place: e,
                          address: e.storefrontAddress.addressLines.join(', ') + ', ' + e.storefrontAddress.locality + ', ' + e.storefrontAddress.administrativeArea + ', ' + e.storefrontAddress.postalCode
                        }
                        if(e.phoneNumbers && e.phoneNumbers.primaryPhone) update.phone = e.phoneNumbers.primaryPhone
                        if(e.regularHours && e.regularHours.periods) update.hours = e.regularHours.periods.map(p => p.openTime.hours.toString() + 'am - ' + (parseInt(p.closeTime.hours) - 12) + 'pm')
                        return update
                      })
                    }}
                    options={places.map(place => {
                      return {
                        ...place,
                        label: place.title + ' — ' + place.storefrontAddress.addressLines.join(', ') + ', ' + place.storefrontAddress.locality + ', ' + place.storefrontAddress.administrativeArea,
                        value: place.name,
                      }
                    })}
                  />
                  <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
                </div>

                {data.place && data.place.placeIds && data.place.placeIds.length > 0 &&
                  <div className='rounded-xl overflow-hidden'>
                    <img src={'https://maps.googleapis.com/maps/api/staticmap?markers='+data.geoJson.coordinates[1]+','+data.geoJson.coordinates[0]+'&center='+data.geoJson.coordinates[1]+','+data.geoJson.coordinates[0]+'&zoom=18&size=600x260&maptype=satellite&key=' + process.env.REACT_APP_G_API_KEY} />
                  </div>
                }
                {data.place &&
                  <ul className='divide-y divide-black-725 py-4 rounded-lg bg-black-800 text-black-300 text-sm'>
                    <li className='py-2 pb-4 px-4'>
                      {data.address}<br />
                      {data.phone}
                    </li>
                    {data.place && data.place.regularHours && data.place.regularHours.periods && data.place.regularHours.periods.map(h =>
                      <li className='flex py-2 px-4'>
                        <span className='flex-1 capitalize'>{h.openDay.toLowerCase()}</span> <span className='flex-1'>{formatHours(h.openTime.hours)} &mdash; {formatHours(h.closeTime.hours)}</span>
                      </li>
                    )}
                  </ul>
                }
              </div>
            </div>
          }
          <div className='border-t border-black-750 text-sm sticky bottom-0 bg-black-850 p-3 flex z-50 justify-between'>
            <button
              onClick={() => {
                setData(original)
              }}
              className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
              Discard
            </button>
            <div className='flex gap-1'>
              <button
                onClick={async () => {
                  const update = {}
                  Object.entries(data).forEach(([k, v]) => {
                    update[k] = v
                  })
                  delete update.id
                  delete update.createdAt
                  delete update.updatedAt
                  await setDoc(doc(db, 'locations', data.id), {
                    ...update,
                    updatedAt: new Date()
                  }, { merge: true })
                  setOriginal(data)
                }}
                className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-primary bg-black-800 hover:bg-black-750')}>
                Save
              </button>
              <button
                onClick={async () => {
                  const update = {}
                  Object.entries(data).forEach(([k, v]) => {
                    update[k] = v
                  })
                  delete update.id
                  delete update.createdAt
                  delete update.updatedAt
                  await setDoc(doc(db, 'locations', data.id), {
                    ...update,
                    updatedAt: new Date()
                  }, { merge: true })
                  setShowModal(false)
                }}
                className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-black bg-primary hover:bg-purple-600')}>
                Save & Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}

function TextInput({ data, property, label, onChange, isTextArea }) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      {isTextArea &&
        <textarea
          id={property}
          className='h-18 text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
          type='text'
          onChange={onChange}
          value={data[property] ? data[property] : ''} />
      }
      {!isTextArea &&
        <input
          id={property}
          className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
          type='text'
          onChange={onChange}
          value={data[property] ? data[property] : ''} />
        }
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}

import { useRef, useState, useEffect } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, setDoc, orderBy, doc } from 'firebase/firestore'

import { PencilIcon } from '@heroicons/react/24/solid'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(null)
  const articlesUnsub = useRef(null)
  const [articles, setArticles] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(articlesUnsub.current) articlesUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getTeam() {
      const q = query(collection(db, 'education'), orderBy('title', 'asc'))
      articlesUnsub.current = onSnapshot(q, (querySnapshot) => {
        if(mounted.current) {
          if(!querySnapshot.empty) setArticles(querySnapshot.docs)
          else setArticles(null)
        }
      })
    }
    getTeam()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div data-aos='fade-in' className='h-full overflow-scroll'>
      {articles &&
        <ul className='fade-in py-16 divide-y'>
          {articles.map(article =>
            <li key={doc.id} className='text-sm px-4 py-2.5 w-full flex items-center text-left overflow-hidden space-x-6'>
              <button
                data-tip='Edit career'
                data-for={article.id + '-edit'}
                data-delay-show={1000}
                trigger='true'
                className='h-9 w-9 text-primary rounded-full bg-black-800 hover:bg-black-775 active:bg-black-750 hover:text-white flex'>
                <PencilIcon className='h-4 w-4 m-auto' />
              </button>
              <span>
                <div className='flex items-center gap-3 pr-6'>
                  <label htmlFor={article.id + '-published'} className='transition duration-200 group block bg-black-775 hover:bg-black-725 rounded-full w-11 h-6 relative cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={article.data().isPublished ? false : true}
                      id={article.id + '-published'}
                      onChange={(e) => {
                        const isPublished = article.data().isPublished ? false : true
                        setDoc(doc(db, 'education', article.id), {
                          isPublished: isPublished,
                          updatedAt: new Date()
                        }, { merge: true })
                      }}
                      className='cursor-pointer h-full w-full sr-only peer' />
                    <div className={'absolute top-0 left-0 pointer-events-none w-6 h-6 rounded-full transition duration-200 ' + (article.data().isPublished ? 'transform translate-x-full bg-primary' : 'bg-black-550 group-hover:bg-black-475')}></div>
                  </label>
                  <span className={'text-xs ' + (article.data().isPublished ? 'text-white' : 'text-black-400')}>Published</span>
                </div>
              </span>
              <span className='flex-1 truncate'>{article.data().title}</span>
              <span className='flex-1 truncate text-black-400'>{article.data().slug}</span>
            </li>
          )}
        </ul>
      }
    </div>
  )
}

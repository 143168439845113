import { useState, useRef, useEffect } from 'react'

import { quarters } from 'lib/utils'

import { XMarkIcon } from '@heroicons/react/24/solid'

export default function Index({ quarter, setQuarter, setUpdateQuarters }) {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(quarters)

  useEffect(() => {
    if(search && search !== '') {
      setData(quarters.filter(quarter => quarter.label.includes(search)))
    } else {
      setData(quarters)
    }
  }, [search])

  return (
    <div className='h-full w-full overflow-scroll relative'>
      <div className='z-10 p-2 pb-0 backdrop-filter backdrop-blur-md sticky top-0'>
        <input
          className='font-mono p-2 placeholder-black-500 rounded-lg bg-black-825 w-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search quarters'
        />
      </div>
      <ul className='z-0 pb-6 pt-3 px-1'>
        {data.map((metric, index) =>
          <li key={'quarter-' + index} className='fade-in'>
            <button
              onClick={() => {
                // let hasMetric = quarter && quarter.find(s => s.label === metric.label)
                // if(hasMetric) {
                //   setQuarter(false)
                // } else {
                //   setQuarter([{
                //     label: metric.label,
                //     value: metric.value
                //   }])
                // }
                let hasMetric = quarter && quarter.find(s => s.label === metric.label)
                if(quarter) {
                  if(hasMetric) {
                    setQuarter(prev => prev.filter(s => s.label !== metric.label))
                  } else {
                    setQuarter(prev => ([
                      ...prev,
                      {
                        ...metric
                      }
                    ]))
                  }
                } else {
                  setQuarter([{
                    ...metric
                  }])
                }
                setUpdateQuarters(new Date())
              }}
              className='truncate overflow-hidden flex gap-3 items-center w-full px-2 py-2 text-left hover:bg-black-825 rounded-full'>
              <span className={'h-1.5 w-1.5 rounded-full ' + (quarter && quarter.find(s => s.label === metric.label) ? 'bg-primary' : 'bg-black-725') } />
              <span className='-mt-px block flex-1 truncate overflow-hidden'>{metric.label}</span>
            </button>
          </li>
        )}
      </ul>
      {quarter && quarter.length > 0 &&
        <div className='-mx-px fade-in bg-black-850 sticky bottom-0 p-2 flex flex-wrap items-end gap-1 overflow-hidden'>
          {quarter.map(o => <button key={o.label} onClick={() => {
            setQuarter(prev => prev.filter(p => p.label !== o.label))
            setUpdateQuarters(new Date())
          }} className='max-w-full whitespace-nowrap truncate fade-in text-xxs bg-yellow-500 text-black pl-2 pr-1 rounded-full flex items-center gap-2'>{o.label}<XMarkIcon className='h-2.5 w-2.5' /></button>)}
        </div>
      }
    </div>
  )
}

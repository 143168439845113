import { useEffect, useState, useRef } from 'react'

import { getApp } from 'firebase/app'
import { getFirestore, collection, query, onSnapshot, orderBy, where, Timestamp, limit } from 'firebase/firestore'

import { useDebouncedCallback } from 'use-debounce'

import { XMarkIcon, MapPinIcon, QueueListIcon } from '@heroicons/react/24/solid'

import Contacts from './components/contacts-list'

import ViewModal from './components/view-modal'

export default function Index() {
  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)

  const mounted = useRef(false)
  const contactsUnsub = useRef(false)

  const [search, setSearch] = useState('')

  const [page, setPage] = useState(false)
  const [contacts, setContacts] = useState(false)
  const [isPaging, setIsPaging] = useState(false)

  const [currentContact, setCurrentContact] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
      if(contactsUnsub.current) contactsUnsub.current()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(search) debouncedGetContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const debouncedGetContacts = useDebouncedCallback(() => {
    getContacts()
  }, 1000)

  useEffect(() => {
    if(search) {
      debouncedGetContacts()
    } else if(search !== false) {
      getContacts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  async function getContacts() {
    if(contactsUnsub.current) contactsUnsub.current()

    let q = null
    if(search && search !== '') {
      q = query(collection(db, 'contacts'), orderBy('createdAt', 'desc'), where('is_lead', '==', true), where('keyWords', 'array-contains', search.toLowerCase()), limit(25))
    } else {
      q = query(collection(db, 'contacts'), orderBy('createdAt', 'desc'), where('is_lead', '==', true), limit(25))
    }

    contactsUnsub.current = onSnapshot(q, (querySnapshot) => {
      if(mounted.current) {
        if(!querySnapshot.empty) {
          setContacts(querySnapshot.docs.map(doc => {
            const c = {
              id: doc.id
            }
            Object.entries(doc.data()).forEach(([key, value]) => {
              if(value instanceof Timestamp) {
                c[key] = value.seconds * 1000 + value.nanoseconds / 1000000
              } else {
                c[key] = value
              }
            })
            return {
              vid: doc.id,
              ...c
            }
          }))
          setPage({
            hasMore: true,
            vidOffset: querySnapshot.docs[querySnapshot.docs.length - 1].data().createdAt.toDate()
          })
        } else {
          setContacts(null)
        }
      } else {
        setContacts(null)
      }
    })
  }

  async function loadMore() {
    setIsPaging(true)
    // setIsPaging(false)
  }

  return (
    <div data-aos='fade-in' className='h-screen w-full px-3 py-16'>
      <div className='bg-black-875 rounded-2xl overflow-hidden flex h-full'>
        <div className='w-1/4 h-full bg-black-825 rounded-2xl'>
          <div className='p-3 h-full flex flex-col justify-between items-start'>
            <div className='w-full'>
              <p className='mb-12 font-bold text-primary'>Contacts</p>
              <div className='relative'>
                <input
                  autoComplete='off'
                  id='search'
                  className='focus:ring-2 focus:ring-primary transition duration-200 bg-black-775 appearance-none rounded-lg w-full py-2.5 px-3 text-sm placeholder-black-300 focus:outline-none'
                  type='text'
                  placeholder='Search'
                  value={search}
                  onChange={(e) => {
                    setContacts(false)
                    setSearch(e.target.value)
                  }}
                />
                {search !== '' &&
                  <button onClick={() => setSearch('')} className='absolute right-2 top-1/2 transform -translate-y-1/2 h-5 w-5 rounded-full flex items-center justify-center bg-primary text-black'>
                    <XMarkIcon className='h-3.5 w-3.5' />
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <div className='flex-1 min-w-0 rounded-2xl'>
          <Contacts search={search} contacts={contacts} setCurrentContact={setCurrentContact} setShowModal={setShowViewModal} isPaging={isPaging} loadMore={loadMore} page={page} />
        </div>
      </div>
      <ViewModal contact={currentContact} setCurrentContact={setCurrentContact} showModal={showViewModal} setShowModal={setShowViewModal} />
    </div>
  )
}

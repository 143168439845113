import { useRef, useState, useEffect, useContext } from 'react'
import { createPortal } from 'react-dom'
import { Context } from 'store/index'

import { getApp } from 'firebase/app'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { getFirestore, collection, query, onSnapshot, deleteDoc, orderBy, setDoc, deleteField, updateDoc, doc } from 'firebase/firestore'

import { EnvelopeIcon, ArrowPathIcon, XMarkIcon, PencilIcon } from '@heroicons/react/24/solid'
import ReactTooltip from 'react-tooltip'

import UploadImage from './upload-image'

export default function Index({ access, user, setCurrentUser, showModal, setShowModal }) {
  const [state, dispatch] = useContext(Context)

  const firebaseApp = getApp()
  const db = getFirestore(firebaseApp)
  const auth = getAuth()

  const [display, setDisplay] = useState(false)
  const [animate, setAnimate] = useState(false)

  const [data, setData] = useState(false)
  const [original, setOriginal] = useState(false)

  function compareObjs(obj1, obj2){
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }

  useEffect(() => {
    if(showModal) {
      setDisplay(true)
    } else {
      setAnimate(false)
    }
  }, [showModal])

  useEffect(() => {
    if(display) {
      setTimeout(() => {
        setAnimate(true)
      }, 20)
    } else {
      setCurrentUser(false)
    }
  }, [display])

  useEffect(() => {
    if(!animate) {
      setTimeout(() => {
        setDisplay(false)
      }, 500)
    }
  }, [animate])

  useEffect(() => {
    if(user) {
      setData(user)
      if(!original) {
        setOriginal(user)
      }
    } else {
      setData(false)
      setOriginal(false)
    }
  }, [user])

  return display ? createPortal(
    <div className='fixed z-50 top-0 left-0 h-screen w-screen'>
      <div
        onClick={() => {
          setShowModal(false)
        }}
        className={'absolute top-0 left-0 h-full w-full bg-black bg-opacity-40 backdrop-filter backdrop-blur-md transition duration-500 ' + (animate ? 'opacity-100' : 'opacity-0')} />
      <div className={'rounded-l-2xl overflow-hidden flex flex-col absolute right-0 top-0 h-full bg-black-850 w-full max-w-md transition duration-500 transform ' + (animate ? 'translate-x-0' : 'translate-x-full')}>
        <div className='p-3'>
          <button
            onClick={() => {
              setShowModal(false)
            }}
            className='group flex h-8 w-8 rounded-full bg-black-775 hover:bg-black-750 text-black-200 hover:text-white'>
            <XMarkIcon className='transition duration-200 group-hover:rotate-90 h-5 w-5 m-auto' />
          </button>
        </div>
        <div className='flex-1 h-full overflow-hidden overflow-scroll'>
          {data &&
            <div className='space-y-6'>
              <div className='p-3 pr-6'>
                <h2 className='text-xl'>{data.firstName} {data.lastName}</h2>
                <p className='text-black-400 mb-6'>{data.email}</p>
                <div className='flex gap-3 items-center'>
                  <div>
                    <button onClick={() => {
                      const isActive = data.isActive ? false : true
                      setData(prev => ({
                        ...prev,
                        isActive: isActive
                      }))
                    }} className={'transition duration-200 text-xs rounded-full px-4 py-1 ' + (data.isActive ? 'ring-1 ring-primary text-primary' : 'text-black-400 bg-black-800')}>
                      Active
                    </button>
                  </div>
                  {state.profile.access && state.profile.access.global && state.profile.access.global.some(item => 'Super Admin' === item) &&
                    <span className='flex space-x-2'>
                      <button
                        data-tip='Send password reset email'
                        data-for={data.id + '-email'}
                        data-delay-show={1000}
                        onClick={() => {
                          dispatch({ type: 'toast-set', payload: 'Sending email.' })
                          sendPasswordResetEmail(auth, data.email)
                          .then(() => {
                            dispatch({ type: 'toast-set', payload: false })
                            setTimeout(() => {
                              dispatch({ type: 'toast-set', payload: 'Email sent!' })
                            }, 400)
                          })
                          .catch((error) => {
                            console.log(error.message)
                            dispatch({ type: 'toast-set', payload: error.message })
                          })
                        }}
                        trigger='true'
                        className='h-9 w-9 text-black-300 rounded-full bg-black-800 hover:bg-black-775 active:bg-black-750 hover:text-white flex'>
                        <EnvelopeIcon className='h-3.5 w-3.5 m-auto' />
                      </button>

                      <ReactTooltip
                        className='text-sm'
                        id={user.id + '-email'}
                        place='bottom'
                        type='dark'
                        effect='solid'
                        multiline={true}
                      />
                    </span>
                  }
                </div>
              </div>

              <UploadImage
                data={data}
                collection='users'
                folder='users'
                onChange={e => {
                  setData(prev => ({
                    ...prev,
                    image: e
                  }))
                }}
              />

              <div className='space-y-2 p-3 pr-6'>
                {/*<TextInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    name: e.target.value
                  }))
                }} data={data} property='name' label='Name' placeholder='Enter name' />*/}
                <TextInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    firstName: e.target.value
                  }))
                }} data={data} property='firstName' label='First name' placeholder='Enter first name' />
                <TextInput onChange={(e) => {
                  setData(prev => ({
                    ...prev,
                    lastName: e.target.value
                  }))
                }} data={data} property='lastName' label='Last name' placeholder='Enter last name' />
              </div>

              <div className='p-3 pb-12'>
                <button
                  onClick={async () => {
                    await deleteDoc(doc(db, 'users', data.id))
                    setShowModal(false)
                  }}
                  className='font-bold px-6 py-1.5 text-sm rounded-lg text-red-500 bg-black-775'>
                  Delete
                </button>
              </div>
            </div>
          }
          <div className='border-t border-black-750 text-sm sticky bottom-0 bg-black-850 p-3 flex z-50 justify-between'>
            <button
              onClick={() => {
                setData(original)
              }}
              className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-purple-500 bg-black-800 hover:bg-black-750')}>
              Discard
            </button>
            <div className='flex gap-1'>
              <button
                onClick={async () => {
                  const update = {}
                  Object.entries(data).forEach(([k, v]) => {
                    update[k] = v
                  })
                  delete update.id
                  delete update.createdAt
                  delete update.updatedAt
                  await setDoc(doc(db, 'users', data.id), {
                    ...update,
                    updatedAt: new Date()
                  }, { merge: true })
                  setOriginal(data)
                }}
                className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-purple-500 bg-black-800 hover:bg-black-750')}>
                Save
              </button>
              <button
                onClick={async () => {
                  const update = {}
                  Object.entries(data).forEach(([k, v]) => {
                    update[k] = v
                  })
                  delete update.id
                  delete update.createdAt
                  delete update.updatedAt
                  await setDoc(doc(db, 'users', data.id), {
                    ...update,
                    updatedAt: new Date()
                  }, { merge: true })
                  setShowModal(false)
                }}
                className={'font-bold transition duration-200 px-4 py-1 rounded-lg ' + (compareObjs(data, original) ? 'text-black-400 bg-black-775' : 'text-black bg-purple-500 hover:bg-purple-600')}>
                Save & Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  , document.body) : null
}

function TextInput({ data, property, label, onChange, placeholder}) {
  return (
    <div className='relative group'>
      <label htmlFor={property} className='relative z-10 text-black-200 text-xxs px-2 pt-1 block'>{label}</label>
      <input
        id={property}
        className='text-sm leading-none placeholder-black-400 relative z-10 px-2 pb-2 pt-1 peer bg-transparent rounded-lg w-full'
        type='text'
        onChange={onChange}
        placeholder={placeholder}
        value={data[property] ? data[property] : ''} />
      <div className='z-0 absolute top-0 left-0 h-full w-full rounded-lg bg-black-800 group-hover:bg-black-775 peer-focus:bg-black-775' />
    </div>
  )
}
